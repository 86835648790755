import React, { useState } from "react";
import PropTypes from "prop-types";
import { Auth } from "aws-amplify";
import useWindowSize from '../../../utils/Hooks/useWindowSize';
import {
  Collapse,
  Navbar,
  Nav,
  NavItem,
  NavLink,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import { Link } from "react-router-dom";
import contactUs from "../../../customizations/assets/images/contactUs.svg";
import { getTranslatedMessageFromID } from "../../../utils/functions/intl/intlWrapper";
import localStorageKeyring from "../../../constants/LOCAL_STORAGE_KEYS";
import LocalStorage from "../../../utils/functions/localStorage/LocalStorage";
import { renderingInDesktop } from "../../../utils/functions/device/determinePlatform";
import { FormattedMessage } from 'react-intl';

import headShoulders from "../../../customizations/assets/images/headShoulders.svg";
import "./Header.scss";

// CSS from Electric For All
import './thegemcss/gtranslate-style24.css';
import './thegemcss/layerslider.css';
import './thegemcss/style.css';
import './thegemcss/thegem-widgets.css';

// This is fun... our layout on three steps page is broken without this css.
import './thegemcss/thegem-grid.scss';
import './thegemcss/thegem-preloader.css';

// Here's hoping we don't need this.. tons of errors in here.
// We do need this for the pipe though.
import './thegemcss/thegem-child-style.css';

// This one gets wornings: Property is ignored due to the display. 
// With 'display: block', vertical-align should not be used.
import './thegemcss/thegem-header.css';
import './thegemcss/thegem-new-css.css';
import './thegemcss/thegem-widgets.css';
import './thegemcss/thegem-perevazka-css.css';
import './thegemcss/custom-n5xN0Kr8.css';

// import './thegemcss/efa.min.css'
// import './thegemcss/efa.css'

const Header = ({ language, authState }) => {
  const [isAccountMenuOpen, setIsAccountMenuOpen] = useState(false);

  const [showNavMenu, setShowNavMenu] = useState(false);
  const [showSubView, setSubView] = useState(false);
  const [parentId, setParentId] = useState('');

  const redirectToHomePage = () => {
    window.location.href = './';
  };

  const windowSize = useWindowSize();
  const handleClick = (e) => {
    e.preventDefault();
    setShowNavMenu(!showNavMenu);
  };

  const handleSubMenu = (e) => {
    if (windowSize.width < 1000) {
      e.preventDefault();
      setSubView(true);
      setParentId(e.target.dataset.parent);
      if (e.target.dataset.parent === undefined) {
        setSubView(false);
      }
    }
  };

  const isGenericOnboardComplete = LocalStorage.getLocalDatapoint(localStorageKeyring.fractionFieldsFinished) === 1;

  return (
    <div id="header">
      <div
        id="top-area"
        className="top-area top-area-style-default top-area-alignment-right"
      >
        <div>
          <div className="top-area-items inline-inside">
            <div className="top-area-block top-area-menu">
              <nav id="top-area-menu">
                <ul
                  id="top-area-navigation"
                  className="nav-menu styled inline-inside"
                >
                  { <li id="menu-item-24783" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-24783">
                    <a href="https://www.electricforall.org/veloz/">Veloz</a>
                  </li> }

                  <li
                    id="menu-item-24568"
                    className="menu-item menu-item-type-post_type menu-item-object-page menu-item-24568"
                  >
                    <a href="https://www.electricforall.org/media-assets/">
                      <FormattedMessage
                        id="header.media"
                        defaultMessage="Media"
                        description="Media"
                      />
                    </a>
                  </li>
                  <li
                    id="menu-item-24763"
                    className="menu-item menu-item-type-post_type menu-item-object-page menu-item-24763"
                  >
                    <a href="https://www.electricforall.org/contact-efa/">
                      <FormattedMessage
                        id="header.contact"
                        defaultMessage="Contact Us"
                        description="Contact Us"
                      />
                    </a>
                  </li>
                  {/* <li className="menu-item menu-item-gtranslate">
                        <a href="#top" onClick={onChangeLanguage} title="English" className={`${language === "EN" ? `active` : ``} language-toggle-button`} >
                          EN
                        </a>
                      </li>
                      <li className="menu-item menu-item-gtranslate">
                        <a href="#top" onClick={onChangeLanguage} title="Spanish" className={`${language === "ES" ? `active` : ``} language-toggle-button`}>
                          ES
                        </a>
                      </li> */}
                </ul>
              </nav>
            </div>

            <div className="top-area-block top-area-socials socials-colored-hover">
              <div className="socials inline-inside top-right-padding">
                <a
                  className="socials-item"
                  href="https://www.facebook.com/ElectricForAll/"
                  target="_blank"
                  rel="noopener noreferrer"
                  title="Facebook"
                >
                  <i className="socials-item-icon facebook "></i>
                </a>
                <a
                  className="socials-item"
                  href="https://twitter.com/ElectricForAll"
                  target="_blank"
                  rel="noopener noreferrer"
                  title="Twitter"
                >
                  <i className="socials-item-icon twitter "></i>
                </a>
                <a
                  className="socials-item"
                  href="https://www.instagram.com/electricforall/"
                  target="_blank"
                  rel="noopener noreferrer"
                  title="Instagram"
                >
                  <i className="socials-item-icon instagram "></i>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Navbar expand="md" className="Header py-0 grey-bottom-border">
        {/* Medium sized and up branding */}

        <header
          id="site-header"
          className="site-header animated-header mobile-menu-layout-default ios-load"
          role="banner"
        >
          <div className="container">
            <div className="header-main logo-position-left header-layout-default header-style-3">
              <div className="site-title">
                <div className="site-logo" style={{ width: "400px" }}>
                  <a href="https://www.electricforall.org/" rel="home">
                    <span className="logo">
                      <img
                        src="https://www.electricforall.org/wp-content/uploads/thegem-logos/logo_bdfb6420c3367c7ecdcf14a2cc433063_1x.png"
                        srcSet="https://www.electricforall.org/wp-content/uploads/thegem-logos/logo_bdfb6420c3367c7ecdcf14a2cc433063_1x.png 1x,https://www.electricforall.org/wp-content/uploads/thegem-logos/logo_bdfb6420c3367c7ecdcf14a2cc433063_2x.png 2x,https://www.electricforall.org/wp-content/uploads/thegem-logos/logo_bdfb6420c3367c7ecdcf14a2cc433063_3x.png 3x"
                        alt="Electric For All"
                        style={{ width: "400px" }}
                        className="tgp-exclude default"
                      />
                      <img
                        src="https://www.electricforall.org/wp-content/uploads/thegem-logos/logo_89937483090a285911184c6022f1c16d_1x.png"
                        srcSet="https://www.electricforall.org/wp-content/uploads/thegem-logos/logo_89937483090a285911184c6022f1c16d_1x.png 1x,https://www.electricforall.org/wp-content/uploads/thegem-logos/logo_89937483090a285911184c6022f1c16d_2x.png 2x,https://www.electricforall.org/wp-content/uploads/thegem-logos/logo_89937483090a285911184c6022f1c16d_3x.png 3x"
                        alt="Electric For All"
                        style={{ width: "225px", marginLeft: "-400px" }}
                        className="tgp-exclude small"
                      />
                    </span>
                  </a>
                </div>
              </div>
              <nav
                id="primary-navigation"
                className={`site-navigation primary-navigation ${
                  windowSize.width > 1000 ? "" : "responsive"
                } padding-left`}
              >
                <button
                  className={`menu-toggle dl-trigger ${
                    showNavMenu ? "dl-active" : ""
                  }`}
                  onClick={(e) => handleClick(e)}
                >
                  Primary Menu
                  <span className="menu-line-1"></span>
                  <span className="menu-line-2"></span>
                  <span className="menu-line-3"></span>
                </button>{" "}
                <ul
                  id="primary-menu"
                  className={`nav-menu styled dl-menu ${
                    (showNavMenu ? "dl-menuopen" : "") +
                    " " +
                    (windowSize.width > 1000 ? "no-responsive" : "") +
                    " " +
                    (showSubView ? "dl-subview" : "")
                  } `}
                >
                  <li
                    id="menu-item-24753"
                    className={`menu-item menu-item-type-post_type menu-item-object-page menu-item-has-children menu-item-parent menu-item-24753 megamenu-first-element mobile-clickable ${
                      parentId === "24753" ? `dl-subviewopen` : ``
                    }`}
                  >
                    <a
                      href="https://www.electricforall.org/which-car-is-right-for-me/"
                      data-parent="24753"
                      onClick={(e) => handleSubMenu(e)}
                    >
                      <FormattedMessage
                        id="subheader.exploreEvs"
                        defaultMessage="Explore EVs"
                        description="Explore EVs"
                      />
                    </a>
                    <span className="menu-item-parent-toggle"></span>
                    <ul className="sub-menu styled dl-submenu">
                      <li className="dl-parent">
                        <a href="https://www.electricforall.org/which-car-is-right-for-me/">
                          Show this page
                        </a>
                      </li>
                      <li className="dl-back">
                        <a href="/" onClick={(e) => handleSubMenu(e)}>
                          <FormattedMessage
                            id="subheader.back"
                            defaultMessage="Back"
                            description="Back"
                          />
                        </a>
                      </li>
                      <li
                        id="menu-item-24683"
                        className="menu-item menu-item-type-post_type menu-item-object-page menu-item-24683 megamenu-first-element"
                      >
                        <a href="https://www.electricforall.org/which-car-is-right/">
                          <FormattedMessage
                            id="subheader.findYourEV"
                            defaultMessage="Find Your EV and Incentives"
                            description="Find Your EV and Incentives"
                          />
                        </a>
                      </li>
                      <li
                        id="menu-item-24899"
                        className="menu-item menu-item-type-post_type menu-item-object-page menu-item-24899 megamenu-first-element"
                      >
                        <a href="https://www.electricforall.org/ev-myths/">
                          <FormattedMessage
                            id="subheader.mythbusting"
                            defaultMessage="EV Mythbusting"
                            description="EV Mythbusting"
                          />
                        </a>
                      </li>
                      <li
                        id="menu-item-24719"
                        className="menu-item menu-item-type-post_type menu-item-object-page menu-item-24719 megamenu-first-element mobile-clickable"
                      >
                        <a href="https://www.electricforall.org/certified-dealers/">
                          <FormattedMessage
                            id="subheader.evDealers"
                            defaultMessage="EV Dealers"
                            description="EV Dealers"
                          />
                        </a>
                      </li>
                      <li
                        id="menu-item-24899"
                        className="menu-item menu-item-type-post_type menu-item-object-page menu-item-24899 megamenu-first-element"
                      >
                        <a href="https://www.electricforall.org/used-evs/">
                          <FormattedMessage
                            id="subheader.usedEVs"
                            defaultMessage="Used EVs"
                            description="Used EVs"
                          />
                        </a>
                      </li>
                      <li
                        id="menu-item-25005"
                        className="menu-item menu-item-type-post_type menu-item-object-page menu-item-25005 megamenu-first-element"
                      >
                        <a href="http://www.electricforall.org/national-pledge">
                          <FormattedMessage
                            id="subheader.nationalPledge"
                            defaultMessage="National Pledge"
                            description="National Pledge"
                          />
                        </a>
                      </li>
                    </ul>
                  </li>
                  <li
                    id="menu-item-25043"
                    className={`menu-item menu-item-type-custom menu-item-object-custom current-menu-ancestor current-menu-parent menu-item-has-children menu-item-parent menu-item-25043 megamenu-first-element ${
                      parentId === "25043" ? `dl-subviewopen` : ``
                    }`}
                  >
                    <a
                      href="/"
                      data-parent="25043"
                      onClick={(e) => handleSubMenu(e)}
                    >
                      <FormattedMessage
                        id="incentives"
                        defaultMessage="Incentives"
                        description="Incentives"
                      />
                    </a>
                    <span className="menu-item-parent-toggle"></span>
                    <ul className="sub-menu styled dl-submenu">
                      <li className="dl-back">
                        <a href="/" onClick={(e) => handleSubMenu(e)}>
                          <FormattedMessage
                            id="subheader.back"
                            defaultMessage="Back"
                            description="Back"
                          />
                        </a>
                      </li>
                      <li
                        id="menu-item-24547"
                        className="menu-item menu-item-type-post_type menu-item-object-page current-menu-item page_item page-item-24545 current_page_item menu-item-24547 megamenu-first-element"
                      >
                        <a href="https://www.electricforall.org/rebates-incentives/">
                          <FormattedMessage
                            id="subheader.evIncentives"
                            defaultMessage="EV Incentives"
                            description="EV Incentives"
                          />
                        </a>
                      </li>

                      <li
                        id="menu-item-24751"
                        className="menu-item menu-item-type-post_type menu-item-object-page menu-item-24751 megamenu-first-element"
                      >
                        <a href="https://www.electricforall.org/electric-utility/">
                          <FormattedMessage
                            id="subheader.utilityIncentives"
                            defaultMessage="Utility Incentives"
                            description="Utility Incentives"
                          />
                        </a>
                      </li>
                      <li
                        id="menu-item-25057"
                        className="menu-item menu-item-type-custom menu-item-object-custom menu-item-25057 megamenu-first-element"
                      >
                        <a
                          target="_blank"
                          rel="noopener noreferrer"
                          href="https://homecharging.electricforall.org"
                        >
                          <FormattedMessage
                            id="subheader.homeChargingIncentives"
                            defaultMessage="Home Charging Incentives"
                            description="Home Charging Incentives"
                          />
                        </a>
                      </li>
                    </ul>
                  </li>
                  <li
                    id="menu-item-24551"
                    className={`menu-item menu-item-type-post_type menu-item-object-page menu-item-has-children menu-item-parent menu-item-24551 megamenu-first-element mobile-clickable menu-item-current ${
                      parentId === "24551" ? `dl-subviewopen` : ``
                    }`}
                  >
                    <a
                      href="https://www.electricforall.org/how-can-i-fill-up/"
                      data-parent="24551"
                      onClick={(e) => handleSubMenu(e)}
                    >
                      <FormattedMessage
                        id="subheader.chargingHydrogen"
                        defaultMessage="Charging | Hydrogen"
                        description="Charging | Hydrogen"
                      />
                    </a>
                    <span className="menu-item-parent-toggle"></span>
                    <ul className="sub-menu styled dl-submenu">
                      <li className="dl-parent">
                        <a href="https://www.electricforall.org/how-can-i-fill-up/">
                          Show this page
                        </a>
                      </li>
                      <li className="dl-back">
                        <a href="/" onClick={(e) => handleSubMenu(e)}>
                          <FormattedMessage
                            id="subheader.back"
                            defaultMessage="Back"
                            description="Back"
                          />
                        </a>
                      </li>
                      <li
                        id="menu-item-24580"
                        className="menu-item menu-item-type-post_type menu-item-object-page menu-item-24580 megamenu-first-element"
                      >
                        <a href="https://www.electricforall.org/how-can-i-fill-up/charging-your-vehicle/">
                          <FormattedMessage
                            id="subheader.charging"
                            defaultMessage="EV Charging 101"
                            description="EV Charging 101"
                          />
                        </a>
                      </li>
                      <li
                        id="menu-item-25055"
                        className="menu-item menu-item-type-post_type menu-item-object-page menu-item-25055 megamenu-first-element"
                      >
                        <a href="https://www.electricforall.org/ev-charger-locations/">
                          <FormattedMessage
                            id="subheader.findEvChargerLocations"
                            defaultMessage="Find an EV Charger Near You"
                            description="Find an EV Charger Near You"
                          />
                        </a>
                      </li>
                      <li id="menu-item-24584" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-24584 megamenu-first-element">
                        <a href="https://www.electricforall.org/how-can-i-fill-up/fuel-cell-electric-vehicles/">
                          <FormattedMessage
                            id="subheader.hydrogenFuelCell"
                            defaultMessage="Hydrogen Fuel Cell EVs"
                            description="Hydrogen Fuel Cell EVs"
                          />
                        </a>
                      </li>
                      <li
                        id="menu-item-24818"
                        className="menu-item menu-item-type-custom menu-item-object-custom menu-item-24818 megamenu-first-element"
                      >
                        <a href="https://homecharging.electricforall.org/">
                          <FormattedMessage
                            id="subheader.homeChargingAdvisor"
                            defaultMessage="Home Charging Advisor"
                            description="Home Charging Advisor"
                          />
                        </a>
                      </li>
                    </ul>
                  </li>
                  <li
                    id="menu-item-24963"
                    className={`menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children menu-item-parent menu-item-24963 megamenu-first-element ${
                      parentId === "24963" ? `dl-subviewopen` : ``
                    }`}
                  >
                    <a
                      href="/"
                      data-parent="24963"
                      onClick={(e) => handleSubMenu(e)}
                    >
                      <FormattedMessage
                        id="subheader.campaign"
                        defaultMessage="Campaigns"
                        description="Campaigns"
                      />
                    </a>
                    <span className="menu-item-parent-toggle"></span>
                    <ul className="sub-menu styled dl-submenu invert">
                      <li className="dl-back">
                        <a href="/" onClick={(e) => handleSubMenu(e)}>
                          <FormattedMessage
                            id="subheader.back"
                            defaultMessage="Back"
                            description="Back"
                          />
                        </a>
                      </li>
                      <li
                        id="menu-item-24962"
                        className="menu-item menu-item-type-post_type menu-item-object-page menu-item-24962 megamenu-first-element"
                      >
                        <a href="https://www.electricforall.org/campaign/">
                          <FormattedMessage
                            id="subheader.myths"
                            defaultMessage="Myths Busting Myths"
                            description="Myths Busting Myths"
                          />
                        </a>
                      </li>
                      <li
                        id="menu-item-24960"
                        className="menu-item menu-item-type-post_type menu-item-object-page menu-item-24960 megamenu-first-element"
                      >
                        <a href="https://www.electricforall.org/40-million-reasons/">
                          <FormattedMessage
                            id="subheader.reasons"
                            defaultMessage="40 Million Reasons to Go Electric"
                            description="40 Million Reasons to Go Electric"
                          />
                        </a>
                      </li>
                      <li
                        id="menu-item-24962"
                        className="menu-item menu-item-type-post_type menu-item-object-page menu-item-24962 megamenu-first-element"
                      >
                        <a href="https://www.electricforall.org/kicking-gas/">
                          <FormattedMessage
                            id="subheader.kickingGas"
                            defaultMessage="Kicking Gas"
                            description="Kicking Gas"
                          />
                        </a>
                      </li>
                      <li
                        id="menu-item-24962"
                        className="menu-item menu-item-type-post_type menu-item-object-page menu-item-24962 megamenu-first-element"
                      >
                        <a href="https://www.electricforall.org/opposites-attract/">
                          <FormattedMessage
                            id="subheader.oppositesAttract"
                            defaultMessage="Opposites Attract"
                            description="Opposites Attract"
                          />
                        </a>
                      </li>
                    </ul>
                  </li>
                </ul>
              </nav>
            </div>
          </div>
        </header>
      </Navbar>
      <Navbar expand="md" className="Header py-0">
        <div className="d-flex d-md-none align-items-center mobile-container ">
          <NavItem>
            <NavLink
              tag={Link}
              to="/dashboard"
              className="d-flex align-items-center"
            >
              {isGenericOnboardComplete && renderingInDesktop && (
                <div className="dashboard-link">
                  {getTranslatedMessageFromID("dashboard", language)}
                </div>
              )}
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              tag={Link}
              to="/contact-us"
              className="d-flex align-items-center"
            >
              <img
                src={contactUs}
                alt="Contact Support"
                className="d-inline-block  mr-2"
              />
              <span className="contact-us-text">
                {getTranslatedMessageFromID("contactSupport", language)}
              </span>
            </NavLink>
          </NavItem>
          <div className="left-hug">
            {authState && (
              <Dropdown
                nav
                inNavbar
                isOpen={isAccountMenuOpen}
                toggle={() => setIsAccountMenuOpen((current) => !current)}
              >
                <DropdownToggle nav caret>
                  <img src={headShoulders} alt="Account" />
                </DropdownToggle>
                <DropdownMenu right>
                  <DropdownItem>
                    <div
                      className="signout"
                      onClick={() => {
                        Auth.signOut();
                        redirectToHomePage();
                      }}
                      role="button"
                      tabIndex="0"
                      onKeyDown={() => {
                        Auth.signOut();
                        redirectToHomePage();
                      }}
                    >
                      Sign Out
                    </div>
                  </DropdownItem>
                </DropdownMenu>
              </Dropdown>
            )}
          </div>
          {/* <NavLink tag={Link} to="/contact-us" className="d-flex align-items-center ml-auto">
            <img src={contactUs} alt="Contact Support" className="d-inline-block  mr-2" />
          </NavLink> */}
        </div>
        <Collapse isOpen={false} navbar>
          <Nav className="ml-auto" navbar>
            <NavItem>
              <NavLink
                tag={Link}
                to="/dashboard"
                className="d-flex align-items-center"
              >
                {isGenericOnboardComplete && renderingInDesktop && (
                  <div className="dashboard-link">
                    {getTranslatedMessageFromID("dashboard", language)}
                  </div>
                )}
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                tag={Link}
                to="/contact-us"
                className="d-flex align-items-center"
              >
                <img
                  src={contactUs}
                  alt="Contact Support"
                  className="d-inline-block  mr-2"
                />
                <span className="contact-us-text ">
                  {getTranslatedMessageFromID("contactSupport", language)}
                </span>
              </NavLink>
            </NavItem>
            {authState && (
              <Dropdown
                nav
                inNavbar
                isOpen={isAccountMenuOpen}
                toggle={() => setIsAccountMenuOpen((current) => !current)}
              >
                <DropdownToggle nav caret>
                  <img src={headShoulders} alt="Account" />
                </DropdownToggle>
                <DropdownMenu right>
                  <DropdownItem>
                    <div
                      className="signout"
                      onClick={() => {
                        Auth.signOut();
                        redirectToHomePage();
                      }}
                      role="button"
                      tabIndex="0"
                      onKeyDown={() => {
                        Auth.signOut();
                        redirectToHomePage();
                      }}
                    >
                      Sign Out
                    </div>
                  </DropdownItem>
                </DropdownMenu>
              </Dropdown>
            )}
          </Nav>
        </Collapse>
      </Navbar>
    </div>
  );
};

export default Header;

Header.propTypes = {
  language: PropTypes.string.isRequired,
  authState: PropTypes.any,
};

import React, { useState } from 'react';
import PropTypes from 'prop-types';

import SeeMoreSeeLess from './SeeMoreSeeLess';

import { setClassName } from './functions/descriptionHandler';

const CardDescription = (
  {
    description,
    language,
    incentiveID
  },
) => {
  const [descriptionExpanded, setDescriptionExpanded] = useState(false); // eslint-disable-line

  // Only show the seeMoreSeeLess if we are looking at a real incentive.
  // Placeholder incentives have brief enough messaging that we don't need this toggle.
  const seeMoreSeeLess = incentiveID > 0
    ? (
      <SeeMoreSeeLess
        onClick={() => {
          setDescriptionExpanded(!descriptionExpanded);
        }}
        language={language}
        descriptionExpanded={descriptionExpanded}
      />
    )
    : null;

  return (
    <div
      className="CardDescription"
    >
      <div
        className={setClassName(descriptionExpanded)}
      >
        {description}
      </div>
      {seeMoreSeeLess}
    </div>
  );
};

export default CardDescription;

CardDescription.propTypes = {
  description: PropTypes.string.isRequired,
  language: PropTypes.string.isRequired,
  incentiveID: PropTypes.number.isRequired
};

// @TODO I am all ears for a better name for this. My own suggersestion: ProgressJellyfish?

import React, { useContext } from 'react';
import PropTypes from 'prop-types';

import IncentiveDrawer from '../../DashboardApplicationProgress/IncentiveDrawer/IncentiveDrawer';
import DashboardChargerHead from '../../DashboardApplicationProgress/DashboardChargerHead/DashboardChargerHead';
import ProgressReviewDrawer from '../../DashboardApplicationProgress/ProgressReviewDrawer/ProgressReviewDrawer';

import { getTranslatedMessageFromID } from '../../../utils/functions/intl/intlWrapper';
import { renderingInSmartphone } from '../../../utils/functions/device/determinePlatform';
import { UserContext } from '../../IaaUserEntity/IaaUserEntity';

import '../../DashboardApplicationProgress/DashboardChargerHead/css/DashboardChargerHead.scss';
import '../../DashboardApplicationProgress/DashboardNavDrawer/css/DashboardNavDrawer.scss';

const DashboardProgressNavigator = ({
  language,
  setCurrentIncentiveByID,
  incentiveDrawerOpened
}) => { 

  const contextArr = useContext(UserContext);
  const iaaUser = contextArr[0] || contextArr;

  const user = iaaUser.UserObject.profile;
  const equipment = iaaUser.UserObject.charger;
  const currentAddress = iaaUser.UserObject.chargerAddress;
  const vehicle = iaaUser.UserObject.vehicle;
  const incentives = iaaUser.UserObject.incentives || null;

  return (
    <>
      <DashboardChargerHead
        equipment={equipment}
        user={user}
        currentAddress={currentAddress}
        vehicle={vehicle}
      />
      <ProgressReviewDrawer
        title={getTranslatedMessageFromID('profileDetails', language)}
        language={language}
        user={user}
        equipment={equipment}
        vehicle={vehicle}
        currentAddress={currentAddress}
      />
      {
        renderingInSmartphone() && (<IncentiveDrawer
          title="Available Incentives"
          incentives={incentives}
          language={language}
          setCurrentIncentiveByID={setCurrentIncentiveByID}
          incentiveDrawerOpened={incentiveDrawerOpened}
        />)
      }
    </>
  );
};

export default DashboardProgressNavigator;

DashboardProgressNavigator.defaultProps = {
  incentiveDrawerOpened: false
};

DashboardProgressNavigator.propTypes = {
  language: PropTypes.string.isRequired,
  setCurrentIncentiveByID: PropTypes.func.isRequired,
  incentiveDrawerOpened: PropTypes.bool
};

import React from 'react';
import PropTypes from 'prop-types';

import { getTranslatedMessageFromID } from '../../../../utils/functions/intl/intlWrapper';

const AppStepHeaderMobile = (
  {
    headlineID,
    blurbID,
    language,
  },
) => {
  const blurb = blurbID
    ? (
      <div className="AppStepBlurb text-center">
        {getTranslatedMessageFromID(blurbID, language)}
      </div>
    )
    : null;

  return (
    <>
      <div className="AppStepHeader text-center">{getTranslatedMessageFromID(headlineID, language)}</div>
      { blurb }
    </>
  );
};

export default AppStepHeaderMobile;

AppStepHeaderMobile.defaultProps = {
  blurbID: null,
};

AppStepHeaderMobile.propTypes = {
  headlineID: PropTypes.string.isRequired,
  blurbID: PropTypes.string,
  language: PropTypes.string.isRequired,
};

import React from 'react';
import PropTypes from 'prop-types';

import NextButton from '../../Nav/PageTurners/NextButton';

const ExampleNextButton = (
  {
    setInputComponentsMustValidate,
  },
) => (
  <NextButton
    setInputComponentsMustValidate={setInputComponentsMustValidate}
  >
    Validate
  </NextButton>
);

export default ExampleNextButton;

ExampleNextButton.propTypes = {
  setInputComponentsMustValidate: PropTypes.func.isRequired,
};

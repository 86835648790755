import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';

import ApplicationAssistantLayout from '../../ApplicationAssistantLayout/ApplicationAssistantLayout';
import MyChargerMainContent from './MainContent/MyChargerMainContent';

import '../../Atoms/Inputs/DateInput/css/DateInput.scss';

const MyCharger = (
  {
    setDisplayPending,
    displayPending,
    setCurrentIncentiveByID,
    language,
    hasInvalidInput,
    inputComponentsMustValidate,
    setInputComponentsMustValidate,
    handlePageForwardClick,
    currentPage,
    alertAPIError
  },
) => {
  const intl = useIntl();
  return (
    <ApplicationAssistantLayout
      language={language}
      previousPage="/charger-location"
      nextPage="/my-utility"
      previousPageButtonText={intl.formatMessage({ id: 'back' })}
      nextPageButtonText={intl.formatMessage({ id: 'saveAndNext' })}
      setCurrentIncentiveByID={setCurrentIncentiveByID}
      handlePageForwardClick={handlePageForwardClick}
      currentPage={currentPage}
    >
      <MyChargerMainContent
        language={language}
        displayPending={displayPending}
        setDisplayPending={setDisplayPending}
        hasInvalidInput={hasInvalidInput}
        inputComponentsMustValidate={inputComponentsMustValidate}
        setInputComponentsMustValidate={setInputComponentsMustValidate}
        alertAPIError={alertAPIError}
      />
    </ApplicationAssistantLayout>
  );
};

export default MyCharger;

MyCharger.propTypes = {
  setCurrentIncentiveByID: PropTypes.func.isRequired,
  language: PropTypes.string.isRequired,
  displayPending: PropTypes.bool.isRequired,
  setDisplayPending: PropTypes.func.isRequired,
  hasInvalidInput: PropTypes.func.isRequired,
  inputComponentsMustValidate: PropTypes.bool.isRequired,
  setInputComponentsMustValidate: PropTypes.func.isRequired,
  handlePageForwardClick: PropTypes.func.isRequired,
  currentPage: PropTypes.string.isRequired,
  alertAPIError: PropTypes.func,
};

import React from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';

import Uuid from '../../../../utils/functions/misc/Uuid';
import { getTranslatedMessageFromID } from '../../../../utils/functions/intl/intlWrapper';

// @TODO repeats a lot of stuff from DashboardNavDrawer... DRY this up.

const TabletModalProfileItem = (
  {
    title,
    drawerContent,
    language,
    editLink,
  }
) => {

  const linkToEdit = editLink
    ? (
      <div className="edit-link">
        <NavLink
          to={editLink}
        >
          {getTranslatedMessageFromID('edit', language)}
        </NavLink>
      </div>
    )
    : null;


  return(
    <div 
      className="TabletModalProfileItem"
      key={Uuid}
    >
      <div className="row tablet-modal-profile-title">
        <div className="col-6 text-left">
          {title}
        </div>
        <div className="col-6 text-right">
          {linkToEdit}
        </div>
      </div>
      <div className="profile-items">
        {drawerContent.map((item) => {
          const itemDivKey = Uuid();

          return (
            <div
              key={itemDivKey}
              className="NavDrawerItem"
            >
              {item}
            </div>
          );              
        })}
      </div>
    </div>
  );
};

export default TabletModalProfileItem;

TabletModalProfileItem.defaultProps = {
  editLink: '',
  language: 'EN',
};
  
TabletModalProfileItem.propTypes = {
  title: PropTypes.string.isRequired,
  drawerContent: PropTypes.arrayOf(PropTypes.node).isRequired,
  language: PropTypes.string.isRequired,
  editLink: PropTypes.string,
};

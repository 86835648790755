import { pathfinder } from '../../utils/functions/locationBar/pathfinder';

// @TODO make settable
const defaultTimeout = 10000;

const defaultPort = process.env.REACT_APP_ENVIRONMENT
  ? ''
  : '3000';

const domain = process.env.REACT_APP_EV_INFO_UNIFIED_HOST || 'https://api.beta.zappyride.com';
const key = process.env.REACT_APP_EV_INFO_TKN || '9e4b20b0031742bdda41ef9a9f2573dd';

const APIs = {
  unified: {
    domain,
    key,
    timeout: defaultTimeout,
  },
  IAA: {
    domain: `${pathfinder.rootURL}`,
    key: 'tbd',
    timeout: defaultTimeout,
  },
};

// @TODO remove default port.
/**
 * Contacts API endpoint. Promise returned by function resolves with the
 * response data or an error message
 *
 * @param {string} api          IAA, Universal, or url
 * @param {string} endpoint     user, address, equipment, location, etc.
 * @param {string} method       GET, POST, or PUT.
 * @param {*}      params       Key-value pairs to become query params.
 * @param {string} port         Optional port number if applicable (such as local testing).
 *
 * @returns {Promise}
 */
const contactEndpoint = (api, endpoint, method, params, port = defaultPort, alertAPIError = null) => {
  const trimmedParams = { ...params };

  // Remove unused params
  Object.keys(trimmedParams).forEach(
    (key) => trimmedParams[key] == null
      && delete trimmedParams[key],
  );

  const portToAppend = port
    ? `:${port}`
    : '';

  const targetAPI = APIs[api] || {
    domain: api,
    key: 'tbd', // If needed, take from params
    timeout: defaultTimeout
  };

  const { domain, key } = targetAPI;
  const url = new URL(`${domain}${portToAppend}${endpoint}`);

  url.search = new URLSearchParams(trimmedParams);

  return new Promise((resolve, reject) => {
    const rejector = (err) => { reject(err); };

    const handleError = alertAPIError || rejector;

    window.fetch(
      url,
      {
        method,
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          Authorization: `Bearer ${key}`,
        },
      },
    ).then((response) => {
      const code = response.code || response.status;

      if (code > 399) {
        handleError(new Error(`Unified ${endpoint} responded with error ${code}.`));
      } else {
        response.json().then((json) => {
          resolve(json);
        }).catch((err) => {
          handleError(err);
        });
      }
    }).catch((err) => {
      handleError(err);
    });
  });
};

export default contactEndpoint;

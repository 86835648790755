// @TODO make this a mapping so we don't have duplicate fields.

// For percent complete
const extraFieldsForCharger = new Set([
  'CHARGER_PURCHASE_RECEIPT',
  'CHARGER_INSTALLATION_INVOICE',
  'CHARGER_INSTALLATION',
  'BUILDING_PERMIT',
]);

const requiredFieldsByCollection = {
  user: new Set([
    'first_name',
    'last_name',
    'mobile_phone',
    'number_household_members',
    'household_income',
    'email',
    'signature'
  ]),
  chargerAddress: new Set([
    'street_address',
    'postcode'
  ]),
  charger: new Set([
    'make',
    'model',
    'serial_number',
    'purchase_date',
    'purchase_price', 
    'CHARGER_PURCHASE_RECEIPT',
    'CHARGER_INSTALLATION_INVOICE',
    'CHARGER_INSTALLATION',
    'BUILDING_PERMIT',
  ]),
  utility: new Set([
    'account_number',
    'name'
  ]),
  vehicle: new Set([
    'vin',
    'vehicle_make',
    'vehicle_model',
    'model_year',
    'vehicle_registration'
  ])
};

// To determine what pages to show in right hand widget. Some pages allow a skip for now
const requiredFieldsByPage = {
  userProfile: requiredFieldsByCollection.user,
  chargerLocation: requiredFieldsByCollection.chargerAddress,
  myCharger: requiredFieldsByCollection.charger,
  myUtility: requiredFieldsByCollection.utility,
  myVehicle: requiredFieldsByCollection.vehicle
};

extraFieldsForCharger.forEach((field) => {
  if(!requiredFieldsByCollection.charger.has(field)) {
    requiredFieldsByCollection.charger.add(field);
  }
});

if(!requiredFieldsByCollection.vehicle.has('vehicle_registration')) {
  requiredFieldsByCollection.vehicle.add('vehicle_registration');
}

const pageNameMappings = {
  '/user-profile' : 'userProfile',
  '/charger-location' : 'chargerLocation',
  '/my-charger' : 'myCharger',
  '/my-utility' : 'myUtility',
  '/my-ev' : 'myVehicle'
};

export {
  requiredFieldsByCollection,
  requiredFieldsByPage,
  pageNameMappings
};

import React from 'react';
import PropTypes from 'prop-types';

import chooseComponentForDeviceType from '../../../utils/functions/device/chooseComponentForDeviceType';

import annotateClassNameMobile from '../../../utils/functions/device/annotateClassNameMobile';

const SectionWrapper = (
  {
    mobileSection,
    desktopSection,
    sectionClassName,
    isContainer,
  },
) => {
  const containerClass = isContainer
    ? 'container '
    : '';

  return (
    <section className={annotateClassNameMobile(`${containerClass}${sectionClassName}`)}>
      { chooseComponentForDeviceType(mobileSection, desktopSection) }
    </section>
  );
};

export default SectionWrapper;

SectionWrapper.defaultProps = {
  isContainer: true,
};

SectionWrapper.propTypes = {
  mobileSection: PropTypes.node.isRequired,
  desktopSection: PropTypes.node.isRequired,
  sectionClassName: PropTypes.string.isRequired,
  isContainer: PropTypes.bool,
};

const checkFieldCompleted = (fieldValue) => {

  const checkNonNumber = (mysteryTypedValue) => {
    if(!mysteryTypedValue) {
      return false;
    }

    // have already excluded null
    if(typeof mysteryTypedValue === 'object') {
      if(mysteryTypedValue.asset_id || mysteryTypedValue.url) {
        return true;
      }
      return false;
    } else if(typeof checkString === 'string') {
      const cleanedString = mysteryTypedValue.replace(/\s/g, '');
      return cleanedString !== '';
    } else if(Array.isArray(mysteryTypedValue)) {
      return mysteryTypedValue.length > 0;
    }

    // Booleans and other
    return true;
  };

  if(fieldValue === true || fieldValue === false) {
    return true;
  }

  if(isNaN(fieldValue)) {
    return checkNonNumber(fieldValue);
  }  

  return (
    (parseFloat(fieldValue) > 0)
      && (fieldValue !== Infinity)
  );
};

export {
  checkFieldCompleted
};

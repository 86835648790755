import React, { createContext, useReducer, useEffect } from 'react';
import Honeybadger from '@honeybadger-io/js';
import cloneDeep from 'lodash/cloneDeep';

import localStorageKeyring from '../../constants/LOCAL_STORAGE_KEYS';
import LocalStorage from '../../utils/functions/localStorage/LocalStorage';
import { boolToString } from '../../utils/functions/misc/stringToBool';

import INITIAL_USER_STATE_DEFAULT from '../../constants/INITIAL_USER_STATE';
import sanitizeIaaUser from '../../utils/functions/iaaUser/sanitizeIaaUser';
import logger from '../../utils/functions/logging/logger';

const IaaUserState = (state, action) => {
  const newGlobalState = {
    ...state,
  };

  // @TODO DRY this up.
  switch (action.type) {
    // May be occasionally useful but careful....
    case 'SET_USER':
      return {
        UserObject: action.payload,
      };

    case 'SET_PROFILE':
      const newProfile = action.payload;
      newProfile.primary_address_id = newProfile.primary_address_id || 0;

      newGlobalState.UserObject.profile = newProfile;

      return newGlobalState;

    case 'SET_PRIMARY_ADDRESS':
      const newPrimaryAddress = action.payload;
      newPrimaryAddress.owner = boolToString(action.payload.owner);

      // @TODO until endpoints are up set the default to 1
      newPrimaryAddress.address_id = newPrimaryAddress.address_id || 0;
      newGlobalState.UserObject.primaryAddress = newPrimaryAddress;

      return newGlobalState;

    case 'SET_CHARGER_ADDRESS':
      const newChargerAddress = action.payload;

      newChargerAddress.active = boolToString(action.payload.active);
      newChargerAddress['charger-at-this-property'] = boolToString(action.payload['charger-at-this-property']);
      newChargerAddress.owner = boolToString(action.payload.owner);

      if (newChargerAddress.power_supplier === null) {
        newChargerAddress.power_supplier = {
          account_number: '',
          name: '',
        };
      }

      // @TODO until endpoints are up set the default to 2
      newChargerAddress.address_id = newChargerAddress.address_id || 0;
      newGlobalState.UserObject.chargerAddress = newChargerAddress;

      // @TODO do we need to set a power supplier here?

      return newGlobalState;

    case 'SET_CHARGER':
      const newCharger = action.payload;

      newCharger.equipment_id = newCharger.equipment_id || 0;

      if (!(newCharger.equipment_id === 0 && state.UserObject.charger && state.UserObject.charger.equipment_id !== 0)) {
        newGlobalState.UserObject.charger = newCharger;
      }
      return newGlobalState;

    case 'RESET_CHARGER_MODEL':
      newGlobalState.UserObject.charger.model = action.payload;

      return newGlobalState;

    case 'SET_VEHICLE':
      const newVehicle = action.payload;

      if (LocalStorage.getLocalDatapoint(localStorageKeyring.hasUploadedVehicleRegistration)) {
        const vehicleReg = state.UserObject.vehicle.images.vehicle_registration;

        if (!vehicleReg.url) {
          newVehicle.images.vehicle_registration.url = 'done';
        }
      }

      // @TODO at what point is equipment type set?
      newVehicle.equipment_id = newVehicle.equipment_id || 0;
      // used for when only a single equipment is returned from backend
      if (!(newVehicle.equipment_id === 0 && state.UserObject.vehicle && state.UserObject.vehicle.equipment_id !== 0)) {
        newGlobalState.UserObject.vehicle = newVehicle;
      }

      return newGlobalState;

    case 'SET_INCENTIVES':
      const incentives = action.payload;

      logger(incentives, 'IAAUserEntity');

      newGlobalState.UserObject.incentives = incentives;

      return newGlobalState;

    case 'SET_ERROR_STATE':
      newGlobalState.errorState = action.payload;

      return newGlobalState;

    case 'SET_CURRENT_INCENTIVE':
      const currentIncentive = action.payload;

      newGlobalState.UserObject.currentIncentive = currentIncentive;

      return newGlobalState;

    case 'SET_CURRENT_PAGE':
      newGlobalState.currentPage = action.payload;

      return newGlobalState;

    case 'SET_INCENTIVE_COMPLETE_MESSAGE':
      newGlobalState.incentiveCompleteMessage = action.payload;

      return newGlobalState;

    case 'RESET_VEHICLE_MODEL_AND_YEAR':
      newGlobalState.UserObject.vehicle.model = action.payload;
      newGlobalState.UserObject.vehicle.model_year = action.payload;
      return newGlobalState;

    case 'RESET':
      return cloneDeep(INITIAL_USER_STATE_DEFAULT);

    default:
      return state;
  }
};

const initialUserState =
  LocalStorage.getLocalDatapoint(localStorageKeyring.userReducedObject) || INITIAL_USER_STATE_DEFAULT;

// eslint-disable-next-line react/prop-types
const IaaUserEntity = ({ children }) => {
  const [state, dispatch] = useReducer(IaaUserState, cloneDeep(initialUserState));

  useEffect(() => {
    Honeybadger.setContext({
      iaaUser: sanitizeIaaUser(state),
    });
  }, [state]);

  return <UserContext.Provider value={[state, dispatch]}>{children}</UserContext.Provider>;
};

export const UserContext = createContext(cloneDeep(initialUserState));
export { IaaUserEntity, IaaUserState };

import React, { useContext } from 'react';
import PropTypes from 'prop-types';

import AppStepHeader from '../../../Atoms/AppStepHeader/AppStepHeader';
import makeAddressForm from '../functions/makeAddressForm';

import { updatePrimaryAddressFunction, updateChargerAddressFunction } from '../../../../apiInterface/responseHandlers/loadToGlobalState';
import { UserContext } from '../../../IaaUserEntity/IaaUserEntity';
import logger from '../../../../utils/functions/logging/logger';

const ChargerLocationMainContent = (
  {
    language,
    inputComponentsMustValidate,
    hasInvalidInput,
  },
) => {

  const contextArr = useContext(UserContext);
  const [iaaUser, dispatch] = contextArr;

  const chargerAddress = iaaUser.UserObject.chargerAddress;

  const updateAddress = (newValue, fieldName) => {
    const newAddress = { ...chargerAddress };
    newAddress[fieldName] = newValue;
    updatePrimaryAddressFunction(dispatch)(newAddress);
    updateChargerAddressFunction(dispatch)(newAddress);
    logger(iaaUser, 'ChargerLocationMainContent');
  };

  return (
    <div className="ChargerLocation">
      <AppStepHeader
        language={language}
        headlineID="chargerLocation.headline.headline"
        blurbID="chargerLocation.headline.blurb"
      />

      {
        makeAddressForm(
          "primary",
          true,
          {
            address: chargerAddress,
            updateAddress: updateAddress,
            language,
            hasInvalidInput,
            inputComponentsMustValidate,
          },
        )
      }
    </div>
  );
};

export default ChargerLocationMainContent;

ChargerLocationMainContent.propTypes = {
  language: PropTypes.string.isRequired,
  hasInvalidInput: PropTypes.func.isRequired,
  inputComponentsMustValidate: PropTypes.bool.isRequired,
  // setInputComponentsMustValidate: PropTypes.func.isRequired,
};

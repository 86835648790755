import React from 'react';
import PropTypes from 'prop-types';

import SectionWrapper from '../SectionWrapper/SectionWrapper';
import AppStepHeaderDesktop from './subcomponents/AppStepHeaderDesktop';
import AppStepHeaderMobile from './subcomponents/AppStepHeaderMobile';

const AppStepHeader = (
  {
    headlineID,
    blurbID,
    language,
  },
) => {
  const sectionClassName = 'AppStepHeaderContainer';

  const mobileSection = (
    <AppStepHeaderMobile
      headlineID={headlineID}
      blurbID={blurbID}
      language={language}
    />
  );

  const desktopSection = (
    <AppStepHeaderDesktop
      headlineID={headlineID}
      blurbID={blurbID}
      language={language}
    />
  );

  return (
    <SectionWrapper
      language={language}
      mobileSection={mobileSection}
      desktopSection={desktopSection}
      sectionClassName={sectionClassName}
    />

  );
};

export default AppStepHeader;

AppStepHeader.defaultProps = {
  blurbID: null,
};

AppStepHeader.propTypes = {
  headlineID: PropTypes.string.isRequired,
  blurbID: PropTypes.string,
  language: PropTypes.string.isRequired,
};

import React from 'react';
import PropTypes from 'prop-types';

import DesktopDashboardContent from './DesktopDashboardContent';

// @TODO this component is possibly unnecessary.

const TabletDashboardContent = (
  { 
    language,
    setCurrentIncentiveByID
  }
) => {

  return(
    <DesktopDashboardContent 
      language={language}
      setCurrentIncentiveByID={setCurrentIncentiveByID}
    />
  );
};

export default TabletDashboardContent;

TabletDashboardContent.propTypes = {
  language: PropTypes.string.isRequired,
  setCurrentIncentiveByID: PropTypes.func.isRequired
};

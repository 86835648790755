const placeholderIncentives = {
  availableIncentivesUnknown: {
    name: "Available Incentives Unknown",
    type: "",
    typical_amount: "",
    description: 'Update your Location to identify incentives we can help you apply for',
    incentive_id: -1,
    status: "",
    input_fields: [],
    placeholder: true
  },
  noAvailableIncentivesForZip: {
    name: "No Incentive Eligible for Incentive Application Support in this zip code",
    type: "",
    typical_amount: "",
    description: 'Unfortunately, we cannot help you apply for any incentive. Please check back later.',
    incentive_id: -1,
    status: "",
    input_fields: [],
    placeholder: true
  },
  nowCheckingIncentives: {
    name: "Checking for available incentives",
    type: "",
    typical_amount: "",
    description: '',
    incentive_id: -1,
    status: "",
    input_fields: [],
    placeholder: true  
  }
};

export default placeholderIncentives;

import {
  mapAddress,
  mapEquipment
} from '../mapStateDataToRequestBody';
import contactAmplifyEndpoint from '../../../../contactEndpoint/contactAmplifyEndpoint';
import { triggerAppWaitingMode, triggerGetUserData } from '../pageCycles/pageCycleManager';

// Because in the transactions around address, we also have to process responses
import { 
  updatePrimaryAddressFunction, 
  updateChargerAddressFunction, 
  updateUserFunction, 
  getIncentives
} from '../../../../responseHandlers/loadToGlobalState';
import processVehicleAndChargerData from "../../../../responseHandlers/processVehicleAndChargerData";
import filterUnfilledImages from '../../filterUnfilledImages';
import LocalStorage from '../../../../../utils/functions/localStorage/LocalStorage';
import localStorageKeyring from '../../../../../constants/LOCAL_STORAGE_KEYS';
import appendAptIfPresent from './supportingFunctions/appendAptIfPresent';
import checkApt from '../../../../responseHandlers/functions/checkApt';

const rememberLastZip = (zip) => {
  LocalStorage.setLocalDatapoint(localStorageKeyring.lastZIP, zip);
};

const getLastZip = () => {
  return LocalStorage.getLocalDatapoint(localStorageKeyring.lastZIP);
};

// @TODO may be better to remove these functions to a separate file as we'll also be using them when 
// selecting one from a list.
const setUserPrimaryAddressID = ({
  address, 
  user,
  dispatch,
  alertAPIError
}) => {
  const addressID = address.address_id;
  const mappedUser = user;
  //const mappedUser = mapUserProfile(user);
  mappedUser.primary_address_id = addressID;

  return new Promise((resolve, reject) => {
    contactAmplifyEndpoint(
      '/user/profile',
      'put',
      {
        body: mappedUser
      },
      alertAPIError
    )
      .then((response) => {
        updateUserFunction(dispatch)(response);

        resolve();
      })
      .catch((err) => {
        reject(err);
      });
  });
};

const setChargerAddressID = ({
  address,
  charger,
  dispatch,
  alertAPIError
}) => {

  const addressID = address.address_id;

  const method = (charger.address_ids.length === 0)
    ? 'post'
    : 'put';

  const mappedCharger = mapEquipment(charger);  

  return new Promise((resolve, reject) => {
    if(addressID === mappedCharger[0].address_ids[0]) {
      resolve();
      return;
    }

    mappedCharger[0].address_ids = [addressID];
    mappedCharger[0].images = filterUnfilledImages(mappedCharger[0].images);

    contactAmplifyEndpoint(
      '/user/equipment',
      method,
      {
        body: mappedCharger,
      },
      alertAPIError
    )
      .then((response) => {
        processVehicleAndChargerData(response, dispatch);
        resolve();
      })
      .catch((err) => {
        reject(err);
      })
    ;
  });
};

const sendAddressToServer = ({
  currentAddress, 
  user, 
  charger,
  dispatch,
  alertAPIError, 
  language
}) => {
  const mappedAddress = mapAddress(currentAddress);
  const endpoint = '/user/addresses';

  const updateExistingAddress = () => {
    //Just update the address and we're out of here.

    return new Promise((resolve, reject) => {
      contactAmplifyEndpoint(
        endpoint,
        'put',
        {
          body: [mappedAddress],
          queryStringParameters: {}
        },
        alertAPIError
      )
        .then((response) => {
          if(response[0].postcode !== getLastZip()) {

            rememberLastZip(response[0].postcode);

            // Trigger refresh of incentives.
            getIncentives(dispatch)([]);
          }          
        
          resolve();
        })
        .catch((err) => {
          reject(err);
        })
      ;
    });
  };

  const createNewAddress = () => {
    // We also need to set the address id on the user and the equipment, so a few more steps here.
    return new Promise((resolve, reject) => {
      contactAmplifyEndpoint(
        endpoint,
        'post',
        {
          body: [mappedAddress],
          queryStringParameters: {}
        },
        alertAPIError
      )
        .then((response) => {
          const addressResponse = checkApt(response[0], language);

          updateChargerAddressFunction(dispatch)(addressResponse);

          // FOR NOW. Just so we're covered where that is required.
          updatePrimaryAddressFunction(dispatch)(addressResponse);

          setUserPrimaryAddressID(
            {
              address: response[0],
              user,
              dispatch,
              alertAPIError
            }
          )
            .then(() => {
              setChargerAddressID(
                {
                  address: response[0],
                  charger,
                  dispatch,
                  alertAPIError
                }
              );
            })
            .then(() => {
              resolve();
              rememberLastZip(response[0].postcode);
            })
            .catch((err) => {
              reject(err);
            });
        })
        .catch((err) => {
          reject(err);
        });

    });
  };

  return new Promise((resolve, reject) => {
    const promiseToKeep = currentAddress.address_id === 0
      ? createNewAddress
      : updateExistingAddress;

    promiseToKeep()
      .then(() => {
        resolve();
      })
      .catch((err) => {
        reject(err);
      });  
  });

};

const chargerLocationPromise = (
  {
    user,
    currentAddress,
    charger,
    setDisplayPending,
    triggerAllInputComponentsValidPromise,
    dispatch, 
    alertAPIError,
    language
  },
) => {

  return new Promise((resolve, reject) => {
    triggerAllInputComponentsValidPromise()
      .then(() => {
        triggerAppWaitingMode(
          {
            setDisplayPending
          }
        );

        let clonedCurrentAddress = { ...currentAddress };
        clonedCurrentAddress = appendAptIfPresent(clonedCurrentAddress, language); 

        sendAddressToServer({
          currentAddress: clonedCurrentAddress, 
          user, 
          charger,
          dispatch,
          alertAPIError,
          language
        })
          .then(() => {
            resolve();
          })
          .catch((err) => {
            alertAPIError(err);
          })
          .finally(() => {
            triggerGetUserData({
              setDisplayPending,
            });
          });
      })
      .catch((err) => {
        reject(err);
      });

  });
};

export default chargerLocationPromise;

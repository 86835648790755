const optionsByNumber = (lower, upper, interval = 1) => {
  const options = [];

  for (let i = lower; i <= upper; i += interval) {
    options.push(
      {
        name: `${i}`,
        value: `${i}`,
      },
    );
  }

  return options;
};

const optionNameAndValSame = (arr) => {
  const options = [];

  arr.forEach((str) => {
    options.push({
      name: str,
      value: str,
    });
  });

  return options;
};

export { optionsByNumber, optionNameAndValSame };

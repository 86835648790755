// @TODO for a real fix, have displayPending and renderReady as properties on a single state.
const triggerGetUserData = ({
  setDisplayPending
}) => {
  setDisplayPending(false);
};

const triggerAppWaitingMode = ({
  setDisplayPending
}) => {
  setDisplayPending(true);
};

export {
  triggerGetUserData,
  triggerAppWaitingMode
};

import React, { useContext } from 'react';
import PropTypes from 'prop-types';

import DashboardChargerHead from '../../../DashboardApplicationProgress/DashboardChargerHead/DashboardChargerHead';
import TabletProfileModalLauncher from './TabletProfileModalLauncher';

import ApplicationProgressMonitor from '../../../../utils/helpers/ApplicationProgressMonitor/ApplicationProgressMonitor';
import incentiveCatalogBuilder from '../../../DashboardApplicationProgress/functions/incentiveCatalogBuilder';
import assembleTabletDashboardContentBlocks from '../functions/assembleTabletDashboardContentBlocks';
import { renderingInTablet } from '../../../../utils/functions/device/determinePlatform';

import { UserContext } from '../../../IaaUserEntity/IaaUserEntity';

import '../css/TabletDashboardNav.scss';


const TabletDashboardNav = ({
  language,
  setCurrentIncentiveByID
}) => {

  const contextArr = useContext(UserContext);
  const iaaUser = contextArr[0] || contextArr;

  const user = iaaUser.UserObject.profile;
  const equipment = iaaUser.UserObject.charger;
  const currentAddress = iaaUser.UserObject.chargerAddress;
  const vehicle = iaaUser.UserObject.vehicle;
  const incentives = iaaUser.UserObject.incentives || null;

  const dashboardHead = (
    <>
      <DashboardChargerHead
        fractionCompleteFunction={
          ApplicationProgressMonitor
            .getCurrentProgress
            .bind(ApplicationProgressMonitor)}
        user={user}
        equipment={equipment}
        currentAddress={currentAddress}
      />
      <TabletProfileModalLauncher
        equipment={equipment}
        user={user}
        vehicle={vehicle}
        currentAddress={currentAddress}
        language={language}       
      />
    </>
  );

  const incentiveCards = incentiveCatalogBuilder(incentives, language, setCurrentIncentiveByID);

  const dashboardNavContentBlocks = renderingInTablet()
    ? [dashboardHead, ...incentiveCards]
    : [ ...incentiveCards];

  const sectionContent = assembleTabletDashboardContentBlocks(dashboardNavContentBlocks);
  
  return (
    <section className="container TabletDashboardNav">
      {sectionContent}
    </section>
  );

};

export default TabletDashboardNav;  

TabletDashboardNav.propTypes = {
  language: PropTypes.string.isRequired,
  setCurrentIncentiveByID: PropTypes.func.isRequired,
};

import React, { useContext } from 'react';
import PropTypes from 'prop-types';

import Dropdown from '../../../../Atoms/Inputs/Dropdown/Dropdown';
import FieldGroup from '../../../../Atoms/FieldGroup/FieldGroup';

import { getTranslatedMessageFromID } from '../../../../../utils/functions/intl/intlWrapper';
import { checkFieldNotEmpty } from '../../../../Atoms/Inputs/functions/inputHelper';
// import LocalStorage from '../../../../../utils/functions/localStorage/LocalStorage';
// import contactEndpoint from '../../../../../apiInterface/contactEndpoint/contactEndpoint';
import getAllChargerBrands from '../../functions/getAllChargerBrands';
import getChargerModelsFromBrand from '../../functions/getChargerModelsFromBrand';
// import localStorageKeyring from '../../../../../constants/LOCAL_STORAGE_KEYS';
import { EquipmentContext } from '../../../../Equipment/Equipment';

const ChargerSelector = (
  {
    // currentAddress,
    // setDisplayPending,
    // displayPending,
    equipment,
    updateEquipment,
    language,
    inputComponentsMustValidate,
    hasInvalidInput,
  },
) => {
  const equipmentContextArr = useContext(EquipmentContext);
  const [ChargerContext] = equipmentContextArr;
  const chargers = ChargerContext.Chargers;
  /*
  const expectedLocalStorageKey = localStorageKeyring.unifiedAPIChargers;
  const { getLocalDatapoint, setLocalDatapoint } = LocalStorage;
  const chargers = getLocalDatapoint(expectedLocalStorageKey);
  */


  // @TODO we will need to make our own propsCollection to set the charger brand.
  // It's the selection from the make pulldown that yields the charger id.
  // @TODO pull brands from Universal API
  const leftField = (
    <Dropdown
      fieldName="make"
      validate={checkFieldNotEmpty}
      propsCollection={equipment}
      updatePropsCollection={updateEquipment}
      options={getAllChargerBrands(chargers)}
      placeholder={getTranslatedMessageFromID('myCharger.selectChargerBrand', language)}
      legend={getTranslatedMessageFromID('chargerBrand', language)}
      inputComponentsMustValidate={inputComponentsMustValidate}
      hasInvalidInput={hasInvalidInput}
    />
  );

  // Charger ID will be added to root state from this menu. Charger brand menu
  // only will dictate what models are available here.
  const rightField = (
    <Dropdown
      fieldName="model"
      validate={checkFieldNotEmpty}
      propsCollection={equipment}
      updatePropsCollection={updateEquipment}
      options={getChargerModelsFromBrand(chargers, equipment)}
      placeholder={getTranslatedMessageFromID('myCharger.selectChargerModel', language)}
      legend={getTranslatedMessageFromID('chargerModel', language)}
      inputComponentsMustValidate={inputComponentsMustValidate}
      hasInvalidInput={hasInvalidInput}
    />
  );

  return (
    <FieldGroup
      language={language}
      leftField={leftField}
      rightField={rightField}
    />
  );
};

export default ChargerSelector;

ChargerSelector.propTypes = {
  displayPending: PropTypes.bool.isRequired,
  setDisplayPending: PropTypes.func.isRequired,
  currentAddress: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  equipment: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  updateEquipment: PropTypes.func.isRequired,
  language: PropTypes.string.isRequired,
  hasInvalidInput: PropTypes.func.isRequired,
  inputComponentsMustValidate: PropTypes.bool.isRequired,
};

import React from 'react';
import BackNextButtons from '../../Atoms/BackNextButtons/BackNextButtons';

const renderBackNextButtons = (
  previousPage,
  nextPage,
  previousPageButtonText,
  nextPageButtonText,
  handlePageForwardClick,
  sendToAPIOnBackButtonClick
) => {
  if (!previousPage || !nextPage) {
    return null;
  }

  return (
    <BackNextButtons
      previousPage={previousPage}
      nextPage={nextPage}
      previousPageButtonText={previousPageButtonText}
      nextPageButtonText={nextPageButtonText}
      handlePageForwardClick={handlePageForwardClick}
      sendToAPIOnBackButtonClick={sendToAPIOnBackButtonClick}
    />
  );
};

export default renderBackNextButtons;

// import localStorageKeyring from "../../../../constants/LOCAL_STORAGE_KEYS";
// import LocalStorage from "../../../../utils/functions/localStorage/LocalStorage";
import React from 'react';
import { validateVin } from '../../../Atoms/Inputs/functions/validate';
import mapVehicleMakes from '../functions/mapVehicleMakes';
import mapVehicleModels from '../functions/mapVehicleModels';
import mapVehicleYears from '../functions/mapVehicleYears';
import { EquipmentContext } from '../../../Equipment/Equipment';

// @TODO consolidate with drawerContentData and move to utils/functions

const VehicleDetails = (vehicle, updateVehicle) => {
  const contextArr = React.useContext(EquipmentContext);
  const [Vehicles] = contextArr;

  const vehiclesArr = Vehicles.Vehicles;
  return [
    {
      fieldName: 'vin',
      fieldLegendID: 'vin',
      fieldLength: 17,
      propsCollection: vehicle,
      update: updateVehicle,
      validate: validateVin,
    },
    {
      fieldName: 'make',
      fieldLegendID: 'carMake',
      fieldLength: 10,
      propsCollection: vehicle,
      optionsHelper: () => {
        const storedList = vehiclesArr;

        return mapVehicleMakes(storedList);
      },
      update: updateVehicle,
      dropdown: true,
      placeholder: 'Please Select a Make',
    },
    {
      fieldName: 'model',
      fieldLegendID: 'carModel',
      fieldLength: 17,
      propsCollection: vehicle,
      optionsHelper: () => {
        const storedList = vehiclesArr;

        return mapVehicleModels(vehicle, storedList);
      },
      update: updateVehicle,
      dropdown: true,
      placeholder: 'Please Select a Make FIrst',
    },
    {
      fieldName: 'model_year',
      fieldLegendID: 'year',
      fieldLength: 5,
      propsCollection: vehicle,
      optionsHelper: () => {
        const storedList = vehiclesArr;

        return mapVehicleYears(vehicle, storedList);
      },
      update: updateVehicle,
      dropdown: true,
      placeholder: 'Please Select Model Year',
    },
  ];
};

const ownerDetails = (user, updateUser, currentAddress, updateCurrentAddress) => [
  {
    fieldName: 'first_name',
    fieldLegendID: 'firstName',
    fieldLength: 10,
    propsCollection: user,
    update: updateUser,
  },
  {
    fieldName: 'last_name',
    fieldLegendID: 'lastName',
    fieldLength: 15,
    propsCollection: user,
    update: updateUser,
  },
  {
    fieldName: 'street_address',
    fieldLegendID: 'address',
    fieldLength: 15,
    propsCollection: currentAddress,
    update: updateCurrentAddress,
  },
  {
    fieldName: 'postcode',
    fieldLegendID: 'zipCode',
    fieldLength: 15,
    propsCollection: currentAddress,
    update: updateCurrentAddress,
  },
];

export { VehicleDetails, ownerDetails };

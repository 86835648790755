import React from 'react';
import PropTypes from 'prop-types';

import ApplicationStep from '../../Atoms/ApplicationStep/ApplicationStep';

import listApplicationSteps from './functions/listApplicationSteps';
import findCurrentStepNumber from './functions/findCurrentStepNumber';

const ApplicationStepsNavDesktop = (
  {
    currentPage,
    language,
  },
) => {

  const pages = listApplicationSteps(language);

  const stepNumber = findCurrentStepNumber(pages, currentPage);

  // @TODO lots of better ways to handle this. See how it's handled for mobile.
  if(stepNumber < 0) {
    return null;
  }

  return (
    <section className="container AppStepBar">
      <div className="ApplicationStepsNav row text-center">
        {
          pages.map((page) => (
            <ApplicationStep
              targetPage={page.targetPage}
              titleText={page.titleText}
              currentPage={currentPage}
              key={page.titleText}
            />
          ))
        }
      </div>
    </section>
  );
};
export default ApplicationStepsNavDesktop;

ApplicationStepsNavDesktop.propTypes = {
  currentPage: PropTypes.string.isRequired,
  language: PropTypes.string.isRequired,
};

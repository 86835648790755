import React from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';

import IAAButton from '../../../Atoms/Buttons/IAAButton';

import { getTranslatedMessageFromID } from '../../../../utils/functions/intl/intlWrapper';

import largeGreenCheck from '../../../../customizations/assets/images/largeGreenCheck.svg';

import '../css/SubmitApplication.scss';

const SubmitApplicationMainContent = ({ 
  language
}) => {
  const history = useHistory();

  return (
    <div className="SubmitApplication text-center">
      <div className="green-check">
        <img src={largeGreenCheck} alt="" />
      </div>
      <div className="congrats">
        {getTranslatedMessageFromID("submitApplication.title", language)}
      </div>
      <div>
        {getTranslatedMessageFromID("submitApplication.paragraphs.1", language)}{" "}
        <a
          href="mailto:veloz-iaa-support@zappy.freshdesk.com"
          className="strong"
        >
          veloz-iaa-support@zappy.freshdesk.com
        </a>
      </div>

      <div className="back-to-dashboard">
        <IAAButton
          onClick={() => {
            history.push("/dashboard");
          }}
          label="backToDashboard"
        >
          Back to Dashboard
        </IAAButton>
      </div>
    </div>
  );
};

export default SubmitApplicationMainContent;

SubmitApplicationMainContent.propTypes = {
  language: PropTypes.string.isRequired
};

import { checkFieldCompleted } from "../../../../../../utils/fields/checkFieldCompleted";

const checkIncentiveFieldsComplete = (inputFields) => {

  // Potential issue - for sets of Boolean values we will need at least one to be true.
  // How do we recognize sets of values though?  

  // @TODO should be able to combine these loops
  const requiredFields = inputFields.filter((field) => {
    return field.type && field.type.toLowerCase() !== 'boolean';
  });

  let allFieldsComplete = true;

  for(let i = 0; i < requiredFields.length; i++) {
    const fieldValue = requiredFields[i].user_input;

    if(!checkFieldCompleted(fieldValue)) {
      allFieldsComplete = false;
      break;
    }
  }

  return allFieldsComplete;
};

export default checkIncentiveFieldsComplete;

import React from 'react';
import PropTypes from 'prop-types';

import { renderingInAnyMobile } from '../../../../../utils/functions/device/determinePlatform';
import { getTranslatedMessageFromID } from '../../../../../utils/functions/intl/intlWrapper';
import chargersPhotoprocessors from '../../data/chargersPhotoprocessors';
import PhotoProcessor from '../../../../Atoms/Inputs/PhotoProcessor/PhotoProcessor';


const MyChargerPhotoProcessorSet = (
  {
    language,
    equipmentID,
    setDisplayPending,
    hasInvalidInput,
    inputComponentsMustValidate,
    assetType
  },
) => {
  const topMessageId = renderingInAnyMobile()
    ? 'photoprocessor.takeAPhotoOfThe'
    : 'photoprocessor.uploadPhoto';

  const topMessage = getTranslatedMessageFromID(topMessageId, language);

  return (
    <>
      {chargersPhotoprocessors(language).map(processor => {
        return (
          <div
            key={`photo_processor_${processor.id}`}
          >
            <PhotoProcessor
              id={processor.id}
              equipmentID={equipmentID}
              setDisplayPending={setDisplayPending}
              hasInvalidInput={hasInvalidInput}
              inputComponentsMustValidate={inputComponentsMustValidate}
              language={language}
              topMessage={topMessage}
              bottomMessage={processor.bottomMessage}
              tooltip={processor.tooltip}
              assetType={assetType}
            />
          </div>
        );
      })}
    </>
  );
};

export default MyChargerPhotoProcessorSet;

MyChargerPhotoProcessorSet.propTypes = {
  equipmentID: PropTypes.string.isRequired,
  setDisplayPending: PropTypes.func.isRequired,
  language: PropTypes.string.isRequired,
  hasInvalidInput: PropTypes.func.isRequired,
  inputComponentsMustValidate: PropTypes.bool.isRequired,
  assetType: PropTypes.string.isRequired,

};

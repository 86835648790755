// code adapted from https://themeteorchef.com/tutorials/reusable-seo-with-react-helmet

import React from 'react';
import PropTypes, { string } from 'prop-types';
import { Helmet } from 'react-helmet-async';

const absoluteUrl = (path) => `${process.env.REACT_APP_SITE_PUBLIC_URL}${path}`;

const seoImageURL = (image) => {
  const regexFqdn = /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([-.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/;
  return (regexFqdn.test(image)) ? image : `${process.env.REACT_APP_SITE_PUBLIC_URL}${image}`;
};

// @TODO move out to supporting js file
// const getTranslatedTitle = (titleId) => (titleId
// ? getTranslatedMessageFromID(titleId)
// : null);

// @TODO honestly this should be in its own file.
const getMetaTags = ({
  language,
  title,
  description,
  url,
  contentType,
  published,
  updated,
  category,
  tags,
  twitterHandle,
  image,
}) => {
  const metaTags = [
    { itemprop: 'name', content: title },
    { name: 'og:type', content: contentType || 'website' },
    { name: 'og:url', content: url },
  ];

  if (title) {
    metaTags.push({ name: 'og:title', content: `${title}` });
    metaTags.push({ name: 'og:site_name', content: `${title}` });
  }
  if (description) {
    metaTags.push({ itemprop: 'description', content: description });
    metaTags.push({ name: 'description', content: description });
    metaTags.push({ name: 'og:description', content: description });
  }

  if (published) metaTags.push({ name: 'article:published_time', content: published });
  if (updated) metaTags.push({ name: 'article:modified_time', content: updated });
  if (category) metaTags.push({ name: 'article:section', content: category });
  if (tags) metaTags.push({ name: 'article:tag', content: tags });
  if (twitterHandle) {
    metaTags.push({ name: 'twitter:card', content: 'summary' });
    metaTags.push({ name: 'twitter:site', content: twitterHandle });
    metaTags.push({ name: 'twitter:title', content: `${title}` });
    metaTags.push({ name: 'twitter:description', content: description });
    metaTags.push({ name: 'twitter:creator', content: twitterHandle });
  }

  if (image) {
    if (twitterHandle) {
      metaTags.push({ name: 'twitter:image:src', content: seoImageURL(image) });
      metaTags.push({ name: 'twitter:card', content: 'summary_large_image' });
    }
    metaTags.push({ itemprop: 'image', content: seoImageURL(image) });
    metaTags.push({ name: 'og:image', content: seoImageURL(image) });
    metaTags.push({ name: 'image', property: 'og:image', content: seoImageURL(image) });
  }
  return metaTags;
};

// @TODO also this should be in its own file.
const getHtmlAttributes = ({
  schema,
}) => {
  let result = {
    lang: 'EN',
  };
  if (schema) {
    result = {
      ...result,
      itemscope: undefined,
      itemtype: `http://schema.org/${schema}`,
    };
  }
  return result;
};

getHtmlAttributes.propTypes = {
  schema: PropTypes.string,
};

const Seo = ({
  language,
  schema = '',
  title,
  siteName,
  description,
  path,
  contentType,
  published,
  updated,
  category,
  tags,
  twitterHandle,
  image,
}) => (
  <Helmet
    htmlAttributes={getHtmlAttributes({
      schema,
    })}
    title={title}
    link={[
      { rel: 'canonical', href: absoluteUrl(path) },
    ]}
    meta={getMetaTags({
      language,
      title,
      description,
      contentType,
      url: absoluteUrl(path),
      published,
      updated,
      category,
      tags,
      twitterHandle,
      siteName,
      image,
    })}
  />
);

// @TODO what are the use cases for
// schema, published, updated, category, tags, siteName?
// I do not see passed in from PageLayout.js. Can they be removed?
Seo.defaultProps = {
  schema: '',
  title: '',
  description: '',
  path: '',
  contentType: '',
  published: '',
  updated: '',
  category: '',
  tags: [],
  siteName: '',
  twitterHandle: '',
  image: '',
};

// @TODO in unit test context image is an object, while in a browser it's a string.
// What up with that?
Seo.propTypes = {
  language: PropTypes.string.isRequired,
  schema: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.string,
  path: PropTypes.string,
  contentType: PropTypes.string,
  published: PropTypes.string,
  updated: PropTypes.string,
  category: PropTypes.string,
  tags: PropTypes.arrayOf(PropTypes.string),
  siteName: string,
  twitterHandle: string,

  // @TODO if specifying string or object, will fail in either unit tests or in browser.
  // Ideally let's fix.
  image: PropTypes.any, // eslint-disable-line react/forbid-prop-types
};

export default Seo;

import React from 'react';
import PropTypes from 'prop-types';

import StreetAddressAndZip from './subcomponents/StreetAddressAndZip';

const AddressForm = (
  {
    address,
    updateAddress,
    language,
    hasInvalidInput,
    inputComponentsMustValidate,
    streetAddressLegend,
    streetAddressPlaceholder,
    fieldIDSuffix
  },
) => {
  return (
    <>
      <StreetAddressAndZip
        address={address}
        updateAddress={updateAddress}
        language={language}
        hasInvalidInput={hasInvalidInput}
        inputComponentsMustValidate={inputComponentsMustValidate}
        streetAddressLegend={streetAddressLegend}
        streetAddressPlaceholder={streetAddressPlaceholder}
        fieldIDSuffix={fieldIDSuffix}
      />
    </>
  );
};

export default AddressForm;

AddressForm.defaultProps = {
  addressUpdating: 'both',
};

AddressForm.propTypes = {
  address: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  updateAddress: PropTypes.func.isRequired,
  language: PropTypes.string.isRequired,
  hasInvalidInput: PropTypes.func.isRequired,
  inputComponentsMustValidate: PropTypes.bool.isRequired,
  streetAddressLegend: PropTypes.string.isRequired,
  streetAddressPlaceholder: PropTypes.string.isRequired,
  addressUpdating: PropTypes.string,
  fieldIDSuffix: PropTypes.string.isRequired
};

import React from 'react';
import PropTypes from 'prop-types';

import { useHistory } from 'react-router-dom';
import CardTitle from '../CardParts/CardTitle';
import CardValue from '../CardParts/CardValue';
import CardDescription from '../CardParts/CardDescription';
import CardBottom from '../CardParts/CardBottom/CardBottom';

import setCardBottomType from './functions/setCardBottomType';
import determineClickHandler from './functions/determineClickHandler';

const IncentiveCard = (
  {
    incentiveData,
    language,
    setCurrentIncentiveByID,
  },
) => {
  const cardBottomType = setCardBottomType(
    incentiveData.status, 
    incentiveData.input_fields,
    incentiveData.incentive_id
  );
  const history = useHistory();

  return (
    <div
      className="IAAIncentiveCard text-center"
    >
      <CardTitle
        title={incentiveData.name}
      />
      <CardValue
        incentiveType={incentiveData.type}
        monetaryValue={incentiveData.typical_amount}
      />
      <CardDescription
        description={incentiveData.description}
        language={language}
        incentiveID={incentiveData.incentive_id}
      />
      <CardBottom
        cardBottomType={cardBottomType}
        language={language}
        incentiveDetailsURL={incentiveData.details_url}
        incentiveID={incentiveData.incentive_id}
        onClick={determineClickHandler(
          cardBottomType,
          incentiveData.incentive_id,
          setCurrentIncentiveByID,
          history,
        )}
      />
    </div>
  );
};

export default IncentiveCard;

IncentiveCard.propTypes = {
  incentiveData: PropTypes.object.isRequired, // eslint-disable-line
  language: PropTypes.string.isRequired,
  setCurrentIncentiveByID: PropTypes.func.isRequired,
};

import React, { useContext } from 'react';
import PropTypes from 'prop-types';

import { requiredFieldsByPage, pageNameMappings } from '../../../../utils/helpers/ApplicationProgressMonitor/data/constants';
import { UserContext } from '../../../IaaUserEntity/IaaUserEntity';

const RequiredFieldIndicator = ({
  fieldName
}) => {
  const [iaaUser] = useContext(UserContext);

  const currentPage = pageNameMappings[iaaUser.currentPage];
  const requiredFieldNode = (<span className="required_field">*</span>);

  let elementToRender = 
    requiredFieldsByPage[currentPage]
    && requiredFieldsByPage[currentPage].has(fieldName) 
      ? requiredFieldNode
      : null;

  // @TODO hopefully someay we won't need to make exceptions for vehicle make and model
  if(fieldName === 'make' || fieldName === 'model') {
    elementToRender = requiredFieldNode;
  }    

  return elementToRender;
};

export default RequiredFieldIndicator;

RequiredFieldIndicator.propTypes = {
  fieldName: PropTypes.string.isRequired
};

import { optionNameAndValSame } from '../../../../utils/functions/forms/dropdown';

const mapVehicleModels = (vehicle, storedList) => {
  if (!storedList.length > 0) {
    return [];
  }

  const vehicleModels = new Set();

  const make = vehicle.make;
  const availableVehicles = storedList.filter((listedVehicle) => {
    return listedVehicle.make === make;
  });

  availableVehicles.forEach((availableVehicle) => {
    vehicleModels.add(availableVehicle.model);
  });

  return optionNameAndValSame(Array.from(vehicleModels));
};

export default mapVehicleModels;

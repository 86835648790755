import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';

import RequiredFieldIndicator from '../RequiredFieldIndicator/RequiredFieldIndicator';

import { handleClick, checkForValidation } from '../functions/inputHelper';

const TextField = (
  {
    validate,
    fieldName,
    fieldWidth,
    propsCollection,
    updatePropsCollection,
    defaultValue,
    minFieldLength,
    maxFieldLength,
    legend,
    className,
    inputComponentsMustValidate,
    hasInvalidInput,
    additionalOnChange = (event) => event,
    prepend,
    fieldIDSuffix,
    type,
  },
) => {

  const [warning, setWarning] = useState('');
  const fieldWidthStyle = { width: fieldWidth };

  // @TODO takes a little more effort but we should try to eliminate having value in state
  const [value, setValue] = useState(propsCollection[fieldName]);
  const tfRef = useRef();

  if(propsCollection[fieldName] && !value) {
    setValue(propsCollection[fieldName]);
  }

  const setDisplayValue = (value) => {
    const prependedText = value
      .toString()
      .substring(0, prepend.length) === prepend
      ? value
      : `${prepend}${value}`;

    return prependedText;
  };

  useEffect(() => {

    const validateOnCheck = () => {
      if (inputComponentsMustValidate) {
        checkForValidation(
          validate, 
          typeof value === 'string' ? value.trim() : value,
          defaultValue, 
          setWarning, 
          fieldName, 
          hasInvalidInput
        );
      }
    };

    validateOnCheck();
  });

  const fieldID = `input_${fieldName}${fieldIDSuffix}`;

  // @TODO extract onClick and onKeyDown to other files
  return (
    <div className={`${className} IAAInput`}>
      <div className="inputLegend">
        {legend}
        <RequiredFieldIndicator 
          fieldName={fieldName}
        />
      </div>
      <input
        ref={tfRef}
        className="inputField"
        id={fieldID}
        value={ value
          ? setDisplayValue(value) 
          : ''
        }
        placeholder={defaultValue}
        style={fieldWidthStyle}
        minLength={minFieldLength != null ? minFieldLength : "1" }
        maxLength={maxFieldLength != null ? maxFieldLength : "100" } 
        onClick={(event) => {
          handleClick(event, defaultValue);
        }}
        onChange={(event) => {
          const transitValue = additionalOnChange(event.target.value);

          const newValue = event.target.value;

          // Updates state at the level of this module.
          setValue(transitValue);

          // Updates User Context
          updatePropsCollection(newValue, fieldName);

        }}
        type={type}
      />
      <div className="warning">{warning}</div>
    </div>
  );
};

export default TextField;

TextField.defaultProps = {
  validate: () => {},
  className: '',
  defaultValue: '',
  legend: '',
  fieldWidth: '100%',
  additionalOnChange: (event) => event,
  prepend: '',
  fieldIDSuffix: '',
  type: 'text',
};

TextField.propTypes = {
  validate: PropTypes.func,
  className: PropTypes.string,
  fieldName: PropTypes.string.isRequired,
  defaultValue: PropTypes.any,
  legend: PropTypes.string,
  fieldWidth: PropTypes.string,
  minFieldLength: PropTypes.string,
  maxFieldLength: PropTypes.string,
  inputComponentsMustValidate: PropTypes.bool.isRequired,
  hasInvalidInput: PropTypes.func.isRequired,
  propsCollection: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  updatePropsCollection: PropTypes.func.isRequired,
  additionalOnChange: PropTypes.func,
  prepend: PropTypes.string,
  fieldIDSuffix: PropTypes.string,
  type: PropTypes.string,
};

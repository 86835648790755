import React, { useContext, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import MyChargerPhotoProcessorSet from './submodules/MyChargerPhotoProcessorSet';
import AppStepHeader from '../../../Atoms/AppStepHeader/AppStepHeader';
import contactEndpoint from '../../../../apiInterface/contactEndpoint/contactUnifiedEndpoint';
import ChargerSelector from './submodules/ChargerSelector';
import SerialNumberPriceDatePurchase from './submodules/SerialNumberPriceDatePurchase';
import { UserContext } from '../../../IaaUserEntity/IaaUserEntity';
import { EquipmentContext } from '../../../Equipment/Equipment';
import {
  updateChargerFunction,
  resetChargerModelFunction,
} from '../../../../apiInterface/responseHandlers/loadToGlobalState';

import logger from '../../../../utils/functions/logging/logger';

import '../../../Atoms/Inputs/PhotoProcessor/css/PhotoProcessor.scss';

const MyChargerMainContent = ({
  setDisplayPending,
  displayPending,
  language,
  inputComponentsMustValidate,
  hasInvalidInput,
  alertAPIError,
}) => {
  const userContextArr = useContext(UserContext);
  const [iaaUser, userDispatch] = userContextArr;
  const equipmentContextArr = useContext(EquipmentContext);
  const [equipment, equipmentDispatch] = equipmentContextArr;
  const userCharger = iaaUser.UserObject.charger;
  const userZip = iaaUser.UserObject.primaryAddress.postcode;
  const currentAddress = iaaUser.UserObject.chargerAddress;
  const isMounted = useRef(false);

  const updateCharger = (newValue, fieldName) => {
    const newCharger = { ...userCharger };
    newCharger[fieldName] = newValue;
    updateChargerFunction(userDispatch)(newCharger);
    logger(iaaUser, 'MyChargerMainContent');
  };
  useEffect(() => {
    const fetchChargers = async () => {
      setDisplayPending(true);
      try {
        const chargers = await contactEndpoint(
          'unified',
          '/unsafe/chargers',
          'GET',
          {
            postcode: userZip,
          },
          null,
          alertAPIError
        );
        equipmentDispatch({
          type: 'SET_CHARGERS',
          payload: chargers.chargers,
        });
      } catch (err) {
        alertAPIError(err);
      } finally {
        setDisplayPending(false);
      }
    };
    if (userZip && !equipment.Chargers.length > 0) {
      fetchChargers();
    }
  }, []);

  useEffect(() => {
    isMounted.current ? resetChargerModelFunction(userDispatch)('') : (isMounted.current = true);
  }, [userCharger.make]);
  const equipmentID = `${userCharger.equipment_id}`;

  return (
    <div className="MyCharger">
      <AppStepHeader language={language} headlineID="myCharger.headline.headline" blurbID="myCharger.headline.blurb" />
      <ChargerSelector
        displayPending={displayPending}
        currentAddress={currentAddress}
        setDisplayPending={setDisplayPending}
        equipment={userCharger}
        updateEquipment={updateCharger}
        language={language}
        inputComponentsMustValidate={inputComponentsMustValidate}
        hasInvalidInput={hasInvalidInput}
      />
      <SerialNumberPriceDatePurchase
        equipment={userCharger}
        updateEquipment={updateCharger}
        language={language}
        inputComponentsMustValidate={inputComponentsMustValidate}
        hasInvalidInput={hasInvalidInput}
      />
      <MyChargerPhotoProcessorSet
        equipmentID={equipmentID}
        setDisplayPending={setDisplayPending}
        language={language}
        hasInvalidInput={hasInvalidInput}
        inputComponentsMustValidate={inputComponentsMustValidate}
        assetType="CHARGER_PHOTOGRAPH"
      />
    </div>
  );
};

export default MyChargerMainContent;

MyChargerMainContent.propTypes = {
  setDisplayPending: PropTypes.func.isRequired,
  displayPending: PropTypes.bool.isRequired,
  language: PropTypes.string.isRequired,
  hasInvalidInput: PropTypes.func.isRequired,
  inputComponentsMustValidate: PropTypes.bool.isRequired,
  alertAPIError: PropTypes.func,
};

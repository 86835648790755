import sortAlphabetical from '../../../../utils/functions/misc/sort';
import { optionNameAndValSame } from '../../../../utils/functions/forms/dropdown';

// @TODO consolidate this with the ChargerSelector helpers and move up to utils.
const getAllUtilitiesForZip = (utilitiesFromAPIResponse) => {
  if (!utilitiesFromAPIResponse) {
    return [];
  }

  const utilityNames = new Set();

  utilitiesFromAPIResponse.forEach((utility) => {
    if (!utilityNames.has(utility.name)) {
      utilityNames.add(utility.name);
    }
  });

  let utilityNamesArray = Array.from(utilityNames);
  utilityNamesArray = sortAlphabetical(utilityNamesArray);

  return optionNameAndValSame(utilityNamesArray);
};

export default getAllUtilitiesForZip;

import React from 'react';
import PropTypes from 'prop-types';

import ApplicationAssistantLayout from '../../ApplicationAssistantLayout/ApplicationAssistantLayout';
import DashboardContent from './MainContent/DashboardContent';

const Dashboard = (
  {
    language,
    setCurrentIncentiveByID,
    handlePageForwardClick,
    currentPage
  },
) => { 
  
  return (
    <>
      <ApplicationAssistantLayout
        className="DashboardIAAClass"
        setCurrentIncentiveByID={setCurrentIncentiveByID}
        handlePageForwardClick={handlePageForwardClick}
        language={language}
        incentiveDrawerOpened
        currentPage={currentPage}
      >
        <DashboardContent
          language={language}
          setCurrentIncentiveByID={setCurrentIncentiveByID}
        />
      </ApplicationAssistantLayout>
    </>
  );};
export default Dashboard;

Dashboard.defaultProps = {
  handlePageForwardClick: () => {},
};

Dashboard.propTypes = {
  language: PropTypes.string.isRequired,
  setCurrentIncentiveByID: PropTypes.func.isRequired,
  handlePageForwardClick: PropTypes.func,
  currentPage: PropTypes.string.isRequired
};

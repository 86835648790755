import sortAlphabetical from '../../../../utils/functions/misc/sort';
import { optionNameAndValSame } from '../../../../utils/functions/forms/dropdown';

const getAllChargerBrands = (chargersFromAPIResponse) => {
  if (!chargersFromAPIResponse) {
    return [];
  }

  const chargerNames = new Set();

  chargersFromAPIResponse.forEach((charger) => {
    if (!chargerNames.has(charger.make)) {
      chargerNames.add(charger.make);
    }
  });

  let chargerNamesArray = Array.from(chargerNames);
  chargerNamesArray = sortAlphabetical(chargerNamesArray);

  return optionNameAndValSame(chargerNamesArray);
};

export default getAllChargerBrands;

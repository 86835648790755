import { renderingInAnyMobile } from './determinePlatform';

// @TODO test. Will need to spoof isMobile
const annotateClassNameMobile = (defaultClassNames, mobileClassName = 'mobileClass') => {
  const mobileClass = renderingInAnyMobile()
    ? ` ${mobileClassName}`
    : '';

  return `${defaultClassNames}${mobileClass}`;
};

export default annotateClassNameMobile;

import { Auth } from 'aws-amplify';

import { validateEmail, validatePhoneNumber } from '../../components/Atoms/Inputs/functions/validate';
import filterByField from '../../utils/functions/misc/filterByField';
import { FormatUSPhoneNumber } from '../../utils/helpers/Format';
import fieldsByUpdater from './data/fieldsByUpdater';
import bindDataToField from './functions/bindDataToField';
import loadToGlobalState from './loadToGlobalState';

const processUserData = (userProfile, dispatch) => {

  Auth.currentSession()
    .then((userInfo) => {
      const dataPoints = fieldsByUpdater.updateUser;

      const idToken = userInfo.idToken.payload;
      let defaultPhone = "";
      let defaultEmail = "";

      try {
        validatePhoneNumber(idToken.phone_number);
        defaultPhone = FormatUSPhoneNumber(idToken.phone_number);
      } catch(err) {}

      try {
        validateEmail(idToken.email);
        defaultEmail = idToken.email;
      } catch(err) {}

      const phoneField = filterByField('name', 'mobile_phone', dataPoints)[0];
      const emailField = filterByField('name', 'email', dataPoints)[0];

      phoneField.default = defaultPhone;
      emailField.default = defaultEmail;

      const newUserData = bindDataToField(dataPoints, userProfile);

      loadToGlobalState(dispatch, {
        type: 'SET_PROFILE',
        payload: newUserData
      });
    });
};

export default processUserData;

import { optionNameAndValSame } from '../../../../utils/functions/forms/dropdown';

const mapVehicleMakes = (universalVehicles) => {
  if (Object.keys(universalVehicles).length === 0) {
    return [];
  }
  const vehicleMakes = new Set();

  universalVehicles.forEach((vehicle) => {
    vehicleMakes.add(vehicle.make);
  });

  return optionNameAndValSame(Array.from(vehicleMakes));
};

export default mapVehicleMakes;

import LocalStorage from '../../../../../utils/functions/localStorage/LocalStorage';

const storeResponse = (response, path) => {
  const localStorageKey = `user_data_${path}`;
  LocalStorage.setLocalDatapoint(localStorageKey, response);
};

const responses = {
  '/user': (body) => {
    const response = JSON.stringify(
      {
        code: 0,
        message: 'OK',
      },
    );
    storeResponse(body, 'user-profile');

    return response;
  },
  '/user/profile': (body) => {
    const response = JSON.stringify(
      body,
    );

    return response;
  },
};

const contactAmplifyEndpoint = (
  path,
  method,
  {
    body,
    
  },
) => { 
  
  return new Promise((resolve) => {
    const response = responses[path]
      ? responses[path](body, method)
      : '';

    resolve(response);
  });
};

export default contactAmplifyEndpoint;

import { getTranslatedMessageFromID } from '../../../../utils/functions/intl/intlWrapper';
import React from 'react';

const photoprocessors = (language) => ([
  {
    id: 'CHARGER_PURCHASE_RECEIPT',
    bottomMessage: getTranslatedMessageFromID('photoprocessor.charger.receipt', language),
    tooltip: getTranslatedMessageFromID('myCharger.purchaseReceipt.tooltip', language),
  },
  {
    id: 'CHARGER_INSTALLATION_INVOICE',
    bottomMessage: getTranslatedMessageFromID('photoprocessor.installCharger.receipt', language),
    tooltip: getTranslatedMessageFromID('myCharger.installationReceipt.tooltip', language),
  },
  {
    id: 'CHARGER_INSTALLATION',
    bottomMessage: getTranslatedMessageFromID('photoprocessor.installedCharger', language),
    tooltip: getTranslatedMessageFromID('myCharger.installedCharger.tooltip', language),
  },
  {
    id: 'BUILDING_PERMIT',
    bottomMessage: getTranslatedMessageFromID('photoprocessor.installationPermit', language),
    tooltip: getTranslatedMessageFromID('myCharger.installationPermit.tooltip', language, { lineBreak: <br className="d-block" /> }),
  },
]);

export default photoprocessors;

import React from 'react';
import DashboardChargerHead from '../../DashboardApplicationProgress/DashboardChargerHead/DashboardChargerHead';

const equipment = {
  make: 'Webasto',
  model: 'TurboCord',
  street_address: '1721 W. North Ave',
  municipality: '',
  region: '',
  postcode: '',
};

const currentAddress = {};

const ExampleDashboardChargerhead = () => (
  <DashboardChargerHead
    fractionCompleteFunction={() => 1}
    equipment={equipment}
    user={
      {
        first_name: 'Amy',
        last_name: 'Pohler',
      }
    }
    currentAddress={currentAddress}
    vehicle={{}}
  />
);

export default ExampleDashboardChargerhead;

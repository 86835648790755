import { STATUS_READY_SUBMIT, STATUS_STARTED, STATUS_SUBMITTED } from "../../../../../constants/STRING_CONSTANTS";
import { FormatDate } from "../../../../../utils/helpers/Format";
import contactAmplifyEndpoint from "../../../../contactEndpoint/contactAmplifyEndpoint";
import filterUnfilledImages from "../../filterUnfilledImages";
import { triggerAppWaitingMode, triggerGetUserData } from '../pageCycles/pageCycleManager';

const completeApplicationPromise = (
  {
    incentive,
    charger,
    setDisplayPending,
    alertAPIError,
    backClick
  }
) => {

  const date = FormatDate(new Date());

  return new Promise((resolve, reject) => {
    triggerAppWaitingMode(
      {
        setDisplayPending
      }
    );

    if(incentive.status === STATUS_READY_SUBMIT) {
      incentive.status = STATUS_SUBMITTED;
    }

    if(incentive.status === STATUS_SUBMITTED) {

      // We really shouldn't get here because it shouldn't be possible
      // to edit a submitted application. 
      if(backClick) {
        incentive.status = STATUS_STARTED;
        resolve();
        return;
      }

      incentive.apply_date = date;
    }

    incentive.id = incentive.id || "";
    incentive.equipment_id = charger.equipment_id; 
    if(incentive.images) {
      incentive.images = filterUnfilledImages(incentive.images);
    }

    //  @TODO when there's an incentive.id really should be PUT. Slack message sent to
    // Ricardo 5/7 after 5pm
    contactAmplifyEndpoint(
      '/user/incentive',
      incentive.id 
        ? 'post' // Should be put?
        : 'post',
      {
        body: [
          incentive
        ]
      },
      alertAPIError
    )
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      })
      .finally(() => {
        triggerGetUserData({
          setDisplayPending
        });
      });
  });
};

export default completeApplicationPromise;

import { optionsByNumber } from '../../../../../utils/functions/forms/dropdown';

const getHouseholdSizeValues = optionsByNumber(1, 10);

// Based loosely on this: https://www.nerdwallet.com/article/taxes/california-state-tax
const getHouseholdIncomeValues = [
  {
    name: '< $50,000',
    value: '50000',
  },
  {
    name: '$50,000 to $100,000',
    value: '100000',
  },
  {
    name: '$100,000 to $150,000',
    value: '150000',
  },
  {
    name: '> $150,000',
    value: '200000',
  },
];

export {
  getHouseholdSizeValues,
  getHouseholdIncomeValues,
};

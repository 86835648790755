import sortAlphabetical from '../../../../utils/functions/misc/sort';
import { optionNameAndValSame } from '../../../../utils/functions/forms/dropdown';

const getChargerModelsFromBrand = (chargersFromAPIResponse, selectedCharger) => {
  if (!chargersFromAPIResponse) {
    return [];
  }

  const chargerModels = new Set();
  const chargersOfSelectedBrand = chargersFromAPIResponse.filter(
    (charger) => selectedCharger.make === charger.make,
  );
  chargersOfSelectedBrand.forEach((charger) => {
    if (!chargerModels.has(charger.model)) {
      chargerModels.add(charger.model);
    }
  });

  let sortedChargerModels = Array.from(chargerModels);

  sortedChargerModels = sortAlphabetical(sortedChargerModels);

  return optionNameAndValSame(sortedChargerModels);
};

export default getChargerModelsFromBrand;

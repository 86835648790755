/* eslint camelcase: 0 */

import { stringToBool } from '../../../../utils/functions/misc/stringToBool';

import {
  address,
  userProfile,
  vehicle,
  charger,
  incentive,
  inputField,
} from '../data/requestBodyTemplates';

const mapUserProfile = (
  {
    email,
    firstName,
    fontFamily,
    homePhone,
    householdIncome,
    lastName,
    mobilePhone,
    numberHouseholdMembers,
    primaryAddressID,
    signature,
  },
) => {
  const requestBodyProfile = { ...userProfile };
  requestBodyProfile.email = email;
  requestBodyProfile.first_name = firstName;
  requestBodyProfile.last_name = lastName;
  requestBodyProfile['font-family'] = fontFamily;
  requestBodyProfile.home_phone = homePhone;
  requestBodyProfile.mobile_phone = mobilePhone;
  requestBodyProfile.primary_address_id = primaryAddressID;
  requestBodyProfile.household_income = householdIncome;
  requestBodyProfile.number_household_members = numberHouseholdMembers;
  requestBodyProfile.signature = signature;

  return requestBodyProfile;
};

const mapAddress = (
  {
    address_id,
    street_address,
    municipality = '',
    region = '',
    country = '',
    postcode,
    active = true,
    owner,
    power_supplier
  },
) => {
  const requestBodyAddress = { ...address };

  const powerSupplier = power_supplier;

  requestBodyAddress.power_supplier = powerSupplier;

  requestBodyAddress.address_id = address_id; // eslint-disable-line no-undef
  requestBodyAddress.street_address = street_address;
  requestBodyAddress.municipality = municipality;
  requestBodyAddress.region = region;
  requestBodyAddress.country = country;
  requestBodyAddress.postcode = postcode;
  requestBodyAddress.active = stringToBool(active);
  requestBodyAddress.owner = stringToBool(owner);

  return requestBodyAddress;
};

const mapImages = (images) => {
  return images;
};

const mapInputFields = (inputFields) => {
  const requestBodyInputFields = [];

  inputFields.forEach((listedInputField) => {
    const newRequestBodyInputField = { ...inputField };

    const keys = Object.keys(newRequestBodyInputField);

    keys.forEach((key) => {
      newRequestBodyInputField[key] = listedInputField[key];
    });

    requestBodyInputFields.push(newRequestBodyInputField);
  });

  return requestBodyInputFields;
};

const mapIncentives = (incentives) => {
  const requestBodyIncentives = [];

  // If No Available Equipment Incentives, the page breaks 
  if (incentives === undefined) {
    return requestBodyIncentives;
  }

  incentives.forEach((listedIncentive) => {
    const newRequestBodyIncentive = { ...incentive };

    const incentiveDataPoints = Object.keys(newRequestBodyIncentive);

    incentiveDataPoints.forEach((datapoint) => {
      const additionalMaps = {
        images: mapImages,
        input_fields: mapInputFields,
      };

      if (listedIncentive[datapoint]) {
        if (additionalMaps[datapoint]) {
          newRequestBodyIncentive[datapoint] = additionalMaps[datapoint](
            listedIncentive[datapoint],
          );
        } else newRequestBodyIncentive[datapoint] = listedIncentive[datapoint];
      }
    });

    if(newRequestBodyIncentive.id) {
      requestBodyIncentives.push(newRequestBodyIncentive);    
    }  
  });

  return requestBodyIncentives;
};

const mapEquipment = (equip) => {
  const templates = {
    vehicle,
    charger,
  };

  const selectedTemplate = templates[equip.type];

  const requestBodyEquip = { ...selectedTemplate };
  const { details } = requestBodyEquip;
  if(typeof equip.purchase_price === 'string') {
    const priceCleaned = equip.purchase_price.replace('$', '');
    const priceAsNumber = parseFloat(priceCleaned);
    equip.purchase_price = priceAsNumber;
  }

  requestBodyEquip.equipment_id = equip.equipment_id;
  requestBodyEquip.address_ids = equip.address_ids;

  const detailKeys = Object.keys(details);

  detailKeys.forEach((key) => {
    if (equip[key]) {
      details[key] = equip[key];
    }
  });

  details.type = requestBodyEquip.type;
  requestBodyEquip.images = mapImages(equip.images);
  requestBodyEquip.incentives = mapIncentives(equip.incentives);

  return [requestBodyEquip];
};

export {
  mapUserProfile,
  mapAddress,
  mapEquipment,
  mapIncentives,
};

import React, { useState } from 'react';
import PropTypes from 'prop-types';

import EditableDetail from './EditableDetail/EditableDetail';

import { getTranslatedMessageFromID } from '../../../../utils/functions/intl/intlWrapper';

const EditableDetails = (
  {
    language,
    detailSetName,
    detailSetSubtitle,
    formFieldsList,
    inputComponentsMustValidate,
    hasInvalidInput,
    hasEditMode,
    startInEditMode,
    forceMobile,
    setDisplayPending,
    onChangeEditMode
  },
) => {
  // Should be refactored to remove edit mode 
  const [editMode] = useState(true);

  // const toggleEditMode = () => {
  //   if (editMode) {

  //     onChangeEditMode();
  //     // @TODO save
  //   }
  //   setEditMode(!editMode);
  // };
 
  // const editButtonText = editMode
  //   ? 'save'
  //   : 'edit';

  // const editButtonClass = editMode
  //   ? 'save-button'
  //   : 'edit-button';

  // const editButton = hasEditMode
  //   ? (
  //     <div className={`col-6 text-right ${editButtonClass}`}>
  //       <button
  //         type="button"
  //         onClick={toggleEditMode}
  //       >
  //         {getTranslatedMessageFromID(editButtonText, language)}
  //       </button>
  //     </div>
  //   )
  //   : null;

  const detailsTitle = detailSetName
    ? (<div className="col-6 text-left EditableDetailsHeader">{detailSetName}</div>)
    : null;

  return (
    <div className="EditableDetails">
      <div className="row">
        { detailsTitle }
        {detailSetSubtitle && (<div className="EditableDetailsSubHeader">{detailSetSubtitle}</div>)}
        {/* { editButton } */}
      </div>
      {formFieldsList.map((formField) => (
        <EditableDetail
          validate={formField.validate}
          key={formField.fieldName}
          legend={getTranslatedMessageFromID(formField.fieldLegendID, language)}
          fieldName={formField.fieldName}
          fieldWidth={`${formField.fieldLength}em`}
          specialDataType={formField.specialDataType}
          propsCollection={formField.propsCollection}
          updatePropsCollection={formField.update}
          optionsHelper={formField.optionsHelper}
          dropdown={formField.dropdown}
          localStorageKey={formField.localStorageKey}
          editMode={
            hasEditMode
              ? editMode
              : false
          }
          hasEditMode={hasEditMode}
          inputComponentsMustValidate={inputComponentsMustValidate}
          hasInvalidInput={hasInvalidInput}
          forceMobile={forceMobile}
          setDisplayPending={setDisplayPending}
          placeholder={formField.placeholder}
        />
      ))}
    </div>
  );
};

export default EditableDetails;

EditableDetails.defaultProps = {
  hasEditMode: true,
  detailSetName: '',
  startInEditMode: false,
  forceMobile: false,
  setDisplayPending: () => {},
  onChangeEditMode: () => {}
};

EditableDetails.propTypes = {
  detailSetName: PropTypes.string,
  detailSetSubtitle: PropTypes.string,
  formFieldsList: PropTypes.array.isRequired, // eslint-disable-line react/forbid-prop-types
  language: PropTypes.string.isRequired,
  inputComponentsMustValidate: PropTypes.bool.isRequired,
  hasInvalidInput: PropTypes.func.isRequired,
  hasEditMode: PropTypes.bool,
  startInEditMode: PropTypes.bool,
  forceMobile: PropTypes.bool,
  setDisplayPending: PropTypes.func,
  onChangeEditMode: PropTypes.func,
};

import React from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';

const HomePageNotLoggedInBlurb = (
  {
    headlineTranslateId,
    bodyTranslateId,
  },
) => (
  <div className="homePageNotLoggedInBlurb text-center">
    <div className="blurbHeadline">
      <FormattedMessage
        id={headlineTranslateId}
      />
    </div>
    <p>
      <FormattedMessage
        id={bodyTranslateId}
      />
    </p>
  </div>
);

export default HomePageNotLoggedInBlurb;

HomePageNotLoggedInBlurb.propTypes = {
  headlineTranslateId: PropTypes.string.isRequired,
  bodyTranslateId: PropTypes.string.isRequired,
};

import React from 'react';
import PropTypes from 'prop-types';

import TextField from '../../Inputs/TextField/TextField';
import FieldGroup from '../../FieldGroup/FieldGroup';
import ApartmentAndZip from './ApartmentAndZip';

import { checkFieldNotEmpty } from '../../Inputs/functions/validate';

// @TODO will need to be able to set legend and placeholder text as props.
const StreetAddressAndZip = (
  {
    address,
    updateAddress,
    language,
    hasInvalidInput,
    inputComponentsMustValidate,
    streetAddressLegend,
    streetAddressPlaceholder,
    fieldIDSuffix
  },
) => {
  const leftField = (
    <TextField
      validate={checkFieldNotEmpty}
      className="TextInput"
      fieldName="street_address"
      defaultValue={streetAddressPlaceholder}
      legend={streetAddressLegend}
      fieldWidth="100%"
      inputComponentsMustValidate={inputComponentsMustValidate}
      hasInvalidInput={hasInvalidInput}
      propsCollection={address}
      updatePropsCollection={updateAddress}
      fieldIDSuffix={fieldIDSuffix}
    />
  );

  const rightField = (
    <ApartmentAndZip
      address={address}
      updateAddress={updateAddress}
      language={language}
      hasInvalidInput={hasInvalidInput}
      inputComponentsMustValidate={inputComponentsMustValidate}
      fieldIDSuffix={fieldIDSuffix}
    />
  );

  return (
    <FieldGroup
      language={language}
      leftField={leftField}
      rightField={rightField}
    />
  );
};

export default StreetAddressAndZip;

StreetAddressAndZip.propTypes = {
  address: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  updateAddress: PropTypes.func.isRequired,
  language: PropTypes.string.isRequired,
  hasInvalidInput: PropTypes.func.isRequired,
  inputComponentsMustValidate: PropTypes.bool.isRequired,
  streetAddressPlaceholder: PropTypes.string.isRequired,
  streetAddressLegend: PropTypes.string.isRequired,
  fieldIDSuffix: PropTypes.string.isRequired
};

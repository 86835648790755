import { createIntl } from 'react-intl';

import messagesEn from '../../../customizations/translations/en';
import messagesEsp from '../../../customizations/translations/esp';

const intl = (lang) => {
  const messages = {
    EN: messagesEn,
    ES: messagesEsp,
  };

  return createIntl({
    locale: lang,
    messages: messages[lang],
  });
};

// So here's the downside of doing it this way folks:
// We need to pass language down to every page and every component that needs to deal with text.
// I still think this beats having to deal with the overhead of intl regardless of how much of it
// we need, but time will tell.
/**
 *
 * @param {String} id                Key indicating message to return from messages files.
 * @param {String} lang              Requested language. For now that's EN or ES.
 * @param {Object} values            Used for adding html tags (for example) to the translation.
 * @param {String} defaultMessage    If provided, a message to show if not provided in message
 *                                   files. Otherwise will use id.
 *
 * @returns {String}  Message in requested language
 */
const getTranslatedMessageFromID = (
  id,
  lang,
  values = {},
  defaultMessage = null,
) => intl(lang).formatMessage(
  {
    id,
    default: (defaultMessage || id),
  },
  values,
);

export { getTranslatedMessageFromID }; // eslint-disable-line import/prefer-default-export

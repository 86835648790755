import { mapAddress } from '../mapStateDataToRequestBody';
import contactAmplifyEndpoint from '../../../../contactEndpoint/contactAmplifyEndpoint';
import { triggerAppWaitingMode, triggerGetUserData } from '../pageCycles/pageCycleManager';
import appendAptIfPresent from './supportingFunctions/appendAptIfPresent';

const myUtilityPromise = (
  {
    currentAddress,
    setDisplayPending,
    triggerAllInputComponentsValidPromise,
    alertAPIError, 
    language
  }
) => {
  let clonedCurrentAddress = { ...currentAddress };
  clonedCurrentAddress = appendAptIfPresent(clonedCurrentAddress, language); 

  const mappedAddress = mapAddress(clonedCurrentAddress);

  return new Promise((resolve, reject) => {
    if(mappedAddress.address_id < 0) {
      reject('No address in database');
    }

    triggerAllInputComponentsValidPromise()
      .then(() => {
        triggerAppWaitingMode(
          {
            setDisplayPending
          }
        );
        contactAmplifyEndpoint(
          '/user/addresses',
          'put',
          {
            body: [mappedAddress],
          },
          alertAPIError
        )
          .then((response) => {
            resolve(response);
          })
          .catch((err) => {
            reject(err);
          })
          .finally(() => {
            triggerGetUserData({
              setDisplayPending
            }); 
          });
      
      });
  });
};

export default myUtilityPromise;

import React from 'react';
import { useHistory } from 'react-router-dom';

import DetermineAllowedPages from '../../../utils/helpers/ApplicationProgressMonitor/DetermineAllowedPages';
import ApplicationProgressMonitor from '../../../utils/helpers/ApplicationProgressMonitor/ApplicationProgressMonitor';

const SaveReturn = () => {
  const history = useHistory();
  const pagesCompleteKnown = ApplicationProgressMonitor.getCurrentPagesComplete();
  const redirectPage = DetermineAllowedPages('/dashboard', pagesCompleteKnown);

  setTimeout(() => {
    history.push(redirectPage);
  }, 0);

  return (<div>Updating... give us a second...</div>);
};

export default SaveReturn;

import React from 'react';
import PropTypes from 'prop-types';

import DashboardNavDrawer from '../DashboardNavDrawer/DashboardNavDrawer';

import buildSubDrawers from './functions/buildSubDrawers';

const ProgressReviewDrawer = ({
  title,
  language,
  user,
  equipment,
  vehicle,
  currentAddress,
}) => (
  <DashboardNavDrawer
    title={title}
    items={buildSubDrawers(language, user, equipment, vehicle, currentAddress)}
    showCount={false}
  />
);

export default ProgressReviewDrawer;

ProgressReviewDrawer.propTypes = {
  language: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  user: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  equipment: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  vehicle: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  currentAddress: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
};

import React from 'react';
import PropTypes from 'prop-types';

const DesktopEditableDetail = ({
  legend,
  nodeToRender,
}) => (
  <div className="row EditableDetail">
    <div className="col-6 text-left">
      <div className="label">{legend}</div>
    </div>
    <div className="col-6 text-right">
      <div className="value">{nodeToRender}</div>
    </div>
  </div>
);

export default DesktopEditableDetail;

DesktopEditableDetail.propTypes = {
  legend: PropTypes.string.isRequired,
  nodeToRender: PropTypes.node.isRequired,
};

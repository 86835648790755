import React from 'react';
import PropTypes from 'prop-types';

import ApplicationStep from '../../Atoms/ApplicationStep/ApplicationStep';

import listApplicationSteps from './functions/listApplicationSteps';
import findCurrentStepNumber from './functions/findCurrentStepNumber';

const ApplicationStepsNavMobile = (
  {
    currentPage,
    language,
  },
) => {
  const pages = listApplicationSteps(language);

  const stepNumber = findCurrentStepNumber(pages, currentPage);

  if (stepNumber < 1) {
    return null;
  }

  return (
    <div className="application-steps-nav-wrapper">
      <div className="ApplicationStepsNav row text-center">
        {
          pages.map((page) => (
            <div
              className="col-3 app-step-tab"
              key={page.titleText}
            >
              <ApplicationStep
                targetPage={page.targetPage}
                titleText={page.titleText}
                currentPage={currentPage}
              />
            </div>
          ))
        }
      </div>
      <div
        className="row StepNumberHeader"
      >
        <div className="col-12 text-center">
          {`Step ${stepNumber}`}
        </div>
      </div>
    </div>
  );
};

export default ApplicationStepsNavMobile;

ApplicationStepsNavMobile.propTypes = {
  currentPage: PropTypes.string.isRequired,
  language: PropTypes.string.isRequired,
};

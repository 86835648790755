import React from "react";
import "./Footer.scss";
import PropTypes from 'prop-types';
import facebookIcon from './facebookIcon.svg';
import twitterIcon from './twitterIcon.svg';
import instagramIcon from './instagramIcon.svg';

const FooterFromEVShopper = () => {

  return (
    <div className="Footer">
      <div className="container-fluid" >

        <div className="copyright-notice row">
          <div className="col-md-3">
            <p style={{ display: "flex", justifyContent: "center", margin: "0px 0px 10px 0px", color: "rgb(255, 255, 255)", fontSize: "11px" }}>
              <span style={{ fontFamily: "Arial, Helvetica" }}>©</span> 2021 | Veloz. All Rights Reserved.</p>
          </div>
          <div className="col-md-6 footer-links" style={{ color: "white" }}>
            <p style={{ margin: "0px auto 10px auto", width: "305px", color: "rgb(255, 255, 255)", fontSize: "11px" }}>
              <a className="col-3" style={{ color: "white" }} title="Veloz" href="https://www.electricforall.org/contact-efa/" target="_blank" rel="noopener noreferrer">Contact</a>
                                    |<a className="col-3" style={{ color: "white" }} href="https://www.electricforall.org/privacy-policy/" target="_blank" rel="noopener noreferrer">Privacy Policy</a>|
              <a className="col-3" style={{ color: "white", margin: "5px 0px" }} href="https://www.electricforall.org/terms-conditions/" target="_blank" rel="noopener noreferrer">Terms &amp; Conditions</a>
            </p></div><div className="col-md-3 social-links"><div style={{ display: "flex", color: "rgb(255, 255, 255)", fontSize: "11px", justifyContent: "center" }}>
            <div className="social-icons mb-2" style={{ display: "flex", alignItems: "flex-end", justifyContent: "space-between", width: "75px" }}> <a href="https://www.facebook.com/ElectricForAll/" target="_blank" rel="noopener noreferrer">
              <img className="social-media-icon" style={{ color: "white", height: "20px" }} alt="Facebook Icon" src={facebookIcon} />
            </a>
            <a href="https://twitter.com/ElectricForAll" target="_blank" rel="noopener noreferrer" >
              <img className="social-media-icon" style={{ color: "white", height: "20px" }} alt="Twitter Icon" src={twitterIcon} />
            </a>
            <a href="https://www.instagram.com/electricforall/" target="_blank" rel="noopener noreferrer">
              <img className="social-media-icon" style={{ color: "white", height: "20px", }} alt="Instagram Icon" src={instagramIcon} /></a>
            </div>
          </div>
          </div>
        </div>

      </div>
    </div>
  );
};

export default FooterFromEVShopper;


FooterFromEVShopper.propTypes = {
  language: PropTypes.string,
  changeLanguage: PropTypes.func
};

import { getUtility } from '../../../Pages/MyUtility/functions/utilitiesFromAddress';
import { VehicleDetails } from '../../../Pages/MyElectricVehicle/data/detailedInfo';

const noOp = () => {};

const userProfile = (user) => [
  {
    fieldName: 'first_name',
    fieldLegendID: 'firstName',
    propsCollection: user,
    update: noOp,
  },
  {
    fieldName: 'last_name',
    fieldLegendID: 'lastName',
    propsCollection: user,
    update: noOp,
  },
  {
    fieldName: 'home_phone',
    fieldLegendID: 'homePhoneShort',
    propsCollection: user,
    update: noOp,
  },
  {
    fieldName: 'mobile_phone',
    fieldLegendID: 'mobilePhoneShort',
    propsCollection: user,
    update: noOp,
  },
  {
    fieldName: 'number_household_members',
    fieldLegendID: 'userProfile.householdSizeShort',
    propsCollection: user,
    update: noOp,
  },
  {
    fieldName: 'household_income',
    fieldLegendID: 'householdIncomeShort',
    propsCollection: user,
    update: noOp,
  },
];

const chargerLocation = (currentAddress) => [
  {
    fieldName: 'street_address',
    fieldLegendID: 'streetAddress',
    propsCollection: currentAddress,
    update: noOp,
  },
  {
    fieldName: 'apt_number',
    fieldLegendID: 'apartment',
    propsCollection: currentAddress,
    update: noOp,
  },
  {
    fieldName: 'postcode',
    fieldLegendID: 'zipCode',
    propsCollection: currentAddress,
    update: noOp,
  },
];

// @TODO this will also need to include photos
const chargerData = (equipment) => [
  {
    fieldName: 'make',
    fieldLegendID: 'carMake',
    propsCollection: equipment,
    update: noOp,
  },
  {
    fieldName: 'model',
    fieldLegendID: 'carModel',
    propsCollection: equipment,
    update: noOp,
  },
  {
    fieldName: 'serial_number',
    fieldLegendID: 'serialNumber',
    propsCollection: equipment,
    update: noOp,
  },
  {
    fieldName: 'purchase_date',
    fieldLegendID: 'purchaseDate',
    propsCollection: equipment,
    specialDataType: 'date',
    update: noOp,
  },
  {
    fieldName: 'purchase_price',
    fieldLegendID: 'purchasePrice',
    propsCollection: equipment,
    update: noOp,
  },
];

const utilityData = (currentAddress) => {
  const propsCollection = getUtility(currentAddress);

  return [
    {
      fieldName: 'account_number',
      fieldLegendID: 'myUtility.accountIDShort',
      propsCollection,
      update: noOp,
    },
    {
      fieldName: 'name',
      fieldLegendID: 'myUtility.providerNameShort',
      propsCollection,
      update: noOp,
    },
  ];
};

const evData = (vehicle) => VehicleDetails(vehicle, noOp);

export { userProfile, chargerLocation, chargerData, utilityData, evData };

import React from 'react';
import PropTypes from 'prop-types';

import DashboardProgressNavigator from '../../DashboardProgressNavigator/DashboardProgressNavigator';

const ContentContainingChildNodeDesktop = (
  {
    children,
    setCurrentIncentiveByID,
    language,
    incentiveDrawerOpened
  },
) => (
  <div className="row">
    <div className="col-sm-12 col-md-7 col-lg-8">
      {children}
    </div>
    <div className="col-sm-12 col-md-5 col-lg-4 sidebar">
      <DashboardProgressNavigator
        setCurrentIncentiveByID={setCurrentIncentiveByID}
        language={language}
        incentiveDrawerOpened={incentiveDrawerOpened}
      />
    </div>

  </div>
);

export default ContentContainingChildNodeDesktop;

ContentContainingChildNodeDesktop.defaultProps = {
  incentiveDrawerOpened: false
};

ContentContainingChildNodeDesktop.propTypes = {
  children: PropTypes.node.isRequired,
  language: PropTypes.string.isRequired,
  setCurrentIncentiveByID: PropTypes.func.isRequired,
  incentiveDrawerOpened: PropTypes.bool
};

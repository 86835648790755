/**
 * This is the landing page for the link in the confirmation email.
 */

import React, { useContext } from 'react';
import PropTypes from 'prop-types';

import ZappyWave from './subcomponents/ZappyWave';
import WelcomeHeader from './subcomponents/WelcomeHeader';
import Paragraphs from './subcomponents/Paragraphs';
import ContinueButton from './subcomponents/ContinueButton';
import PlaceHolder from '../../../Atoms/PlaceHolder/PlaceHolder';

import { UserContext } from '../../../IaaUserEntity/IaaUserEntity';

import './css/RegistrationSuccessful.scss';

const RegistrationSuccessfulMainContent = (
  {
    language,
  },
) => { 

  const contextArr = useContext(UserContext);
  const iaaUser = contextArr[0] || contextArr;

  const user = iaaUser.UserObject.profile;

  return(
    <div
      className="RegistrationSuccessful"
    >
      <ZappyWave />
      <WelcomeHeader
        user={user}
        language={language}
      />
      <Paragraphs
        user={user}
        language={language}
      />
      <ContinueButton
        language={language}
      />
      <PlaceHolder />
    </div>
  );};

export default RegistrationSuccessfulMainContent;

RegistrationSuccessfulMainContent.propTypes = {
  // user: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  language: PropTypes.string.isRequired,
};

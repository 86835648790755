const filterUnfilledImages = (images) => {
  const filledImages = {};

  const keys = Object.keys(images);

  keys.forEach((key) => {
    if(images[key].url && images[key].asset_id) {
      filledImages[key] = images[key];
    } 
  });

  return filledImages;
};

export default filterUnfilledImages;

import React from 'react';
import PropTypes from 'prop-types';

const FieldGroupDesktop = ({
  leftField = <></>,
  rightField = <></>,
}) => (
  <>
    <div className="row">
      <div className="col-6 left-field">
        {leftField}
      </div>
      <div className="col-6 right-field">
        {rightField}
      </div>
    </div>
  </>
);

export default FieldGroupDesktop;

FieldGroupDesktop.defaultProps = {
  leftField: <></>,
  rightField: <></>,
};

FieldGroupDesktop.propTypes = {
  leftField: PropTypes.node,
  rightField: PropTypes.node,
};

import { getTranslatedMessageFromID } from "../../../../../../utils/functions/intl/intlWrapper";

const appendAptIfPresent = (addr, language) => {
  if(addr.apt_number) {
    const aptAbbrev = getTranslatedMessageFromID('chargerLocation.chargerAptAbbrev', language);
    addr.street_address = `${addr.street_address}${aptAbbrev}${addr.apt_number}`;
    delete addr.apt_number;
  }
  
  return addr;
};

export default appendAptIfPresent;

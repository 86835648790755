import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import ApplicationAssistantLayout from '../../ApplicationAssistantLayout/ApplicationAssistantLayout';
import CompleteApplicationMainContent from './MainContent/CompleteApplicationMainContent';

import '../../Atoms/Inputs/DateInput/css/DateInput.scss';

import { UserContext } from '../../IaaUserEntity/IaaUserEntity';
import filterByField from '../../../utils/functions/misc/filterByField';
import logger from '../../../utils/functions/logging/logger';
import LocalStorage from '../../../utils/functions/localStorage/LocalStorage';
import localStorageKeyring from '../../../constants/LOCAL_STORAGE_KEYS';
import checkIncentiveFieldsComplete from '../../../apiInterface/requestBuilders/post/functions/promises/supportingFunctions/checkIncentivesFieldsComplete';
import { STATUS_STARTED, STATUS_READY_SUBMIT } from '../../../constants/STRING_CONSTANTS';

const CompleteApplication = (
  {
    setCurrentIncentiveByID,
    incentive,
    language,
    currentPage,
    setDisplayPending,
    handlePageForwardClick,
    hasInvalidInput,
    inputComponentsMustValidate,
    fetchUserData
  },
) => { 

  const contextArr = useContext(UserContext);
  const iaaUser = contextArr[0] || contextArr;
  const userObject = iaaUser.UserObject;
  const dispatch = contextArr[1];
  
  const user = userObject.profile;
  const equipment = userObject.charger;
  const vehicle = userObject.vehicle;
  const currentAddress = userObject.chargerAddress;
  const allUserIncentives = userObject.incentives;
  const fractionGeneralAppComplete = LocalStorage.getLocalDatapoint(localStorageKeyring.fractionFieldsFinished);
  const [saveOrSubmit, setSaveOrSubmit] = useState('save');

  // At first it is the currentIncentive passed in; later becomes the incentive we 
  // are updating from the incentives array
  const [workingIncentive, setWorkingIncentive] = useState(incentive);

  const updateIncentive = (updatedIncentive) => {


    // Find the incentive in the full set of user incentives and update it.
    const targetIncentiveID = incentive.incentive_id;
    const newUserIncentives = [ ...allUserIncentives ];
    const targetIncentive = filterByField('incentive_id', targetIncentiveID, newUserIncentives)[0];
    const photoFields = filterByField('type', 'PHOTO', incentive.input_fields, true);

    setWorkingIncentive(targetIncentive);

    targetIncentive.input_fields = updatedIncentive.input_fields;

    photoFields.forEach((photoField) => {
      const matchingPhotosByName = filterByField('name', photoField.name, targetIncentive.input_fields);
      if(matchingPhotosByName.length === 0) {
        targetIncentive.input_fields.push(photoField);
      }

    });    

    dispatch({ 
      type: 'SET_INCENTIVES',
      payload: newUserIncentives
    });
    
    logger(iaaUser, 'CompleteApplication');

  };

  useEffect(() => {

    const inputFields = workingIncentive.input_fields;

    if(!inputFields) {
      return;
    }

    // @TODO what if we somehow get here when the incentive is submitted?
    const generalAppComplete = (fractionGeneralAppComplete === 1);
    const customFieldsComplete = checkIncentiveFieldsComplete(inputFields);

    if(generalAppComplete && customFieldsComplete) {
      setSaveOrSubmit('submit');

      workingIncentive.status = STATUS_READY_SUBMIT;
    } else {
      setSaveOrSubmit('save');

      workingIncentive.status = STATUS_STARTED;
    }
  });

  const prepareIncentiveImages = () => {
    if(!incentive.input_fields) {
      return;
    }

    if(!incentive.images) {
      incentive.images = {};
  
      incentive.input_fields.forEach((field) => {
        if(field.type.toLowerCase() === 'photo') {
          incentive.images[field.name] = field;
        }
      });
    }
  };

  prepareIncentiveImages();
 
  return (
    <ApplicationAssistantLayout
      user={user}
      language={language}
      equipment={equipment}
      vehicle={vehicle}
      currentAddress={currentAddress}
      currentPage={currentPage}
      previousPage="/dashboard"
      nextPage={
        saveOrSubmit === 'save'
          ? '/save-return'
          : '/submit-return'
      }
      previousPageButtonText="Back"
      nextPageButtonText="Submit"
      sendToAPIOnBackButtonClick={true}
      setCurrentIncentiveByID={setCurrentIncentiveByID}
      handlePageForwardClick={handlePageForwardClick}
    >
      <CompleteApplicationMainContent
        incentive={incentive}
        updateIncentive={updateIncentive}
        language={language}
        setDisplayPending={setDisplayPending}
        hasInvalidInput={hasInvalidInput}
        inputComponentsMustValidate={inputComponentsMustValidate}
        fetchUserData={fetchUserData}
      />
    </ApplicationAssistantLayout>
  );
};

export default CompleteApplication;

CompleteApplication.propTypes = {
  setCurrentIncentiveByID: PropTypes.func.isRequired,
  language: PropTypes.string.isRequired,
  currentPage: PropTypes.string.isRequired,
  incentive: PropTypes.object.isRequired,
  handlePageForwardClick: PropTypes.func.isRequired,
  hasInvalidInput: PropTypes.func.isRequired,
  inputComponentsMustValidate: PropTypes.bool.isRequired,
  setDisplayPending: PropTypes.func.isRequired,
  fetchUserData: PropTypes.func.isRequired
};

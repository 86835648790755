import React from 'react';
import PropTypes from 'prop-types';

import { getTranslatedMessageFromID } from '../../../../../utils/functions/intl/intlWrapper';

const Paragraphs = (
  {
    language,
    user
  },
) => {

  const p1 = user.first_name 
    ? getTranslatedMessageFromID(
      'register.partiallyCompleted.accountCreated', 
      language
    )
    : getTranslatedMessageFromID(
      'register.registerSuccess.accountCreated', 
      language
    );             
    
  const p2 = user.first_name 
    ? getTranslatedMessageFromID(
      'register.partiallyCompleted.nextUp', 
      language
    )
    : getTranslatedMessageFromID(
      'register.registerSuccess.nextUp', 
      language
    );             
    

  return (
    <section className="container">
      <div className="row">
        <div className="col-12 text-center">
          <p>{p1}</p>
          <p>{p2}</p>
        </div>
      </div>
    </section>
  );
};

export default Paragraphs;

Paragraphs.propTypes = {
  language: PropTypes.string.isRequired,
  user: PropTypes.object.isRequired
};

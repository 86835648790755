import { getTranslatedMessageFromID } from "../../../utils/functions/intl/intlWrapper";

// @TODO will need to call this again if language changes
const checkApt = (addr, language) => {
  if(!addr.street_address) {
    return addr;
  }
  
  const aptAbbrev = getTranslatedMessageFromID('chargerLocation.chargerAptAbbrev', language);
  const addrAndApt = addr.street_address.split(aptAbbrev);
  
  if(addrAndApt.length > 1) {
    addr.street_address = addrAndApt[0];
    addr.apt_number = addrAndApt[1];
  }
  
  return addr;
};

export default checkApt;

import React, { useState } from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router } from "react-router-dom";
import { HelmetProvider } from "react-helmet-async";
import { IntlProvider } from "react-intl";
import Honeybadger from '@honeybadger-io/js';
import ErrorBoundary from "@honeybadger-io/react";
import LocalStorage from "./utils/functions/localStorage/LocalStorage";
import localStorageKeyring from "./constants/LOCAL_STORAGE_KEYS";

import messagesEn from "./customizations/translations/en";
import messagesEsp from "./customizations/translations/esp";

import Root from "./Root";
import ErrorPage from './components/Pages/ErrorPage/ErrorPage';

// NOTE: uncomment reportData: true to test in development
const honeybadgerConfig = Honeybadger.configure({
  apiKey: "3b7abe99",
  environment: process.env.NODE_ENV,
  // reportData: true
});

// @TODO wrap in IntlProvider. See index.js from Generic_EV_Shopper
const App = () => {
  const [language, setLanguage] = useState("EN");

  const messages = {
    EN: messagesEn,
    ES: messagesEsp,
  };

  const checkLocalStorage = () => {
    const currentIAVersion = process.env.REACT_APP_IA_VERSION;
    if (
      LocalStorage.getLocalDatapoint("iaVersion") < currentIAVersion ||
      LocalStorage.getLocalDatapoint("iaVersion") === undefined
    ) {
      for (const keyRing in localStorageKeyring) {
        LocalStorage.deleteLocalDatapoint(localStorageKeyring[keyRing]);
      }
      LocalStorage.setLocalDatapoint("iaVersion", currentIAVersion);
    }
  };

  return (
    <ErrorBoundary honeybadger={honeybadgerConfig} ErrorComponent={ErrorPage}>
      <HelmetProvider>
        <IntlProvider locale={language} messages={messages[language]}>
          {checkLocalStorage()}
          <Router>
            <Root 
              language={language} 
              changeLanguage={setLanguage} 
              honeybadger={honeybadgerConfig}
            />
          </Router>
        </IntlProvider>
      </HelmetProvider>
    </ErrorBoundary>
  );
};

ReactDOM.render(<App />, document.getElementById("root"));

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Modal, ModalHeader, ModalBody } from "reactstrap";

import TabletProfileModalBody from './TabletProfileModalBody';

const TabletProfileModalLauncher = ({
  equipment,
  user,
  vehicle,
  currentAddress,
  language,
}) => {

  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="TabletModalLauncher">
      <button
        onClick={() => { toggle();}}
        type="button"
        className="launch-button"
      >Profile Details</button>
      <Modal
        isOpen={isOpen}
        toggle={toggle}
      >
        <ModalHeader toggle={toggle}>Profile Details</ModalHeader>
        <ModalBody
          className="tablet-profile-modal-body"
        >
          <TabletProfileModalBody
            equipment={equipment}
            user={user}
            vehicle={vehicle}
            currentAddress={currentAddress}
            language={language}
          />
        </ModalBody>
      </Modal>
    </div>
  );
};

export default TabletProfileModalLauncher;

TabletProfileModalLauncher.propTypes = {
  language: PropTypes.string.isRequired,
  user: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  equipment: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  vehicle: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  currentAddress: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
};

import React from 'react';
import PropTypes from 'prop-types';
import ProgressBar from '@ramonak/react-progress-bar';

const IAAProgressBar = (
  {
    barColor,
    barBGColor,
    fractionComplete,
    height,
    width,
    isLabelVisible,
  },
) => (
  <ProgressBar
    completed={fractionComplete * 100}
    bgcolor={barColor}
    baseBgColor={barBGColor}
    height={height}
    width={width}
    isLabelVisible={isLabelVisible}
  />
);

export default IAAProgressBar;

IAAProgressBar.defaultProps = {
  isLabelVisible: false,
};

IAAProgressBar.propTypes = {
  barColor: PropTypes.string.isRequired,
  barBGColor: PropTypes.string.isRequired,
  fractionComplete: PropTypes.number.isRequired,
  height: PropTypes.string.isRequired,
  width: PropTypes.string.isRequired,
  isLabelVisible: PropTypes.bool,
};

import React from 'react';
import ChecklistUL from '../../Atoms/UL/ChecklistUL/ChecklistUL';

import '../../Atoms/UL/ChecklistUL/css/ChecklistUL.scss';

const ExampleChecklistUL = () => (
  <ChecklistUL
    listItems={[
      'homepage.registrationPhoto',
      'homepage.chargerInvoicePhoto',
      'homepage.installedChargerPhoto',
      'homepage.utilityBillPhoto',
    ]}
  />
);

export default ExampleChecklistUL;

import React from 'react';
import PropTypes from 'prop-types';

import TextField from './TextField';

import { validatePhoneNumber } from '../functions/validate';
import { FormatUSPhoneNumber } from '../../../../utils/helpers/Format';


const PhoneNumberTextField = (
  {
    fieldName,
    fieldWidth,
    propsCollection,
    updatePropsCollection,
    defaultValue,
    legend,
    className,
    inputComponentsMustValidate,
    hasInvalidInput,
    validate,
  },
) => {
  const validateInput = validate || validatePhoneNumber;

  const additionalOnChange = (rawPhoneNumber) => {

    const strippedPhoneNumber = rawPhoneNumber.replace(/\D/g,'');

    const formattedPhone = strippedPhoneNumber.length > 3 
      ? FormatUSPhoneNumber(rawPhoneNumber)
      : strippedPhoneNumber;

    return formattedPhone;
  };


  return (
    <TextField
      validate={validateInput}
      className={className}
      fieldName={fieldName}
      defaultValue={defaultValue}
      legend={legend}
      fieldWidth={fieldWidth}
      inputComponentsMustValidate={inputComponentsMustValidate}
      hasInvalidInput={hasInvalidInput}
      propsCollection={propsCollection}
      updatePropsCollection={updatePropsCollection}
      additionalOnChange={additionalOnChange}
    />
  );
};

export default PhoneNumberTextField;

PhoneNumberTextField.defaultProps = {
  className: '',
  defaultValue: '',
  legend: '',
  fieldWidth: 'auto',
  validate: null,
};

PhoneNumberTextField.propTypes = {
  className: PropTypes.string,
  fieldName: PropTypes.string.isRequired,
  defaultValue: PropTypes.string,
  legend: PropTypes.string,
  fieldWidth: PropTypes.string,
  inputComponentsMustValidate: PropTypes.bool.isRequired,
  hasInvalidInput: PropTypes.func.isRequired,
  propsCollection: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  updatePropsCollection: PropTypes.func.isRequired,
  validate: PropTypes.func,
};

import React, { useEffect, useContext } from 'react';
import PropTypes from 'prop-types';

import ContentContainingChildNodeMobile from './ContentContainingChildNode/Mobile/ContentContainingChildNodeMobile';
import ContentContainingChildNodeDesktop from './ContentContainingChildNode/Desktop/ContentContainingChildNodeDesktop';
import SectionWrapper from '../Atoms/SectionWrapper/SectionWrapper';
import ApplicationStepsNavDesktop from '../Nav/ApplicationStepsNav/ApplicationStepsNavDesktop';
import ApplicationStepsNavMobile from '../Nav/ApplicationStepsNav/ApplicationStepsNavMobile';

import renderBackNextButtons from './function/renderBackNextButtons';
import { renderingInTablet } from '../../utils/functions/device/determinePlatform';

import { updateCurrentPage } from '../../apiInterface/responseHandlers/loadToGlobalState';
import { UserContext } from '../IaaUserEntity/IaaUserEntity';

import './css/ApplicationAssistantLayout.scss';
import '../Atoms/AppStepHeader/css/AppStepHeader.scss';

const ApplicationAssistantLayout = ({
  language,
  children,
  setCurrentIncentiveByID,
  currentPage = '',
  previousPage = null,
  nextPage = null,
  previousPageButtonText,
  nextPageButtonText,
  handlePageForwardClick,
  sendToAPIOnBackButtonClick,
  className = '',
  incentiveDrawerOpened
}) => {

  const [, dispatch ] = useContext(UserContext);

  const navStepsDesktop = (
    <ApplicationStepsNavDesktop
      currentPage={currentPage}
      language={language}
    />
  );

  const navStepsMobile = (
    <ApplicationStepsNavMobile
      currentPage={currentPage}
      language={language}
    />
  );

  const tabletPadding = renderingInTablet()
    ? (
      <div className="tablet-padding" />
    )
    : null;

  const MobileWrapper = (
    <ContentContainingChildNodeMobile
      language={language}
    >
      { tabletPadding }
      <div className={`IAAContent ${className}`}>
        {navStepsMobile}
        {children}
        { renderBackNextButtons(
          previousPage,
          nextPage,
          previousPageButtonText,
          nextPageButtonText,
          handlePageForwardClick,
          sendToAPIOnBackButtonClick
        ) }
      </div>
    </ContentContainingChildNodeMobile>
  );

  const DesktopWrapper = (
    <ContentContainingChildNodeDesktop
      setCurrentIncentiveByID={setCurrentIncentiveByID}
      language={language}
      incentiveDrawerOpened={incentiveDrawerOpened}
    >
      { navStepsDesktop }
      <section className={`IAAContent ${className}`}>
        {children}
      </section>
      { renderBackNextButtons(
        previousPage,
        nextPage,
        previousPageButtonText,
        nextPageButtonText,
        handlePageForwardClick,
        sendToAPIOnBackButtonClick
      ) }
    </ContentContainingChildNodeDesktop>
  );

  useEffect(() => {
    updateCurrentPage(dispatch)(currentPage);   
  }, 
  [currentPage]
  );

  return (
    <SectionWrapper
      language={language}
      mobileSection={MobileWrapper}
      desktopSection={DesktopWrapper}
      sectionClassName="appAssistLayout"
    />
  );
};

export default ApplicationAssistantLayout;

ApplicationAssistantLayout.defaultProps = {
  previousPageButtonText: 'Back',
  nextPageButtonText: 'Next',
  currentPage: '',
  previousPage: '',
  nextPage: '',
  className: '',
  inputDrawerOpened: false,
  handlePageForwardClick: () => {
    return new Promise((resolve) => {
      resolve();
    });
  },
  sendToAPIOnBackButtonClick: false
};

ApplicationAssistantLayout.propTypes = {
  language: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  currentPage: PropTypes.string,
  previousPage: PropTypes.string,
  nextPage: PropTypes.string,
  previousPageButtonText: PropTypes.string,
  nextPageButtonText: PropTypes.string,
  className: PropTypes.string,
  setCurrentIncentiveByID: PropTypes.func.isRequired,
  handlePageForwardClick: PropTypes.func,
  incentiveDrawerOpened: PropTypes.bool,
  sendToAPIOnBackButtonClick: PropTypes.bool
};

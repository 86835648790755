import React from 'react';
import PropTypes from 'prop-types';

import IncentiveCard from '../../Atoms/Cards/IncentiveCard/IncentiveCard';

import equipmentData from '../data/equipmentData';

import '../../Atoms/Cards/css/Card.scss';

const ExampleIncentiveCard = (
  {
    language,
    incentiveToUse,
    setCurrentIncentiveByID,
  },
) => {
  const incentiveData = equipmentData.equipment.incentives[incentiveToUse];

  return (
    <IncentiveCard
      incentiveData={incentiveData}
      language={language}
      setCurrentIncentiveByID={setCurrentIncentiveByID}
    />
  );
};

export default ExampleIncentiveCard;

ExampleIncentiveCard.propTypes = {
  language: PropTypes.string.isRequired,
  incentiveToUse: PropTypes.number.isRequired,
  setCurrentIncentiveByID: PropTypes.func.isRequired,
};

const NAV_PAGE_DESCRIPTORS = [
  {
    page: 'Homepage',
    path: '/',
    title: 'welcome',
    seoTitleKey: 'seoPageTitle.homepage',
  },
  {
    page: 'Dashboard',
    path: '/dashboard',
    title: 'almostThere',
    seoTitleKey: 'seoPageTitle.dashboard',
  },
  {
    page: 'RegistrationSuccessful',
    path: '/welcome',
    title: 'welcome',
    seoTitleKey: 'seoPageTitle.welcome',
  },
  {
    page: 'UserProfile',
    path: '/user-profile',
    title: 'myProfile',
    seoTitleKey: 'seoPageTitle.profile',
  },
  {
    page: 'UpdateAccount',
    path: '/update-account',
    title: 'updateAccount',
    seoTitleKey: 'seoPageTitle.updateAccount',
  },
  {
    page: 'ChargerLocation',
    path: '/charger-location',
    title: 'chargerLocation',
    seoTitleKey: 'seoPageTitle.chargerLocation',
  },
  {
    page: 'MyCharger',
    path: '/my-charger',
    title: 'myCharger',
    seoTitleKey: 'seoPageTitle.myCharger',
  },
  {
    page: 'MyUtility',
    path: '/my-utility',
    title: 'myUtility',
    seoTitleKey: 'seoPageTitle.myUtility',
  },
  {
    page: 'MyElectricVehicle',
    path: '/my-ev',
    title: 'myElectricVehicle',
    seoTitleKey: 'seoPageTitle.myElectricVehicle',
  },
  {
    page: 'Dashboard',
    path: '/my-dashboard',
    title: 'My Dashboard',
    seoTitleKey: 'seoPageTitle.myDashboard',
  },
  {
    page: 'ContactUs',
    path: '/contact-us',
    title: 'contactUs',
    seoTitleKey: 'seoPageTitle.contactUs',
  },
  {
    page: 'ComponentCatalog',
    path: '/component-zoo',
    title: 'componentZoo',
    seoTitleKey: 'seoPageTitle.componentCatalog',
  },
  {
    page: 'Login',
    path: '/login',
    title: 'login',
    seoTitleKey: 'seoPageTitle.login',
  },
  {
    page: 'RegistrationSuccessful',
    path: '/signout',
    title: 'registration',
    seoTitleKey: 'seoPageTitle.registration',
  },
  {
    page: 'CompleteApplication',
    path: '/complete-application',
    title: 'completeApplication',
    seoTitleKey: 'seoPageTitle.completeApplication',
  },
  {
    page: 'SubmitApplication',
    path: '/submit-application',
    title: 'submitApplication',
    seoTitleKey: 'seoPageTitle.submitApplication',
  },
  {
    page: 'Homepage',
    path: '/three-steps',
    title: 'welcome',
    seoTitleKey: 'seoPageTitle.threeSteps',
  },
  {
    page: 'NoIncentivesAvailable',
    path: '/no-incentives-available',
    title: 'noIncentivesAvailable',
    seoTitleKey: 'seoPageTitle.noIncentivesAvailable',
  },
  {
    page: 'SaveReturn',
    path: '/save-return',
    title: 'saveAndReturn',
    seoTitleKey: 'seoPageTitle.saveAndReturn'
  },
  {
    page: 'SubmitReturn',
    path: '/submit-return',
    title: 'submitAndReturn',
    seoTitleKey: 'seoPageTitle.submitAndReturn'
  }
];

export default NAV_PAGE_DESCRIPTORS;

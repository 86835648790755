import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';

import ApplicationAssistantLayout from '../../ApplicationAssistantLayout/ApplicationAssistantLayout';
import ChargerLocationMainContent from './MainContent/ChargerLocationMainContent';

import './css/ChargerLocation.scss';

const ChargerLocation = (
  {
    language,
    currentPage,
    setCurrentIncentiveByID,
    hasInvalidInput,
    inputComponentsMustValidate,
    setInputComponentsMustValidate,
    handlePageForwardClick,
  },
) => {
  const intl = useIntl();
  return (
    <ApplicationAssistantLayout
      language={language}
      currentPage={currentPage}
      setCurrentIncentiveByID={setCurrentIncentiveByID}
      previousPage="/user-profile"
      nextPage="/my-charger"
      previousPageButtonText={intl.formatMessage({ id: 'back' })}
      nextPageButtonText={intl.formatMessage({ id: 'saveAndNext' })}
      handlePageForwardClick={handlePageForwardClick}
    >
      <ChargerLocationMainContent
        language={language}
        hasInvalidInput={hasInvalidInput}
        inputComponentsMustValidate={inputComponentsMustValidate}
        setInputComponentsMustValidate={setInputComponentsMustValidate}
      />
    </ApplicationAssistantLayout>
  );
};

export default ChargerLocation;

ChargerLocation.propTypes = {
  language: PropTypes.string.isRequired,
  setCurrentIncentiveByID: PropTypes.func.isRequired,
  currentPage: PropTypes.string.isRequired,
  hasInvalidInput: PropTypes.func.isRequired,
  inputComponentsMustValidate: PropTypes.bool.isRequired,
  setInputComponentsMustValidate: PropTypes.func.isRequired,
  handlePageForwardClick: PropTypes.func.isRequired,
};

import React from 'react';
import PropTypes from 'prop-types';

import DashboardNavDrawer from '../DashboardNavDrawer/DashboardNavDrawer';

import incentiveCatalogBuilder from '../functions/incentiveCatalogBuilder';

// @TODO is this class actually necessary?

const IncentiveDrawer = ({
  title,
  incentives,
  language,
  postcodeSet,
  setCurrentIncentiveByID,
  incentiveDrawerOpened
}) => (
  <DashboardNavDrawer
    title={title}
    items={incentiveCatalogBuilder(incentives, language, setCurrentIncentiveByID)}
    incentiveDrawerOpened={true}
  />
);

export default IncentiveDrawer;

IncentiveDrawer.defaultProps = {
  incentiveDrawerOpened: false,
  incentives: null
};

IncentiveDrawer.propTypes = {
  language: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  incentives: PropTypes.arrayOf(PropTypes.object),
  setCurrentIncentiveByID: PropTypes.func.isRequired,
  incentiveDrawerOpened: PropTypes.bool,
  postcodeSet: PropTypes.bool
};

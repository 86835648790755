import React, { useContext, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

import AppStepHeader from '../../../Atoms/AppStepHeader/AppStepHeader';
import PhotoProcessor from '../../../Atoms/Inputs/PhotoProcessor/PhotoProcessor';
import EditableDetails from '../../../Atoms/Inputs/EditableDetails/EditableDetails';
import contactEndpoint from '../../../../apiInterface/contactEndpoint/contactUnifiedEndpoint';
import { VehicleDetails } from '../data/detailedInfo';
import { getTranslatedMessageFromID } from '../../../../utils/functions/intl/intlWrapper';
import { UserContext } from '../../../IaaUserEntity/IaaUserEntity';
import { EquipmentContext } from '../../../Equipment/Equipment';
import {
  updateVehicleFunction,
  resetVehicleModelYear,
} from '../../../../apiInterface/responseHandlers/loadToGlobalState';
import myEquipmentPromise from '../../../../apiInterface/requestBuilders/post/functions/promises/myEquipmentPromise';

const MyElectricVehicleMainContent = ({
  language,
  inputComponentsMustValidate,
  triggerAllInputComponentsValidPromise,
  setDisplayPending,
  hasInvalidInput,
  fetchUserData,
  alertAPIError,
}) => {
  // For some reason useContext does not return an array if in Jest.
  // Actually I think useContext behaves differently between browser and jest.
  // @TODO Mock useContext?
  const userContextArr = useContext(UserContext);
  const [iaaUser, userDispatch] = userContextArr;
  const equipmentContextArr = useContext(EquipmentContext);
  const [equipment, equipmentDispatch] = equipmentContextArr;
  const userZip = iaaUser.UserObject.primaryAddress.postcode;
  const currentAddress = iaaUser.UserObject.chargerAddress;
  const usersVehicle = iaaUser.UserObject.vehicle;
  const isMounted = useRef(false);

  const updateVehicle = (newValue, fieldName) => {
    const newVehicle = { ...usersVehicle };
    newVehicle[fieldName] = newValue;
    updateVehicleFunction(userDispatch)(newVehicle);
  };
  useEffect(() => {
    const fetchVehicles = async () => {
      setDisplayPending(true);
      try {
        const vehicles = await contactEndpoint(
          'unified',
          '/unsafe/vehicles',
          'GET',
          {
            postcode: userZip,
            include_used_vehicles: true,
          },
          null,
          alertAPIError
        );
        equipmentDispatch({
          type: 'SET_VEHICLES',
          payload: vehicles.vehicle,
        });
      } catch (err) {
        alertAPIError(err);
      } finally {
        setDisplayPending(false);
      }
    };
    if (userZip && !equipment.Vehicles.length > 0) {
      fetchVehicles();
    }
  }, [userZip]);

  useEffect(() => {
    isMounted.current ? resetVehicleModelYear(userDispatch)('') : (isMounted.current = true);

    if (!usersVehicle) {
      resetVehicleModelYear(userDispatch)('');
    }
  }, [usersVehicle.make]);

  // We have to have a vehicle id before the photo can be sent.
  const beforeSendingPhoto = () => {
    return new Promise((resolve, reject) => {
      // resolve(iaaUser.UserObject.vehicle.equipment_id);

      myEquipmentPromise({
        canSkipValidation: true,
        fetchUserData,
        equipment: usersVehicle,
        currentAddress,
        setDisplayPending,
        triggerAllInputComponentsValidPromise,
        dispatch: userDispatch,
      })
        .then(() => {
          setTimeout(() => {
            resolve(iaaUser.UserObject.vehicle.equipment_id);
          }, 1000);
        })
        .catch(() => {
          reject();
        });
    });
  };

  return (
    <div className="MyEV">
      <>
        <AppStepHeader language={language} headlineID="myElectricVehicle" blurbID="myEV.headline.blurb" />
        <PhotoProcessor
          id="vehicle_registration"
          type="vehicle"
          language={language}
          topMessage={getTranslatedMessageFromID('photoprocessor.takeAPhotoOfThe', language)}
          bottomMessage={getTranslatedMessageFromID('vehicleRegistrationCard', language)}
          equipmentID={usersVehicle.equipment_id.toString()}
          setDisplayPending={setDisplayPending}
          inputComponentsMustValidate={inputComponentsMustValidate}
          hasInvalidInput={hasInvalidInput}
          assetType="VEHICLE_PHOTOGRAPH"
          beforeAddingPhoto={beforeSendingPhoto}
        />
        <EditableDetails
          language={language}
          detailSetName={getTranslatedMessageFromID('vehicleDetails', language)}
          detailSetSubtitle={getTranslatedMessageFromID('myEV.vehicleDetails.blurb', language)}
          formFieldsList={VehicleDetails(usersVehicle, updateVehicle)}
          inputComponentsMustValidate={inputComponentsMustValidate}
          setDisplayPending={setDisplayPending}
          hasInvalidInput={hasInvalidInput}
          startInEditMode={false}
          forceMobile
        />
      </>
    </div>
  );
};

export default MyElectricVehicleMainContent;

MyElectricVehicleMainContent.propTypes = {
  language: PropTypes.string.isRequired,
  hasInvalidInput: PropTypes.func.isRequired,
  inputComponentsMustValidate: PropTypes.bool.isRequired,
  setDisplayPending: PropTypes.func.isRequired,
  triggerAllInputComponentsValidPromise: PropTypes.func.isRequired,
  fetchUserData: PropTypes.func.isRequired,
  alertAPIError: PropTypes.func,
};

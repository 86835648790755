// @TODO get translations from Ana
const esp = {
  welcome: "¡Bienvenidos!",
  myProfile: "Profile",
  updateAccount: "Update My Account",
  chargerLocation: "Ubicación",
  myCharger: "My Charger",
  myUtility: "My Utility",
  myElectricVehicle: "My Electric Vehicle",
  partOfHomePage: "Will become part of home page",
  contactUs: "Contact Us",
  componentZoo: "Component Zoo",
  registration: "Registration",
  login: "Login",
  register: "Get Started",
  continue: "Continue",
  firstName: "First name",
  lastName: "Last name",
  mobilePhone: "Mobile phone number",
  homePhone: "Home phone number",
  householdIncome: "Household income",
  preferredEmailAddress: "Preferred email address",
  signature: "Type your name on the signature",
  zipCode: "ZIP code",
  zip: "ZIP",
  city: "City",
  state: "State",
  yes: "Si",
  no: "No",
  back: "Back",
  next: "Next",
  saveAndNext: "Save & Next",
  homeCharger: "Home Charger",
  applicationAssistant: "Application Assistant",
  contactSupport: "Contact Support",
  uploadPhoto: "Upload Photo",
  takeAPhoto: "Take a Photo",
  photoReceived: "Photo Received",
  chargerBrand: "Charger type",
  chargerModel: "Charger model",
  serialNumber: "Serial Number",
  purchasePrice: "Purchase Price",
  purchaseDate: "Purchase Date",

  "userProfile.headline.blurb":
    "Here’s why you need to enter this information.",
  "chargerLocation.headline.headline": "Location",
  "chargerLocation.headline.blurb":
    "Here’s why you need to enter this information.",
  "myCharger.headline.headline": "Charger",
  "myCharger.headline.blurb": "Here’s why you need to enter this information",

  "buttontext.nextpage": "Next Page",
  "buttonText.incentiveCard.reviewAndApply": "Review & Apply",
  "buttonText.incentiveCard.applicationIncomplete": "Finish & Apply",

  "card.seeless": ">> see less <<",
  "card.seemore": "<< see more >>",

  "register.agreeToTerms": "I agree to the terms and conditions",

  "register.registerSuccess.welcome":
    "Welcome {firstName} to the home charging application assistant",
  "register.registerSuccess.accountCreated":
    "Success! Your account has been created.",
  "register.registerSuccess.nextUp":
    "Next up, answer some key questions about your car, charger, and utility to confirm your elidgibility and start the process of preparing your incentive applications.",
  "register.registerSuccess.manyIncentives":
    "There are many incentives potentially available to you.",
  "register.registerSuccess.seeAllIncentives": "See all incentives.",

  "homepage.jumbotron.title": "Welcome to the Incentive Assistant.",
  "homepage.jumbotron.subtitle":
    "The easiest way to get home charging incentives.",
  "homepage.seeHomeChargingAdvisor":
    "If you haven’t purchased a charger yet,{br}see the <link>home charging advisor</link>.",
  "homepage.firstTime": "First time?",
  "homepage.letsFindOut": "Let's find out if we can help.",
  "homepage.zipcodeLabel": "Your Zip Code",
  "homepage.check": "Check",
  "homepage.checking": "Checking...",
  "homepage.zipcodeError":
    "Sorry! We encountered an error. Please try again later.",
  "homepage.noIncentivesAvailable":
    "Thanks for checking. We are a new tool, so your incentives are not included yet but should be added soon. Please <link>click here to apply</link> for incentives directly.",
  "homepage.canHelpYouGet":
    "The incentive assistant can help you get {amount} {incentivePluralized}:",
  "homepage.incentiveTitle": "{name} from {grantor}",
  "homepage.getStarted": "Get Started",
  "homepage.returning": "Returning?",
  "homepage.jumpBackIn": "Jump right back in.",
  "homepage.login": "Login",
  "homepage.providedByZappyRide":
    "This tool is provided to you by Veloz in partnership with ZappyRide. <link>Contact ZappyRide</link> with any questions.",
  "homepage.getYourRefundHeader":
    "Get your refund with the charging incentive application assistant",
  "homepage.applyingPara":
    "Applying for home charging incentives can be complicated, so we’re going to do all the work for you. Tell us about your setup, and we’ll do the rest.",
  "homepage.applyingParaNote":
    "NOTE: This tool is currently only available to customers of Anaheim Public Utilities, Burbank Water & Power, and Colton Electric.",
  "homepage.usersPurchase":
    "Users must also have purchased or are planning to purchase a home EV charger.",
  "homepage.moreGeographies":
    "More geographies will come online shortly. Please check back or reach out to veloz-iaa-support@zappy.freshdesk.com with any questions.",
  "homepage.howItWorksHeader": "How it Works",

  "homepage.createASecureAccount": "Create a Secure Account",

  // @TODO need to get copy from Jaime
  "homepage.encryptionWeUse":
    "We use XXX encyption security brag to make sure your information is always safe.",

  "homepage.submitYourDocuments": "Submit Your Documents",
  "homepage.sendInstallationDetails":
    "Submit photos of your charger installation, receipt, and permits.",
  "homepage.getYourRefund": "Get Your Refund",
  "homepage.trackApplicationProgress":
    "Track your application progress along the way",
  "homepage.buyACharger": "Buy A Charger",

  // @TODO need new text for this
  "homepage.buyChargerBlurbText":
    "Visit the Electric For All <link>Home Charging Advisor</link> to find the home charger that is right for you.",

  "homepage.installYourCharger": "Install Your Charger",
  "homepage.hireElectrician":
    "Hire a certified electrician to install the charger in your home.",
  "homepage.whatToDoHeader": "What to do before you apply",
  "homepage.takeSomePictures": "Take Some Pictures",
  "homepage.registrationPhoto": "Photo of your EV registration",
  "homepage.chargerInvoicePhoto": "Photo of your charger invoice",
  "homepage.installedChargerPhoto": "Photo of your installed charger",
  "homepage.utilityBillPhoto": "Photo of your electric utility bill",

  "incentiveCard.incentiveStatus.applied": "Applied",
  "incentiveCard.incentiveStatus.paid": "Paid",
  "incentiveCard.incentiveStatus.declined": "Declined",
  "incentiveCard.contactGrantor": "Contact Grantor",

  "userProfile.firstName": "Enter first name",
  "userProfile.lastName": "Enter last name",
  "userProfile.mobilePhone": "Enter mobile phone number",
  "userProfile.homePhone": "Enter home phone number",
  "userProfile.householdSize": "Household members",
  "userProfile.selectIncomeRange": "Select income range",
  "userProfile.emailAddress": "Email address",
  "userProfile.signature": "Type your name",
  "userProfile.signature.legalese":
    "Your signature will be used to sign the application. You will be able to review it before submitting. Filling in the following information will constiture your eSignature and will have the same legan impact as signing a printed version of the application.",
  "userProfile.signature.yourSignature": "Your signature",
  "userProfile.signature.selectFont": "Select a font for your signature",

  "chargerLocation.homeStreetAddress.legend": "Your home address",
  "chargerLocation.homeStreetAddress.placeholder": "Enter a home address",
  "chargerLocation.zipappt.legend": "Appt/Unit #",
  "chargerLocation.zipappt.placeholder": "Unit #",
  "chargerLocation.propertyOwner": "Are you the owner of this property?",
  "chargerLocation.chargerAtProperty":
    "Is this where the charger will be located?",

  "photoprocessor.charger.receipt": "Charger Purchase Receipt",
  "photoprocessor.installCharger.receipt": "Charger Installation Receipt",
  "photoprocessor.installedCharger": "Installed Charger",
  "photoprocessor.installationPermit": "Installation Permit",
  "photoprocessor.uploadPhoto": "Upload a photo of your",
  "photoprocessor.takeAPhotoOfThe": "Take a photo of the",

  "myCharger.selectChargerBrand": "Select a charger brand",
  "myCharger.selectChargerModel": "Select your charger model",

  "myUtility.accountID": "Electric utility account #",
  "myUtility.providerName": "Electric utility name",
  "myUtility.providerName.placeholder": "Select utility name",

  "contactUs.title": "Contact Support",
  "contactUs.heading": "You have questions? We have answers!",
  "contactUs.bodyText":
    "Send us an email and one of our team{br}members will respond within 48 hours",
  "contactUs.whoWeAre":
    "The Electric For All Incentive Assistant, powered by Veloz, is administered by our technology partner ZappyRide.  Veloz is a nonprofit that engages its powerhouse of members, public-private partnerships, programs, policy engagement, and public awareness campaigns to overcome barriers to electrification and create a virtuous cycle of desire and demand. With more affordable makes and models and refueling stations, Veloz aims to make electric for all a reality.",
  "contactUs.disclaimer":
    "Veloz does not support or promote any particular car manufacturer or electric vehicle charger company and nothing on this website is intended as an endorsement. Please consult our <link>privacy policy</link>.",

  "seoPageTitle.homepage": "Incentive Assistant",
  "seoPageTitle.dashboard": "Dashboard",
  "seoPageTitle.welcome": "Welcome to Incentive Assistant",
  "seoPageTitle.profile": "Profile",
  "seoPageTitle.updateAccount": "Update Account",
  "seoPageTitle.chargerLocation": "Location",
  "seoPageTitle.myCharger": "Charger",
  "seoPageTitle.myUtility": "Utility",
  "seoPageTitle.myElectricVehicle": "Electric Car",
  "seoPageTitle.myDashboard": "My Dashboard",
  "seoPageTitle.contactUs": "Contact Us",
  "seoPageTitle.componentCatalog": "Component Catalog",
  "seoPageTitle.login": "Login",
  "seoPageTitle.registration": "Incentive Assistant",
  "seoPageTitle.completeApplication": "Complete Application",
  "seoPageTitle.submitApplication": "Submit Application",
  "seoPageTitle.threeSteps": "Homepage",
  "seoPageTitle.noIncentivesAvailable": "No Incentives Available",
};

export default esp;

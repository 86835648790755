import React from 'react';
import PropTypes from 'prop-types';

const CardValue = (
  {
    monetaryValue,
    incentiveType,
  },
) => {
  const cardValue = monetaryValue || incentiveType;

  return (
    <div className="CardValue">
      {cardValue}
    </div>
  );
};

export default CardValue;

CardValue.defaultProps = {
  monetaryValue: null,
  incentiveType: null,
};

CardValue.propTypes = {
  incentiveType: PropTypes.string,
  monetaryValue: PropTypes.string,
};

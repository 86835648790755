import React from 'react';

import { Helmet } from 'react-helmet-async';
import PropTypes from 'prop-types';
import Seo from '../SEO/Seo';

import '../../css/master.scss';
import defaultImage from '../../customizations/assets/images/generic-preview.png';

import Header from './Header/Header';
import Main from './Main/Main';
import FooterFromEVShopper from './Footer/FooterFromEVShopper';

// @TODO this is causing unit tests to fail... danged if I could say why.
// import FavIcon from '../../assets/images/favicon.ico';

// @TODO do I still need to pass language and changeLanguage to Footer?
// Depends on whether we'll be using header or footer to change language.
const PageLayout = ({
  children,
  page,
  description,
  image,
  language,
  authState,
  changeLanguage,
  path,
  title,
  haveCheckedRedirect
}) => (
  <div className="PageLayout">

    <Helmet defer>
      {
        /* <link rel="shortcut icon" href={FavIcon} /> */
      }
      <meta name="viewport" content="width=device-width, initial-scale=1" />
      <meta name="theme-color" content="#000000" />

    </Helmet>
    <Seo
      language={language}
      image={image || defaultImage}
      title={title}
      path={path}
      description={"Get your refund with the charging incentive application assistant"}
      twitterHandle={
        process.env.REACT_APP_COMPONENTS_SEO_TWITTER_HANDLE
          ? process.env.REACT_APP_COMPONENTS_SEO_TWITTER_HANDLE
          : (null)
      }
    />

    <Header
      page={page}
      language={language}
      authState={authState}
    />
    <Main
      haveCheckedRedirect={haveCheckedRedirect}
    >
      <span id="main-content-id" tabIndex="-1" />
      {children}
    </Main>
    <FooterFromEVShopper language={language} changeLanguage={changeLanguage} />
  </div>
);

export default PageLayout;

PageLayout.defaultProps = {
  page: '',
  image: '',
  description: '',
  language: 'EN',
  changeLanguage: () => { },
  path: '',
};

PageLayout.propTypes = {
  children: PropTypes.node.isRequired,
  page: PropTypes.string,
  image: PropTypes.string,
  description: PropTypes.string,
  language: PropTypes.string,
  authState: PropTypes.any,
  changeLanguage: PropTypes.func,
  path: PropTypes.string,
  title: PropTypes.string,
  haveCheckedRedirect: PropTypes.bool.isRequired
};

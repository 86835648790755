import filterByField from "../../utils/functions/misc/filterByField";
import { getIncentives } from "./loadToGlobalState";

const removeIncentivesWithoutUserData = (incentives) => {
  const remainingIncentives = incentives.filter((incentive) => {
    return incentive.status
      ? true
      : false;
  });

  return remainingIncentives;
};

const processIncentiveDataFromUnsafe = (dispatch, savedIncentives, availableIncentives) => {
  let processedIncentives = [ ...savedIncentives];

  const fieldsToAdd = {
    apply_date: '',
    apply_completion_date: '',
    disable_alerts: true,
    completed_application_url: '',
    equipment_id: ''
  };

  // If we do have availableIncentives, remove where we haven't collected data.
  processedIncentives = removeIncentivesWithoutUserData(processedIncentives);
  
  for(let i = 0; i < availableIncentives.length; i++) {
    if(filterByField('incentive_id', availableIncentives[i].incentive_id, processedIncentives).length > 0) {
      continue;
    } 

    if(availableIncentives[i].placeholder && processedIncentives.length > 0) {
      
      // Don't show the placeholder if we have some completed incentives
      continue;
    }

    const newIncentive = { ...fieldsToAdd, ...availableIncentives[i] }; 

    processedIncentives.push(newIncentive);
  }

  getIncentives(dispatch)(processedIncentives);
};

export default processIncentiveDataFromUnsafe;

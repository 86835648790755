import { optionNameAndValSame } from '../dropdown';

const fontList = [
  'Caveat',
  'Dawning of a New Day',
  'Sacramento'
];

const fontSelectorOptions = () => optionNameAndValSame(fontList);

export {
  fontList,
  fontSelectorOptions,
};

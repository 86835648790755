import React from 'react';
import PropTypes from 'prop-types';

import DashboardNavDrawer from '../../DashboardNavDrawer/DashboardNavDrawer';

import '../css/ProgressSubdrawer.scss';

const ProgressSubDrawer = ({
  titleNode,
  drawerContent,
  language,
  editLink,
}) => (
  <DashboardNavDrawer
    title={titleNode}
    items={drawerContent}
    showCount={false}
    className="ProgressSubdrawer"
    language={language}
    editLink={editLink}
  />
);

export default ProgressSubDrawer;

ProgressSubDrawer.defaultProps = {
  editLink: '',
};

ProgressSubDrawer.propTypes = {
  titleNode: PropTypes.node.isRequired,
  drawerContent: PropTypes.arrayOf(PropTypes.node).isRequired,
  language: PropTypes.string.isRequired,
  editLink: PropTypes.string,
};

const loadToGlobalState = (dispatch, { type, payload }) => {
  dispatch({ type, payload });
};

const updateUserFunction = (dispatch) => {
  return (payload) => {
    loadToGlobalState(dispatch, {
      type: 'SET_PROFILE',
      payload,
    });
  };
};

const updatePrimaryAddressFunction = (dispatch) => {
  return (payload) => {
    loadToGlobalState(dispatch, {
      type: 'SET_PRIMARY_ADDRESS',
      payload,
    });
  };
};

const updateChargerAddressFunction = (dispatch) => {
  return (payload) => {
    loadToGlobalState(dispatch, {
      type: 'SET_CHARGER_ADDRESS',
      payload,
    });
  };
};

const updateChargerFunction = (dispatch) => {
  return (payload) => {
    loadToGlobalState(dispatch, {
      type: 'SET_CHARGER',
      payload,
    });
  };
};
const resetChargerModelFunction = (dispatch) => {
  return () => {
    loadToGlobalState(dispatch, {
      type: 'RESET_CHARGER_MODEL',
    });
  };
};

const updateVehicleFunction = (dispatch) => {
  return (payload) => {
    loadToGlobalState(dispatch, {
      type: 'SET_VEHICLE',
      payload,
    });
  };
};
const resetVehicleModelYear = (dispatch) => {
  return () => {
    loadToGlobalState(dispatch, {
      type: 'RESET_VEHICLE_MODEL_AND_YEAR',
    });
  };
};

const getIncentives = (dispatch) => {
  return (payload) => {
    loadToGlobalState(dispatch, {
      type: 'SET_INCENTIVES',
      payload,
    });
  };
};

const updateErrorState = (dispatch) => {
  return (payload) => {
    loadToGlobalState(dispatch, {
      type: 'SET_ERROR_STATE',
      payload,
    });
  };
};

const updateCurrentPage = (dispatch) => {
  return (payload) => {
    loadToGlobalState(dispatch, {
      type: 'SET_CURRENT_PAGE',
      payload,
    });
  };
};

export default loadToGlobalState;

export {
  loadToGlobalState,
  updateUserFunction,
  updatePrimaryAddressFunction,
  updateChargerAddressFunction,
  updateChargerFunction,
  resetChargerModelFunction,
  updateVehicleFunction,
  resetVehicleModelYear,
  getIncentives,
  updateErrorState,
  updateCurrentPage,
};

import React from 'react';
import PropTypes from 'prop-types';

import AppStepHeader from '../../../Atoms/AppStepHeader/AppStepHeader';
import InputFields from '../submodules/InputFields';
import IncentiveSpecificPhotoProcessors from '../submodules/IncentiveSpecificPhotoProcessors';

const CompleteApplicationMainContent = (
  {
    incentive,
    updateIncentive,
    language,
    hasInvalidInput,
    inputComponentsMustValidate,
    setDisplayPending,
    fetchUserData
  },
) => {
  const photoFields = [];
  const otherFields = [];

  const targetArrays = {
    photo: photoFields
  };

  const sortIncentivesByType = () => {
    if(incentive && incentive.input_fields) {
      incentive.input_fields.forEach((inputField) => {
        const targetArray = targetArrays[inputField.type.toLowerCase()] || otherFields;
  
        targetArray.push(inputField);
      });    
    }
  };

  sortIncentivesByType();

  return (
    <div className="CompleteApplication">
      <AppStepHeader
        language={language}
        headlineID="completeApplication.headline.headline"
        blurbID="completeApplication.headline.blurb"
      />

      <IncentiveSpecificPhotoProcessors
        incentive={incentive}
        photoFields={photoFields}
        hasInvalidInput={hasInvalidInput}
        inputComponentsMustValidate={inputComponentsMustValidate}
        setDisplayPending={setDisplayPending}
        language={language}
        updateIncentive={updateIncentive}
        fetchUserData={fetchUserData}
      />

      <hr/>

      <InputFields
        incentive={incentive}
        fields={otherFields}
        updateIncentive={updateIncentive}
        language={language}
        hasInvalidInput={hasInvalidInput}
        inputComponentsMustValidate={inputComponentsMustValidate}
      />
    </div>
  );};

export default CompleteApplicationMainContent;

CompleteApplicationMainContent.propTypes = {
  incentive: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  updateIncentive: PropTypes.func.isRequired,
  language: PropTypes.string.isRequired,
  hasInvalidInput: PropTypes.func.isRequired,
  inputComponentsMustValidate: PropTypes.bool.isRequired,
  setDisplayPending: PropTypes.func.isRequired,
  fetchUserData: PropTypes.func.isRequired
};

import getAssetUploadURL from '../get/getAssetUploadURL';

const sendPhotoToServer = (
  {
    photoFile,
    photoID,
    equipmentID,
    assetType,
    callback,
    incentive_id
  },
) => {

  const uploadPhoto = (
    uploadURL,
    {
      photoFile,
    }
  ) => new Promise((resolve, reject) => {
    fetch(uploadURL, {
      method: 'PUT',
      headers: {
        'Content-Type': photoFile.type,
      },
      body: photoFile
    })
      .then(() => {
        resolve();
      })
      .catch((err) => {
        reject(err);
      });
  });

  const getAssetUploadURLProps = {
    equipment_id: equipmentID, 
    image_name: photoID.toUpperCase(), 
    asset_type: assetType, 
    mime_type: photoFile.type
  };

  if(incentive_id) {
    getAssetUploadURLProps.incentive_id = incentive_id;
  }
  
 
  getAssetUploadURL(getAssetUploadURLProps)
    .then((uploadURL) => {

      // Upload photo to uploadURL

      uploadPhoto(
        uploadURL,
        {
          photoFile
        },
      )
        .then(() => {
          callback();
        })
        .catch(() => {

        });
    });

  // @TODO just for now while we're waiting on the backend.
};

const photoHandler = (props) => {

  const photoID = props.event.target.id;
  const photoFile = props.event.target.files[0];

  const photoDetails = {
    photoID,
    photoFile
  };

  const newProps = { 
    ...props,
    ...photoDetails
  };

  sendPhotoToServer(newProps);
};

export default photoHandler;

import React from "react";
import PropTypes from "prop-types";
import { Row, Col, Container } from "reactstrap";
import velozBolt from "../../../customizations/assets/images/Veloz-bolt.png";
import logger from '../../../utils/functions/logging/logger';

const colors = {
  darkGrey: "#222222",
  white: "#ffffff",
  lightGrey: "#ececec",
  mediumGrey: " #6b7280",
};

const TopNav = () => (
  <Row
    style={{
      display: "flex",
      height: "84px",
      backgroundColor: colors.darkGrey,
    }}
  >
    <Col>
      <Row style={{
        height: "100%",
        margin: "0 auto",
        alignItems: "center",
      }}>
        <Col md="4" style={{ color: colors.white }}>
          <img
            style={{ margin: "1rem" }}
            src={velozBolt}
            alt="veloz bolt"
          />
          <span style={{
            fontWeight: 300,
            color: colors.lightGrey,
            textTransform: "uppercase",
          }}>
            Incentive Assistant
          </span>
        </Col>
      </Row>
    </Col>
  </Row>
);

const ErrorContainer = ({ children }) => (
  <div
    style={{
      width: "100%",
      borderRadius: "8px",
      backgroundColor: colors.white,
      boxShadow: "0 16px 34px 7px rgba(121,121,121,0.1)",
      padding: "72px 40px",
      marginBottom: "40px"
    }}
  >
    {children}
  </div>
);

ErrorContainer.propTypes = {
  children: PropTypes.node,
};

const Header = ({ children }) => (
  <div
    style={{
      color: colors.mediumGrey,
      margin: "40px 0"
    }}
  >
    {children}
  </div>
);

Header.propTypes = {
  children: PropTypes.node,
};

const ErrorPage = (
  {
    errorState, // may not need
    fromCaughtError,
    honeybadger
  }
) => { 

  logger(errorState, 'ErrorPage');

  if(errorState && honeybadger) {
    honeybadger.notify(errorState);
  }

  return (
    <>
      {
        fromCaughtError
          ? null
          : (<TopNav />)
      }
      <Container>
        <Row>
          <Col md={{ size: 8, offset: 2 }}>
            <Header>
              <h1>Oops, looks like something went wrong.</h1>
            </Header>
            <ErrorContainer>
              <p>
              Don&apos;t worry, our development team has already been notified.
              Please refresh your browser or <a href="/">click here to try again</a>. 
              </p>

              <p>If the problem persists, check back later.</p>
            </ErrorContainer>
          </Col>
        </Row>
      </Container>
    </>
  );};

export default ErrorPage;

ErrorPage.defaultProps = {
  errorState: null,
  fromCaughtError: false,
  honeybader: null
};

ErrorPage.propTypes = {
  errorState: PropTypes.object,
  fromCaughtError: PropTypes.bool,
  honeybadger: PropTypes.any
};

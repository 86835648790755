import React from 'react';
import PropTypes from 'prop-types';

import ApplicationAssistantLayout from '../../ApplicationAssistantLayout/ApplicationAssistantLayout';
import NoIncentivesAvailableContent from './MainContent/NoIncentivesAvailableContent';

// import { Auth } from 'aws-amplify';

const NoIncentivesAvailable = (
  {
    language,
    user,
    equipment,
    vehicle,
    currentAddress,
    setCurrentIncentiveByID,
    handlePageForwardClick,
    inputComponentsMustValidate,
    hasInvalidInput,
  },
) => {
  return (
    <ApplicationAssistantLayout
      language={language}
      user={user}
      equipment={equipment}
      vehicle={vehicle}
      currentAddress={currentAddress}
      className="NoIncentivesAvailableIAAClass"
      setCurrentIncentiveByID={setCurrentIncentiveByID}
      handlePageForwardClick={handlePageForwardClick}
      previousPage="/user-profile"
      nextPage="/dashboard"
      previousPageButtonText="Back"
      nextPageButtonText="Submit"
    >
      <NoIncentivesAvailableContent
        language={language}
        user={user}
        vehicle={vehicle}
        currentAddress={currentAddress}
        equipment={equipment}
        setCurrentIncentiveByID={setCurrentIncentiveByID}
        inputComponentsMustValidate={inputComponentsMustValidate}
        hasInvalidInput={hasInvalidInput}
      />
    </ApplicationAssistantLayout>
  );

};
export default NoIncentivesAvailable;

NoIncentivesAvailable.defaultProps = {
  handlePageForwardClick: () => {},
};

NoIncentivesAvailable.propTypes = {
  language: PropTypes.string.isRequired,
  user: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  equipment: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  vehicle: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  currentAddress: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  setCurrentIncentiveByID: PropTypes.func.isRequired,
  handlePageForwardClick: PropTypes.func,
  hasInvalidInput: PropTypes.func.isRequired,
  inputComponentsMustValidate: PropTypes.bool.isRequired,
};

import React from 'react';
import PropTypes from 'prop-types';

const DesktopEditableDetail = ({
  legend,
  nodeToRender,
  editMode,
}) => {
  const rowClassName = editMode
    ? ''
    : 'row';

  const leftClassName = editMode
    ? ''
    : 'col-5 text-left';

  const rightClassName = editMode
    ? 'mobile-edit-textfield'
    : 'col-7 text-right';

  const leftClass = editMode
    ? null
    : (<div className={leftClassName}>{legend}</div>);

  return (
    <div className={`${rowClassName} EditableDetail`}>
      {leftClass}
      <div className={rightClassName}>{nodeToRender}</div>
    </div>
  );
};

export default DesktopEditableDetail;

DesktopEditableDetail.propTypes = {
  legend: PropTypes.string.isRequired,
  nodeToRender: PropTypes.node.isRequired,
  editMode: PropTypes.bool.isRequired,
};

import React from 'react';
import PropTypes from 'prop-types';
import Dropdown from '../../../Inputs/Dropdown/Dropdown';

const EditableDetailDropdown = ({
  optionsHelper,
  fieldName,
  propsCollection,
  updatePropsCollection,
  legend,
  fieldWidth, 
  inputComponentsMustValidate,
  validate,
  placeholder, 
  hasInvalidInput
}) => {


  let options = optionsHelper();

  return (
    <Dropdown
      fieldName={fieldName}
      propsCollection={propsCollection}
      fieldWidth={fieldWidth}
      updatePropsCollection={updatePropsCollection}
      validate={validate}
      legend={legend}
      options={options}
      inputComponentsMustValidate={inputComponentsMustValidate}
      placeholder={placeholder}
      hasInvalidInput={hasInvalidInput}
    />
  );
};

export default EditableDetailDropdown;

EditableDetailDropdown.defaultProps = {
  forceMobile: false,
  optionsHelper: () => {
    return new Promise((resolve) => {
      resolve();
    });
  },
  dropdown: false,
};

EditableDetailDropdown.propTypes = {
  fieldName: PropTypes.string.isRequired,
  propsCollection: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  updatePropsCollection: PropTypes.func.isRequired,
  inputComponentsMustValidate: PropTypes.bool.isRequired,
  legend: PropTypes.string.isRequired,
  fieldWidth: PropTypes.string.isRequired,
  optionsHelper: PropTypes.func,
  dropdown: PropTypes.bool, 
  validate: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  hasInvalidInput: PropTypes.func.isRequired
};

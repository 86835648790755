import React, { useContext } from 'react';
import PropTypes from 'prop-types';

import PhotoProcessor from '../../../Atoms/Inputs/PhotoProcessor/PhotoProcessor';

import { renderingInAnyMobile } from '../../../../utils/functions/device/determinePlatform';
import { getTranslatedMessageFromID } from '../../../../utils/functions/intl/intlWrapper';
import logger from '../../../../utils/functions/logging/logger';
import { UserContext } from '../../../IaaUserEntity/IaaUserEntity';
import { getIncentives } from '../../../../apiInterface/responseHandlers/loadToGlobalState';

const IncentiveSpecificPhotoProcessors = ({
  incentive,
  hasInvalidInput,
  inputComponentsMustValidate,
  setDisplayPending,
  photoFields,
  language,
  updateIncentive,
  fetchUserData
}) => {

  logger(photoFields, 'IncentiveSpecificPhotoProcessors');

  const [iaaUser] = useContext(UserContext);

  const topMessageId = renderingInAnyMobile()
    ? 'photoprocessor.takeAPhotoOfThe'
    : 'photoprocessor.uploadPhoto';

  const topMessage = getTranslatedMessageFromID(topMessageId, language);

  const translatePhotoField = (field) => {
    const incentiveImg = incentive.images[field.name.toUpperCase()] || incentive.images[field.name] || {};
    const photoUploaded = incentiveImg.url 
      ? true
      : false;

    return {
      name: field.name, 
      fieldDescription: field.description,
      photoUploaded: photoUploaded || field.user_input === 'done',
      photo: null
    };
  };

  const renderPhotoProcessor = (p) => {
    return (
      <PhotoProcessor 
        key={`photo_processor_${p.name}`}
        id={p.name.toUpperCase()}
        equipmentID={iaaUser.UserObject.charger.equipment_id}
        setDisplayPending={setDisplayPending}
        hasInvalidInput={hasInvalidInput}
        inputComponentsMustValidate={inputComponentsMustValidate}
        language={language}
        topMessage={topMessage}
        bottomMessage={p.fieldDescription}
        incentive={incentive}
        updateIncentive={updateIncentive}
        assetType='INCENTIVE_PHOTOGRAPH'
        incentivePhotoReceived={p.photoUploaded}
        fetchUserData={fetchUserData}
      />
    );
  };

  const prepImageNodeNames = () => {
    photoFields.forEach((photoField) => {

      incentive.images[photoField.name.toUpperCase()] = incentive.images[photoField.name.toUpperCase()] 
      || 
      {
        size: '',
        type: ''
      };

      getIncentives([incentive]);
    });
  };

  if(photoFields.length === 0) {
    return (<div/>);
  }

  prepImageNodeNames();

  return(
    <div>
      {
        photoFields.map((field) => {
          const photoObj = translatePhotoField(field);
          return renderPhotoProcessor(photoObj);
        })
      }
    </div>
  );
};

export default IncentiveSpecificPhotoProcessors;

IncentiveSpecificPhotoProcessors.defaultProps = {
  photoFields: []
};

IncentiveSpecificPhotoProcessors.propTypes = {
  language: PropTypes.string.isRequired,
  incentive: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  hasInvalidInput: PropTypes.func.isRequired,
  inputComponentsMustValidate: PropTypes.bool.isRequired,
  setDisplayPending: PropTypes.func.isRequired,
  photoFields: PropTypes.array,
  updateIncentive: PropTypes.func,
  fetchUserData: PropTypes.func
};

import localStorageKeyring from "../../../constants/LOCAL_STORAGE_KEYS";
import { checkFieldCompleted } from "../../fields/checkFieldCompleted";
import LocalStorage from "../../functions/localStorage/LocalStorage";

import { requiredFieldsByCollection, requiredFieldsByPage } from "./data/constants";
class ApplicationProgressMonitor {

  static countTotalFields() {
    const collections = Object.keys(requiredFieldsByCollection);

    let fieldCount = 0;

    collections.forEach((collectionName) => {
      const fieldSet = requiredFieldsByCollection[collectionName];

      fieldCount += fieldSet.size;
    });

    return fieldCount;
  }

  static checkCompletedPage(pageName, dataCollection, fieldsByPage) {

    if(pageName === 'myVehicle') {
      fieldsByPage = new Set(['vin', 'make', 'model', 'model_year', 'vehicle_registration']);
    }

    const completedPages = new Set(
      LocalStorage.getLocalDatapoint(localStorageKeyring.completedPages)
    );    

    const partialPages = new Set(
      LocalStorage.getLocalDatapoint(localStorageKeyring.partialPages)
    );

    if(completedPages.has(pageName)) {
      return completedPages;
    }

    const completedFieldsByPage = new Set();
    const fields = Object.keys(dataCollection);

    fields.forEach((field) => {
      // @TODO eliminate need to call checkFieldNotEmpty twice on every field.
      if(fieldsByPage.has(field) && checkFieldCompleted(dataCollection[field])) {
        completedFieldsByPage.add(field);
        if(completedFieldsByPage.size === fieldsByPage.size) {
          completedPages.add(pageName);
          partialPages.delete(pageName);
        } else if(completedFieldsByPage.size > 0) {
          partialPages.add(pageName);  
        }
      }
    });

    LocalStorage.setLocalDatapoint(
      localStorageKeyring.completedPages,
      Array.from(completedPages)
    );

    LocalStorage.setLocalDatapoint(
      localStorageKeyring.partialPages,
      Array.from(partialPages)
    );

    // For testing and chaining purposes
    return completedPages;
  }

  static stashCompletedFields(dataCollection, pageName, fieldsByCollection, fieldsByPage) {
    const clonedDataCollection = { ...dataCollection };

    if(clonedDataCollection.images) {
      const imageNames = Object.keys(clonedDataCollection.images);

      imageNames.forEach((imageName) => {
        clonedDataCollection[imageName] = clonedDataCollection.images[imageName];
      });
    }

    const completedFieldsByCollection = new Set();

    if(!clonedDataCollection || Object.keys(clonedDataCollection).length === 0) {
      return completedFieldsByCollection;
    }

    this.checkCompletedPage(pageName, clonedDataCollection, fieldsByPage);

    fieldsByCollection.forEach((field) => {

      // @TODO grungy, but should work for now. At cleanup let's make these fields a 
      // complete map.
      let fieldToCheck = field;

      if(pageName === 'myVehicle') {

        // @TODO use this approach for all fields to prevent collisions
        const mappedFields = {
          vehicle_make: 'make',
          vehicle_model: 'model'
        };

        fieldToCheck = mappedFields[field] || fieldToCheck;
      }

      if(checkFieldCompleted(clonedDataCollection[fieldToCheck])) {
        completedFieldsByCollection.add(field);
      }     
    });

    return completedFieldsByCollection;
  }

  static updateSet(oldSet, newSet) {
    const mergedSet = new Set(
      [
        ...newSet,
        ...oldSet
      ]
    );
  
    return mergedSet;  
  } 

  /**
   * Sets fraction complete as user submits pages. Also works out which pages have been finished.
   * 
   * @param {Object} param0   includes the four main data sets: user, equipment, vehicle, currentAddress 
   * 
   * @returns {Number}        Number between 0 and 1 indicating fraction completed.
   */
  static updateCurrentProgress({
    user,
    equipment, 
    vehicle, 
    currentAddress,
  }) {
    const totalNumberOfFields = this.countTotalFields();
    let completedFields = new Set();

    const utilityCollection = currentAddress.power_supplier;

    const userFields = this.stashCompletedFields(
      user, 
      'userProfile',
      requiredFieldsByCollection.user,
      requiredFieldsByPage.userProfile
    );

    // @TODO for cleanup distill these five functions to one generic function.
    const addressFields = this.stashCompletedFields(
      currentAddress, 
      'chargerLocation',
      requiredFieldsByCollection.chargerAddress,
      requiredFieldsByPage.chargerLocation
    );

    const chargerFields = this.stashCompletedFields(
      equipment, 
      'myCharger',
      requiredFieldsByCollection.charger,
      requiredFieldsByPage.myCharger
    );

    const utilityFields = this.stashCompletedFields(
      utilityCollection,
      'myUtility',
      requiredFieldsByCollection.utility,
      requiredFieldsByPage.myUtility
    );

    const vehicleFields = this.stashCompletedFields(
      vehicle,
      'myVehicle',
      requiredFieldsByCollection.vehicle,
      requiredFieldsByPage.myVehicle
    );

    completedFields = this.updateSet(completedFields, userFields);
    completedFields = this.updateSet(completedFields, addressFields);
    completedFields = this.updateSet(completedFields, chargerFields);
    completedFields = this.updateSet(completedFields, utilityFields);
    completedFields = this.updateSet(completedFields, vehicleFields);
    
    const fractionComplete = completedFields.size / totalNumberOfFields;

    LocalStorage.setLocalDatapoint(
      localStorageKeyring.fractionFieldsFinished,
      fractionComplete
    );

    // For testing and chaining purposes.
    return fractionComplete;
  }

  /**
   * Pulls fraction completed from localStorage
   * 
   * @returns {Number}  Number between 0 and 1 indicating fraction completed.
   */
  static getCurrentProgress() {
    const localStorageVal = LocalStorage.getLocalDatapoint(localStorageKeyring.fractionFieldsFinished);

    // @TODO for unit tests. We'll need a better mock for LocalStorage.
    const returnValue = isNaN(localStorageVal)
      ? 0
      : localStorageVal; 

    return (
      returnValue 
      || 0
    );
  }

  /**
   * Pulls set of completed pages from localstorage.
   * 
   * @returns {Set}  Pages that are complete.
   */
  static getCurrentPagesComplete() {
    return new Set(
      LocalStorage.getLocalDatapoint(localStorageKeyring.completedPages)
    );
  }

  static getCurrentPartialPages() {
    return new Set(
      LocalStorage.getLocalDatapoint(localStorageKeyring.partialPages)
    );
  }
  
}

export default ApplicationProgressMonitor;

import React from 'react';
import PropTypes from 'prop-types';
import { UncontrolledTooltip } from 'reactstrap';

import tooltipIcon from '../../../customizations/assets/images/icons/tooltipIcon.svg';

const ToolTip = ({
  id,
  message,
  img,
}) => (
  <>
    <span
      id={id}
      style={{ display: 'inline-block' }}
      className="ToolTip"
    >
      <img
        src={img || tooltipIcon}
        alt=""
        style={{ width: '16px', marginLeft: '5px', marginBottom: '3px' }}
      />
    </span>
    <UncontrolledTooltip placement="left" target={id}>
      <span>{message}</span>
    </UncontrolledTooltip>
  </>
);

export default ToolTip;

ToolTip.defaultProps = {
  img: tooltipIcon,
};

ToolTip.propTypes = {
  id: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
  img: PropTypes.any, // eslint-disable-line react/forbid-prop-types
};

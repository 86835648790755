const bindDataToField = (datapoints, json) => {
  const newFieldData = {};
  datapoints.forEach((dataPoint) => {
    if (json[dataPoint.name]) {
      newFieldData[dataPoint.name] = json[dataPoint.name];
    } else {
      newFieldData[dataPoint.name] = dataPoint.default;
    }
  });

  return newFieldData;
};

export default bindDataToField;

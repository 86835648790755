import React from 'react';
import PropTypes from 'prop-types';

import TextField from '../../../../Atoms/Inputs/TextField/TextField';
import DateInput from '../../../../Atoms/Inputs/DateInput/DateInput';

import { getTranslatedMessageFromID } from '../../../../../utils/functions/intl/intlWrapper';
import { checkFieldNotEmpty, checkDateFormat } from '../../../../Atoms/Inputs/functions/inputHelper';

const PurchaseDatePrice = ({
  equipment,
  updateEquipment,
  language,
  hasInvalidInput,
  inputComponentsMustValidate,
}) => (
  <div className="row double-field-row">
    <div className="col-6 double-field-left">
      <DateInput
        validate={checkDateFormat}
        className="PurchaseDateInput"
        fieldName="purchase_date"
        legend={getTranslatedMessageFromID('purchaseDate', language)}
        inputComponentsMustValidate={inputComponentsMustValidate}
        hasInvalidInput={hasInvalidInput}
        propsCollection={equipment}
        updatePropsCollection={updateEquipment}
      />
    </div>
    <div className="col-6 double-field-right purchase-price">
      <TextField
        validate={checkFieldNotEmpty}
        className="TextInput"
        fieldName="purchase_price"
        defaultValue="$"
        legend={getTranslatedMessageFromID('purchasePrice', language)}
        fieldWidth="100%"
        maxFieldLength="9"
        inputComponentsMustValidate={inputComponentsMustValidate}
        hasInvalidInput={hasInvalidInput}
        propsCollection={equipment}
        updatePropsCollection={updateEquipment}
        prepend="$"
        additionalOnChange={(val) => {
          return(val.replace('$', ''));
        }}
      />
    </div>
  </div>
);

export default PurchaseDatePrice;

PurchaseDatePrice.propTypes = {
  equipment: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  updateEquipment: PropTypes.func.isRequired,
  language: PropTypes.string.isRequired,
  hasInvalidInput: PropTypes.func.isRequired,
  inputComponentsMustValidate: PropTypes.bool.isRequired,
};

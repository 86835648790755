import { isMobileOnly, isTablet } from 'react-device-detect';
import { checkThresholdSize } from '../viewability/viewability';

const renderingInSmartphone = () => {
  return isMobileOnly;
};

const renderingInTablet = () => {
  return isTablet 
    ? isTablet
    : checkThresholdSize('tablet');
};

const renderingInAnyMobile = () => {
  return renderingInTablet() || renderingInSmartphone();
};

const renderingInDesktop = () => {
  return !renderingInAnyMobile();
};

export {
  renderingInSmartphone,
  renderingInTablet,
  renderingInAnyMobile,
  renderingInDesktop
};

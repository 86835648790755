import { renderingInAnyMobile } from '../../../../utils/functions/device/determinePlatform';
import { pagesBothPlatforms, pagesDesktopOnly } from '../data/applicationStepsData';
import { getTranslatedMessageFromID } from '../../../../utils/functions/intl/intlWrapper';

const listApplicationSteps = (language) => {
  const processPage = (page) => ({
    targetPage: page.targetPage,
    titleText: getTranslatedMessageFromID(page.titleText, language),
  });
  const pages = [];

  pagesBothPlatforms.forEach((page) => {
    pages.push(processPage(page));
  });

  if (!renderingInAnyMobile()) {
    const desktopPages = [];

    pagesDesktopOnly.forEach((page) => {
      desktopPages.push(processPage(page));
    });

    return desktopPages.concat(pages);
  }

  return pages;
};

export default listApplicationSteps;

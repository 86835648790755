import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';

import ApplicationAssistantLayout from '../../ApplicationAssistantLayout/ApplicationAssistantLayout';
import MyUtilityMainContent from './MainContent/MyUtilityMainContent';

const MyUtility = (
  {
    setDisplayPending,
    displayPending,
    language,
    currentPage,
    hasInvalidInput,
    setCurrentIncentiveByID,
    inputComponentsMustValidate,
    setInputComponentsMustValidate,
    handlePageForwardClick,
    alertAPIError
  }
) => {
  const intl = useIntl();

  return (
    <ApplicationAssistantLayout
      currentPage={currentPage}
      previousPage="/my-charger"
      nextPage="/my-ev"
      previousPageButtonText={intl.formatMessage({ id: 'back' })}
      nextPageButtonText={intl.formatMessage({ id: 'saveAndNext' })}
      setCurrentIncentiveByID={setCurrentIncentiveByID}
      handlePageForwardClick={handlePageForwardClick}
      language={language}
    >
      <MyUtilityMainContent
        language={language}
        displayPending={displayPending}
        setDisplayPending={setDisplayPending}
        hasInvalidInput={hasInvalidInput}
        inputComponentsMustValidate={inputComponentsMustValidate}
        setInputComponentsMustValidate={setInputComponentsMustValidate}
        alertAPIError={alertAPIError}
      />
    </ApplicationAssistantLayout>
  );
};

export default MyUtility;

MyUtility.propTypes = {
  language: PropTypes.string.isRequired,
  displayPending: PropTypes.bool.isRequired,
  setDisplayPending: PropTypes.func.isRequired,
  currentPage: PropTypes.string.isRequired,
  hasInvalidInput: PropTypes.func.isRequired,
  inputComponentsMustValidate: PropTypes.bool.isRequired,
  setInputComponentsMustValidate: PropTypes.func.isRequired,
  setCurrentIncentiveByID: PropTypes.func.isRequired,
  handlePageForwardClick: PropTypes.func.isRequired,
  alertAPIError: PropTypes.func.isRequired
};

const stringToBool = (input) => {
  if (!input || input === 'false') {
    return false;
  }
  return true;
};

const boolToString = (input) => {
  if(input === false) {
    return 'false';
  }
  if(input === true) {
    return 'true';
  }

  else return input;
};

export { 
  boolToString,
  stringToBool
};

import React from 'react';
import PropTypes from 'prop-types';
import { renderingInAnyMobile } from '../../../../../utils/functions/device/determinePlatform';

import TextField from '../../TextField/TextField';
import DesktopEditableDetail from '../../Dropdown/submodules/DesktopEditableDetail';
import MobileEditableDetail from '../../Dropdown/submodules/MobileEditableDetail';
import EditableDetailDropdown from './EditableDetailDropdown';

import { checkFieldNotEmpty } from '../../functions/inputHelper';
import { FormatDate } from '../../../../../utils/helpers/Format';

const EditableDetail = ({
  legend,
  fieldName,
  fieldWidth,
  propsCollection,
  updatePropsCollection,
  editMode,
  hasEditMode,
  inputComponentsMustValidate,
  hasInvalidInput,
  forceMobile,
  optionsHelper,
  dropdown,
  localStorageKey,
  setDisplayPending,
  placeholder,
  specialDataType,
  validate,
}) => {
  const dataTypeHandlers = {
    date: (data) => {
      if (!data) {
        return data;
      }
      const date = new Date(data);
      return FormatDate(date, 'mdy');
    },
  };

  const originalData = propsCollection[fieldName];

  const dataTypeHandler = dataTypeHandlers[specialDataType];

  const textToRender = dataTypeHandler ? dataTypeHandler(originalData) : originalData;

  const textField = (
    <TextField
      validate={validate}
      className="EditableDetail"
      fieldName={fieldName}
      defaultValue={placeholder}
      legend={legend}
      fieldWidth={fieldWidth}
      inputComponentsMustValidate={inputComponentsMustValidate}
      hasInvalidInput={hasInvalidInput}
      propsCollection={propsCollection}
      updatePropsCollection={updatePropsCollection}
    />
  );

  const editableDetailDropdown = (
    <EditableDetailDropdown
      optionsHelper={optionsHelper}
      fieldName={fieldName}
      propsCollection={propsCollection}
      updatePropsCollection={updatePropsCollection}
      defaultValue=""
      legend={legend}
      fieldWidth={fieldWidth}
      inputComponentsMustValidate={inputComponentsMustValidate}
      validate={checkFieldNotEmpty}
      localStorageKey={localStorageKey}
      setDisplayPending={setDisplayPending}
      placeholder={placeholder}
      hasInvalidInput={hasInvalidInput}
    />
  );

  const textDiv = <div>{textToRender}</div>;

  const inputToRender = dropdown ? editableDetailDropdown : textField;

  const nodeToRender = editMode ? hasEditMode ? inputToRender : <div /> : textDiv;

  return (
    <>
      {renderingInAnyMobile() || forceMobile ? (
        <MobileEditableDetail nodeToRender={nodeToRender} editMode={editMode} legend={legend} />
      ) : (
        <DesktopEditableDetail nodeToRender={nodeToRender} editMode={editMode} legend={legend} />
      )}
    </>
  );
};

export default EditableDetail;

EditableDetail.defaultProps = {
  forceMobile: false,
  optionsHelper: () => {
    return new Promise((resolve) => {
      resolve();
    });
  },
  dropdown: false,
  hasEditMode: false,
  localStorageKey: null,
  propsCollection: {},
  specialDataType: '',
  validate: checkFieldNotEmpty,
};

EditableDetail.propTypes = {
  fieldName: PropTypes.string.isRequired,
  propsCollection: PropTypes.object, // eslint-disable-line react/forbid-prop-types
  updatePropsCollection: PropTypes.func.isRequired,
  editMode: PropTypes.bool.isRequired,
  inputComponentsMustValidate: PropTypes.bool.isRequired,
  hasInvalidInput: PropTypes.func.isRequired,
  legend: PropTypes.string.isRequired,
  fieldWidth: PropTypes.string.isRequired,
  forceMobile: PropTypes.bool,
  optionsHelper: PropTypes.func,
  dropdown: PropTypes.bool,
  hasEditMode: PropTypes.bool,
  localStorageKey: PropTypes.string,
  setDisplayPending: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  specialDataType: PropTypes.string,
  validate: PropTypes.func,
};

const en = {
  welcome: "Welcome!",
  myProfile: "Profile",
  updateAccount: "Update My Account",
  chargerLocation: "Location",
  myCharger: "Charger",
  myUtility: "Utility",
  myElectricVehicle: "Electric Car",
  partOfHomePage: "Will become part of home page",
  contactUs: "Contact Us",
  componentZoo: "Component Zoo",
  registration: "Account successfully created",
  login: "Login",
  register: "Get Started",
  continue: "Continue",
  firstName: "First name",
  lastName: "Last name",
  address: "Address",
  mobilePhone: "Mobile phone number",
  mobilePhoneShort: "Mobile phone",
  homePhone: "Home phone number",
  homePhoneShort: "Home phone",
  householdIncome: "Household income",
  householdIncomeShort: "HH income",
  preferredEmailAddress: "Preferred email address",
  signature: "Type your name on the signature",
  zipCode: "ZIP code",
  zip: "ZIP",
  city: "City",
  state: "State",
  yes: "Yes",
  no: "No",
  back: "Back",
  next: "Next",
  saveAndNext: "Save & Next",
  homeCharger: "Home Charger",
  applicationAssistant: "Incentive Assistant",
  contactSupport: "Contact Support",
  uploadPhoto: "Upload Photo",
  takeAPhoto: "Take a Photo",
  photoReceived: "Photo Received",
  chargerBrand: "Charger brand",
  chargerModel: "Charger model",
  serialNumber: "Serial number",
  purchasePrice: "Purchase price",
  purchaseDate: "Purchase date",
  vehicleRegistrationCard: "Vehicle Registration Card",
  seeInstructions: "See Instructions",
  edit: "Edit",
  save: "Save",
  vehicleDetails: "Vehicle Details",
  ownerDetails: "Owner Details",
  vin: "Vin #",
  carMake: "Make",
  carModel: "Model",
  year: "Year",
  profileDetails: "Profile Details",
  streetAddress: "Street address",
  apartment: "Apartment",
  completeApplication: "Complete Application",
  propertyType: "Property Type",
  submitApplication: "Application Submitted",
  almostThere: "Almost There!",
  dashboard: "dashboard",

  // headlines blurbs and text should go here...
  "userProfile.headline.blurb":
    "Please provide some basic information that we will use to populate the incentive applications on your behalf.",
  "chargerLocation.headline.headline": "Location",
  "chargerLocation.headline.blurb":
    "Please provide your location so that we may populate the form based on where you live.",
  "myCharger.headline.headline": "Charger",
  "myCharger.headline.blurb":
    "Your charger’s information will help us populate the grant form and provide you with the best refund.",
  "myUtility.headline.blurb":
    "Based on your electric bill and company, you may be entitled to an additional discount.",
  "myEV.headline.blurb":
    "The type and make of your vehicle may increase your refund.",
  "myEV.vehicleDetails.blurb":
    "Your vehicle information will help us find the best grant for your type of vehicle.",
  "completeApplication.headline.headline": "Your application is almost ready",
  "completeApplication.headline.blurb":
    "Fill out the details below and you’ll be ready to submit your application.",

  "userProfile.householdMembers.tooltip":
    "Income and living information will help us best find the grant that matches your needs.",
  "userProfile.householdIncome.tooltip":
    "Income and living information will help us best find any / all tax refunds you qualify for.",
  "myCharger.purchaseReceipt.tooltip":
    "Proof of purchase is needed to provide an incentive relative to your purchase cost.",
  "myCharger.installationReceipt.tooltip":
    "Proof of installation is needed to provide an incentive relative to your install cost",
  "myCharger.installedCharger.tooltip":
    "A photo of your charger will help us confirm its exact type and model for correct categorization.",
  "myCharger.installationPermit.tooltip":
    "Proof of valid permit may be required to verify your refund eligibility. Note: only the following 10 incentives require a permit photo: {lineBreak}{lineBreak} Anaheim EV Charger Rebate {lineBreak}  Burbank Charging Station Rebate {lineBreak}  Burbank Charging Station Rebate {lineBreak} South Coast AQMD Resident EV Chjarging Incetive Pilot Program {lineBreak} Glendale EV Charger Rebate {lineBreak} Lodi EV Charger and Installation Rebate {lineBreak} Palo Alto Service Capacity Fee Rebate {lineBreak} Santa Clara EV Charger Rebate {lineBreak} Trinity EV Charger Rebate {lineBreak} Ukiah Charger Rebate {lineBreak} {lineBreak} If you are not applying for an incentive listed above, you should upload the photo of your Installed Charger a second time instead. If you do not know which incentives you are qualified for, contact veloz-iaa-support@zappy.freshdesk.com.",
  "myUtility.electricUtilityName.tooltip": "Your electric company provider.",

  "buttontext.nextpage": "Next Page",
  "buttonText.incentiveCard.reviewAndApply": "Submit",
  "buttonText.incentiveCard.applicationIncomplete": "Finish & Apply",
  "buttonText.incentiveCard.applicationSubmitted": "Submitted",
  "buttonText.incentiveCard.seeOtherIncentives": "Click Here",

  "card.seeless": ">> see less <<",
  "card.seemore": "<< see more >>",
  "card.learnmore": "Learn More",

  "register.agreeToTerms": "I agree to the terms and conditions",

  "register.registerSuccess.welcome":
    "Welcome {firstName} to the Incentive {lineBreak} Assistant",
  "register.registerSuccess.accountCreated":
    "Success! Your account has been created.",
  "register.registerSuccess.nextUp":
    "Next up, answer some key questions about your car, charger, and utility to confirm your eligibility and start the process of preparing your incentive applications.",
  "register.registerSuccess.manyIncentives":
    "There are many incentives potentially available to you.",
  "register.registerSuccess.seeAllIncentives": "See all incentives.",

  "register.partiallyCompleted.welcome": "Welcome back, {firstName}!",
  "register.partiallyCompleted.accountCreated":
    "Just a few more things we need to know!",
  "register.partiallyCompleted.nextUp":
    "Click below to tell us more about your charger, your vehicle, and yourself so we can find out how much money we can get back for you.",

  "homepage.jumbotron.title": "Welcome to the Incentive Assistant.",
  "homepage.jumbotron.subtitle":
    "The easiest way to get home charging incentives.",
  "homepage.seeHomeChargingAdvisor":
    "If you haven’t purchased a Level 2 home charger, {br} please visit the <link>Home Charging Advisor</link> {br}to search chargers and incentives.",
  "homepage.firstTime": "First time?",
  "homepage.letsFindOut": "See if incentives are {br} available in your area",
  "homepage.zipcodeLabel": "Your ZIP Code",
  "homepage.check": "Check",
  "homepage.checking": "Checking...",
  "homepage.zipcodeError":
    "Sorry! We encountered an error. Please try again later.",
  "homepage.invalidZip": "Please enter a valid ZIP code.",
  "homepage.noIncentivesAvailable":
    "Thanks for checking. We are a new tool, so your incentives are not included yet but should be added soon. Please click <link>here</link> to return to the Home Charging Advisor to learn about incentives and how to apply.",
  "homepage.canHelpYouGet":
    "The incentive assistant can help you get {amount} {incentivePluralized}:",
  "homepage.incentiveTitle": "{name} from {grantor}",
  "homepage.getStarted": "Get Started",
  "homepage.returning": "Returning?",
  "homepage.jumpBackIn": "Jump right back in.",
  "homepage.login": "Login",
  "homepage.providedByZappyRide":
    "This tool is provided to you by Veloz in partnership with ZappyRide. <link>Contact ZappyRide</link> with any questions.",
  "homepage.getYourRefundHeader":
    "Apply for your home charger incentives with the Electric For All Incentive Assistant",
  "homepage.applyingPara":
    "After you purchase and install your home charger, enter your information into the Incentive Assistant and we’ll do what it takes to get you your incentive check.",
  "homepage.applyingParaNote":
    "Users must have purchased a home charger and be a customer of Anaheim Public Utilities, Burbank Water & Power, or Colton Electric to use this tool.  ",
  "homepage.usersPurchase": " ",
  "homepage.moreGeographies":
    "More utilities and programs will be added soon. Please check back or reach out to veloz-iaa-support@zappy.freshdesk.com with any questions.",
  "homepage.howItWorksHeader": "How it works",
  "homepage.createASecureAccount": "Create a Secure Account",

  // @TODO need to get more copy on this from Jaime
  "homepage.encryptionWeUse": "We make sure your information is always safe.",

  "homepage.submitYourDocuments": "Submit Your Documents",
  "homepage.sendInstallationDetails":
    "Submit photos of your charger installation, receipt, and permits.",
  "homepage.getYourRefund": "Get Your Refund",
  "homepage.trackApplicationProgress":
    "Track your application progress along the way.",
  "homepage.buyACharger": "Buy A Charger",

  // @TODO need new text for this
  "homepage.buyChargerBlurbText":
    "Visit the Electric For All <link>Home Charging Advisor</link> to find the home charger that is right for you.",

  "homepage.installYourCharger": "Install Your Charger",
  "homepage.hireElectrician":
    "Hire a certified electrician to install the charger in your home.",
  "homepage.whatToDoHeader": "What to do before you apply",
  "homepage.takeSomePictures": "Take Some Pictures",
  "homepage.registrationPhoto": "Photo of EV registration",
  "homepage.chargerInvoicePhoto": "Photo of charger invoice",
  "homepage.chargerInstallationReceipt": "Photo of installation receipt",
  "homepage.installedChargerPhoto": "Photo of installed charger",
  "homepage.installPermitPhoto": "Photo of installation permit",
  "homepage.utilityBillPhoto": "Photo of electric utility bill",

  "incentiveCard.incentiveStatus.applied": "Applied",
  "incentiveCard.incentiveStatus.paid": "Paid",
  "incentiveCard.incentiveStatus.declined": "Declined",
  "incentiveCard.contactGrantor": "Contact Support",

  "userProfile.firstName": "Enter first name",
  "userProfile.lastName": "Enter last name",
  "userProfile.mobilePhone": "Enter mobile phone number",
  "userProfile.homePhone": "Enter home phone number",
  "userProfile.householdSize": "Household members",
  "userProfile.householdSizeShort": "HH members",
  "userProfile.selectIncomeRange": "Select income range",
  "userProfile.emailAddress": "Email address",
  "userProfile.signature": "Type name",
  "userProfile.signature.legalese":
    "Your signature will be used to sign the application. You will be able to review it before submitting. Filling in the following information will constitute your eSignature and will have the same legal impact as signing a printed version of the application.",
  "userProfile.signature.yourSignature": "Signature",
  "userProfile.signature.selectFont": "Select a font for your signature",

  "chargerLocation.homeStreetAddress.legend": "Home address",
  "chargerLocation.homeStreetAddress.placeholder": "Enter a home address",
  "chargerLocation.chargerStreetAddress.legend": "Charger’s address",
  "chargerLocation.chargerStreetAddress.placeholder": "Enter charger address",
  "chargerLocation.chargerAptAbbrev": " Apt. ",

  "chargerLocation.zipappt.legend": "Apt/Unit #",
  "chargerLocation.zipappt.placeholder": "Unit #",
  "chargerLocation.propertyOwner": "Are you the owner of this property?",
  "chargerLocation.chargerAtProperty":
    "Is this where the charger will be located?",

  "photoprocessor.charger.receipt": "Charger Purchase Receipt",
  "photoprocessor.installCharger.receipt": "Charger Installation Receipt",
  "photoprocessor.installedCharger": "Installed Charger",
  "photoprocessor.installationPermit": "Installation Permit",
  "photoprocessor.invoice": "Installation Invoice",
  "photoprocessor.uploadPhoto": "Upload a photo of",
  "photoprocessor.takeAPhotoOfThe": "Take a photo of",
  "ocrphotoprocessor.desktopButtonText": "View & Replace Photo",
  "ocrphotoprocessor.mobileButtonText": "Take a Photo",

  "myCharger.selectChargerBrand": "Select a charger brand",
  "myCharger.selectChargerModel": "Select a charger model",

  "myUtility.accountID": "Electric Utility Account #",
  "myUtility.providerName": "Electric Utility Name",
  "myUtility.accountIDShort": "Account #",
  "myUtility.providerNameShort": "Utility name",

  "dashboard.subdrawers.drawer1": "Profile",
  "dashboard.subdrawers.drawer2": "Location",
  "dashboard.subdrawers.drawer3": "Charger",
  "dashboard.subdrawers.drawer4": "Utility",
  "dashboard.subdrawers.drawer5": "Electric Car",

  "dashboard.incentivesWhere.desktop": "below",
  "dashboard.incentivesWhere.tablet": "below",

  "dashboard.paragraphs.1":
    "Congratulations! Your available incentives are listed",
  "dashboard.paragraphs.2.1":
    "Please note that some incentives may require you to provide a little more information. These are designated with yellow buttons labeled",
  "dashboard.paragraphs.2.2":
    "Please proceed and follow the instructions to submit those.",
  "dashboard.paragraphs.3":
    "For the applications that we are able to fully complete on your behalf, please confirm your information and submit those applications by clicking or tapping on the green button(s) labeled",
  "dashboard.paragraphs.4": " to see other incentives in your area.",

  "noIncentivesAvaiable.email": "Email me at {email}",
  "noIncentivesAvaiable.text": "Text me at {phone}",
  noIncentivesAvailable: "No Incentives Available",

  "submitApplication.title":
    "Congratulations, you’ve successfully submitted your application.",
  "submitApplication.paragraphs.1":
    "Your application has been submitted. Timeline depends on the grantor. However, feel free to contact us if you have questions at ",

  "contactUs.title": "Contact Support",
  "contactUs.heading": "You have questions? We have answers!",
  "contactUs.bodyText":
    "Send us an email and one of our team{br}members will respond within 48 hours",
  "contactUs.whoWeAre":
    "The Electric For All Incentive Assistant, powered by Veloz, is administered by our technology partner ZappyRide.  Veloz is a nonprofit that engages its powerhouse of members, public-private partnerships, programs, policy engagement, and public awareness campaigns to overcome barriers to electrification and create a virtuous cycle of desire and demand. With more affordable makes and models and refueling stations, Veloz aims to make electric for all a reality.",
  "contactUs.disclaimer":
    "Veloz does not support or promote any particular car manufacturer or electric vehicle charger company and nothing on this website is intended as an endorsement. Please consult our <link>privacy policy</link>.",

  "seoPageTitle.homepage": "Incentive Assistant",
  "seoPageTitle.dashboard": "Dashboard",
  "seoPageTitle.welcome": "Welcome to Incentive Assistant",
  "seoPageTitle.profile": "Profile",
  "seoPageTitle.updateAccount": "Update Account",
  "seoPageTitle.chargerLocation": "Location",
  "seoPageTitle.myCharger": "Charger",
  "seoPageTitle.myUtility": "Utility",
  "seoPageTitle.myElectricVehicle": "Electric Car",
  "seoPageTitle.myDashboard": "My Dashboard",
  "seoPageTitle.contactUs": "Contact Us",
  "seoPageTitle.componentCatalog": "Component Catalog",
  "seoPageTitle.login": "Login",
  "seoPageTitle.registration": "Incentive Assistant",
  "seoPageTitle.completeApplication": "Complete Application",
  "seoPageTitle.submitApplication": "Submit Application",
  "seoPageTitle.threeSteps": "Homepage",
  "seoPageTitle.noIncentivesAvailable": "No Incentives Available",
  "seoPageTitle.saveAndReturn": "Save and Return",
  "seoPageTitle.submitAndReturn": "Submit and Return",

  "sessionTimeoutModal.header": "Session has Timed Out",
  "sessionTimeoutModal.para1":
    "We’re sorry... your session appears to have timed out after an hour of inactivity.",
  "sessionTimeoutModal.para2":
    "Please click or tap the button below to sign back in.",
  "sessionTimeoutModal.buttonText": "Sign in again",

  "congratsModal.congrats":
    "Congratulations, you’ve successfully submitted your application.",
  "congratsModal.message":
    "Refund processing time depends on the grantor. If you have questions while you wait, please contact",
};

export default en;

import React from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';

import Uuid from '../../../../utils/functions/misc/Uuid';

import checkCircle from '../../../../customizations/assets/images/checkCircle.svg';

import './css/ChecklistUL.scss';

const ChecklistUL = ({ listItems }) => {
  const ulStyle = { listStyleImage: `url(${checkCircle})` };

  return (
    <ul
      className="checklistUL"
      style={ulStyle}
    >
      {
        listItems.map((listItem) => (
          <li
            key={Uuid()}
          >
            <FormattedMessage
              id={listItem}
            />
          </li>
        ))
      }
    </ul>
  );
};

export default ChecklistUL;

ChecklistUL.propTypes = {
  listItems: PropTypes.arrayOf(PropTypes.string).isRequired,
};

import contactEndpoint from "../../../apiInterface/contactEndpoint/contactUnifiedEndpoint";

import logger from '../../functions/logging/logger';

const maybeCheckIncentives = (iaaUser, alertAPIError) => {
  return new Promise((resolve, reject) => {
    iaaUser.UserObject.queriedIncentives = true;
    getUnifiedIncentives(iaaUser.UserObject.primaryAddress.postcode, alertAPIError)
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });

  });
};

const getUnifiedIncentives = (postcode, alertAPIError) => {
  return new Promise((resolve, reject) => {
    contactEndpoint(
      'unified',
      '/unsafe/incentives',
      'GET',
      {
        postcode
      },
      null,
      alertAPIError
    )
      .then((response) => {
        const appliableIncentives = response.incentives.filter((incentive) => {
          return incentive.can_be_applied_to;
        });

        logger(appliableIncentives, 'checkIncentives');
        resolve(appliableIncentives);
      })
      .catch((err) => {
        reject(err);
      });
  });
    
};

export {
  maybeCheckIncentives,
  getUnifiedIncentives
};

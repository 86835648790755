import React from 'react';
import PropTypes from 'prop-types';

import IncentiveCardButton from './IncentiveCardButton';
import IncentiveDetailsLink from './IncentiveDetailsLink';

const CardBottom = (
  {
    cardBottomType,
    language,
    incentiveID,
    onClick,
    incentiveDetailsURL
  },
) => {
  const cardBottomContent = {
    available: (
      <IncentiveCardButton
        onClick={onClick}
        cardBottomType={cardBottomType}
        language={language}
        incentiveID={incentiveID}
      />
    ),
    incomplete: (
      <IncentiveCardButton
        onClick={onClick}
        cardBottomType={cardBottomType}
        language={language}
        incentiveID={incentiveID}
      />
    ),
    applied: (
      <IncentiveCardButton
        onClick={()=>{}}
        cardBottomType={cardBottomType}
        language={language}
        incentiveID={incentiveID}
      />
    ),
    incompleteData: (
      <div/>
    )
  };

  return (
    <div className="CardBottom">
      { cardBottomContent[cardBottomType]}
      <IncentiveDetailsLink
        language={language}
        incentiveDetailsURL={incentiveDetailsURL}
        cardBottomType={cardBottomType}
      />
    </div>
  );
};

export default CardBottom;

CardBottom.defaultProps = {
  incentiveDetailsURL: '',
  cardBottomType: null
};

CardBottom.propTypes = {
  cardBottomType: PropTypes.string,
  language: PropTypes.string.isRequired,
  incentiveID: PropTypes.number.isRequired,
  onClick: PropTypes.func.isRequired,
  incentiveDetailsURL: PropTypes.string
};

const intToString = (i) => {
  if(isNaN(i)) {
    return i;
  }

  return `${i}`;
};

const stringToInt = (s) => {
  if(isNaN(s)) {
    return s;
  }

  return parseInt(s, 10);
};

export {
  intToString,
  stringToInt
};

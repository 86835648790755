import React from 'react';

import ToolTip from '../../../components/Atoms/ToolTip/ToolTip';

const maybeRenderTooltip = (tooltip, id, img = null) => (
  (tooltip && !process.env.JEST_WORKER_ID)
    ? (
      <ToolTip
        message={tooltip}
        id={`${id}_tooltip`}
        img={img}
      />
    )
    : (
      <div
        className="tooltip-placeholder"
      >
      </div>
    )
);

export default maybeRenderTooltip;

const getUtility = (address) => address.power_supplier ? address.power_supplier : { name: "", account_number: "" };

const updateUtility = (updater, address, newUtils) => {
  const newAddress = { ...address };
  const powerSupplier = newAddress.power_supplier;

  const keys = Object.keys(newUtils);

  keys.forEach((key) => {
    if(newUtils[key]) {
      powerSupplier[key] = newUtils[key];
    }
  });

  updater(newAddress);
};

export {
  getUtility,
  updateUtility,
};

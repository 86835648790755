import React from 'react';
import PropTypes from 'prop-types';

import DashboardProgressNavigator from '../../../ApplicationAssistantLayout/DashboardProgressNavigator/DashboardProgressNavigator';
import TabletDashboardContent from './TabletDashboard';
import { getTranslatedMessageFromID } from '../../../../utils/functions/intl/intlWrapper';

import { renderingInTablet } from '../../../../utils/functions/device/determinePlatform';

const MobileDashboardContent = ({
  language,
  setCurrentIncentiveByID,
}) => {

  const seeOtherIncentivesText = (
    <>
      <p style={{ textAlign: 'center' }}>
        <a href="https://homecharging.electricforall.org" rel="noreferrer" target="_blank">
          <b>{getTranslatedMessageFromID('buttonText.incentiveCard.seeOtherIncentives', language)}</b></a>{getTranslatedMessageFromID('dashboard.paragraphs.4', language)}.</p>
    </>
  );
  const smartphoneDPN = (
    <>
      <DashboardProgressNavigator
        language={language}
        setCurrentIncentiveByID={setCurrentIncentiveByID}
      />
      <div className="ready-submit-text">{seeOtherIncentivesText}</div>
    </>
  );

  const tabletContent = (
    <TabletDashboardContent
      language={language}
      setCurrentIncentiveByID={setCurrentIncentiveByID}
    />
  );

  return (
    <>
      {
        renderingInTablet() 
          ? tabletContent
          : smartphoneDPN
      }
    </>
  );
};

export default MobileDashboardContent;

MobileDashboardContent.propTypes = {
  language: PropTypes.string.isRequired,
  setCurrentIncentiveByID: PropTypes.func.isRequired,
};

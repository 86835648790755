import React from 'react';
import PropTypes from 'prop-types';

import Dropdown from '../../Atoms/Inputs/Dropdown/Dropdown';
import powerSuppliers from '../data/powerSuppliers';

import '../../Atoms/Inputs/Dropdown/css/Dropdown.scss';
import '../../Atoms/Inputs/css/input-1.scss';

// @TODO will need to know how utils can be accessed from unified API
// and what kind of value the backend is going to expect
const ExampleDropdown = (
  {
    inputComponentsMustValidate,
    hasInvalidInput,
    user,
    updateUser,
  },
) => {
  // just needs to be called when trying to move out of the page
  const validateFunc = (util) => {
    if (!util) {
      throw new Error('Utility is a required field');
    }
  };

  // Example of power suppliers api call: https://api.production.zappyride.com/powersuppliers?postcode=10023
  // Are there any cases where more than one will come up?
  // Must include zipcode or latlong - so we will need to pass it a zipcode first.
  // Handles any nearby zipcodes?

  return (
    <Dropdown
      fieldName="util"
      validate={validateFunc}
      propsCollection={user}
      updatePropsCollection={updateUser}
      options={powerSuppliers}
      placeholder="Select Your Utility Company"
      legend="Electric Utility Name"
      inputComponentsMustValidate={inputComponentsMustValidate}
      hasInvalidInput={hasInvalidInput}
    />
  );
};

export default ExampleDropdown;

ExampleDropdown.propTypes = {
  inputComponentsMustValidate: PropTypes.bool.isRequired,
  hasInvalidInput: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  updateUser: PropTypes.func.isRequired,
};

import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { IaaUserEntity } from './components/IaaUserEntity/IaaUserEntity';
import { Equipment } from './components/Equipment/Equipment';
import Routing from './components/Nav/Routing';

import GaTracker from './utils/GaTracker/GaTracker';
import logger from './utils/functions/logging/logger';

import 'bootstrap/dist/css/bootstrap.css';
import './css/master.scss';

const Root = ({ language, changeLanguage, honeybadger }) => {
  const findPath = (properties) => (properties && properties.match ? properties.match.path : null);

  const [displayPending, setDisplayPending] = useState(false);

  const [requiredCheckboxes, setRequiredCheckboxes] = useState({
    agreeToTerms: false,
  });

  // Setup GA

  useEffect(() => {
    GaTracker.initialize();
    const page = window.location.pathname + window.location.search;
    GaTracker.trackPage(page);
  }, []);

  // Used to alert input components that they need to validate.
  // Set to true when changing page, false when update has been submitted (useEffect hook?)
  const [inputComponentsMustValidate, setInputComponentsMustValidate] = useState(false);

  const [hasInvalidInput, setHasInvalidInput] = useState(() => {
    return (fieldName, isInvalid) => {
      logger(fieldName, 'Root');
      logger(isInvalid, 'Root');
    };
  });

  return (
    <>
      <IaaUserEntity>
        <Equipment>
          <Routing
            findPath={findPath}
            language={language}
            changeLanguage={changeLanguage}
            displayPending={displayPending}
            setDisplayPending={setDisplayPending}
            inputComponentsMustValidate={inputComponentsMustValidate}
            setInputComponentsMustValidate={setInputComponentsMustValidate}
            hasInvalidInput={hasInvalidInput}
            setHasInvalidInput={setHasInvalidInput}
            requiredCheckboxes={requiredCheckboxes}
            setRequiredCheckboxes={setRequiredCheckboxes}
            honeybadger={honeybadger}
          />
        </Equipment>
      </IaaUserEntity>
    </>
  );
};

export default withRouter(Root);

Root.defaultProps = {
  language: 'EN',
  changeLanguage: () => {},
};

Root.propTypes = {
  language: PropTypes.string,
  changeLanguage: PropTypes.func,
  honeybadger: PropTypes.any.isRequired,
};

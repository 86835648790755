import React from 'react';
import PropTypes from 'prop-types';

import './css/Homepage.scss';

const Jumbotron = () => {
  return (
    <section className="container-fluid Jumbotron jumbotron">
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="text-center">
              <p style={{ fontSize:"20px" }}>
                     The Incentive Assistant has been retired as of April 2023. Please consult <a href="https://homecharging.electricforall.org/">homecharging.electricforall.org</a> to compare home chargers and view available incentives. Best of luck on your EV journey!
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

const Homepage = () => {
  return (
    <div className="Homepage">
      <Jumbotron />
    </div>
  );
};

export default Homepage;

Homepage.defaultProps = {
  userLoggedIn: false,
  setUserLoggedIn: () => {},
  user: {},
  updateUser: () => {},
};

// @TODO consider whether everything here really needs to be required....
Homepage.propTypes = {
  language: PropTypes.string.isRequired,
};

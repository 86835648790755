import React from 'react';
import PropTypes from 'prop-types';

import Chevron from './Chevron';

import selectString from '../../../../utils/functions/misc/selectString';

const ItemCounter = (
  {
    numberOfItems,
    isExpanded,
  },
) => {
  const countableItems = numberOfItems > -1;

  return (
    <div
      className={selectString(
        countableItems,
        'DashboardNavCounter',
        'DashboardNavChevron',
      )}
    >
      {
        countableItems
          ? numberOfItems
          : (
            <Chevron
              isExpanded={isExpanded}
            />
          )
      }
    </div>
  );
};

export default ItemCounter;

ItemCounter.propTypes = {
  numberOfItems: PropTypes.number.isRequired,
  isExpanded: PropTypes.bool.isRequired,
};

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import SelectSearch from 'react-select-search';
import RequiredFieldIndicator from '../RequiredFieldIndicator/RequiredFieldIndicator';

import { checkForValidation, checkFieldNotEmpty } from '../functions/inputHelper';
import { intToString } from '../../../../utils/functions/misc/convertStringInt';
import maybeRenderTooltip from '../../../../utils/functions/componentRenderLogic/maybeRenderTooltip';
import Uuid from '../../../../utils/functions/misc/Uuid';


// causing issues with jest. Need to specify transformIgnorePatterns in config

// @TODO move inputLegend to new component or is this overkill?
const Dropdown = (
  {
    fieldName,
    propsCollection,
    updatePropsCollection,
    validate,
    legend,
    className,
    placeholder,
    options,
    hasSearch = true,
    inputComponentsMustValidate,
    hasInvalidInput,
    tooltip,
    ...rest
  },
) => {
  const [warning, setWarning] = useState('');

  const validateFunc = validate || checkFieldNotEmpty;

  // @TODO would it make sense to move all of this out?
  // Perhaps not because we would need to pass along almost everything as args.
  // May work to build a wrapper around input components...
  useEffect(() => {
    const validateOnCheck = () => {


      if (inputComponentsMustValidate) {

        checkForValidation(
          validateFunc,
          propsCollection[fieldName],
          '',
          setWarning,
          fieldName,
          hasInvalidInput,
        );
      }
    };

    validateOnCheck();
  });

  const ttID = `a_${Uuid()}`;

  return (
    <div className={`${className} IAAInput`}>
      <div className="inputLegend" id={ttID}>
        {legend}
        <RequiredFieldIndicator
          fieldName={fieldName}
        />
        {maybeRenderTooltip(tooltip, ttID)}
      </div>
      <SelectSearch
        options={options}
        value={intToString(propsCollection[fieldName])}
        name={`select_${fieldName}`}
        placeholder={placeholder}
        onChange={(newValue) => {
          updatePropsCollection(newValue, fieldName);;
        }}
        search={hasSearch}
        {...rest}
      />
      <div className="warning">{warning}</div>
    </div>
  );
};

export default Dropdown;

Dropdown.defaultProps = {
  className: '',
  legend: '',
  options: [],
  placeholder: '',
  validate: null,
  hasSearch: true,
};

Dropdown.propTypes = {
  className: PropTypes.string,
  fieldName: PropTypes.string.isRequired,
  legend: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.string)),
  hasSearch: PropTypes.bool,
  placeholder: PropTypes.string,
  validate: PropTypes.func,
  tooltip: PropTypes.string,
  inputComponentsMustValidate: PropTypes.bool.isRequired,
  hasInvalidInput: PropTypes.func.isRequired,
  propsCollection: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  updatePropsCollection: PropTypes.func.isRequired,
};

import { AsYouType } from 'libphonenumber-js';

const FormatAsDollars = (val) => (
  `$${
    Math.round(val)
      .toString()
      .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`
);
const FormatAsHundredsOfDollars = (val) => (
  `~$${
    (Math.round(val / 100) * 100)
      .toString()
      .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`
);

const FormatAsCents = (val) => (
  `$${
    parseFloat(val)
      .toFixed(2)
      .toString()}`
);

const FormatAsTwoDecimals = (val) => parseFloat(val)
  .toFixed(2)
  .toString();

const FormatAsCentsDetailed = (val) => (
  `$${
    parseFloat(val)
      .toFixed(4)
      .toString()}`
);

const FormatAsPercent = (val) => `${parseFloat(val).toFixed(2)}%`;

const FormatAsPercentRounded = (val) => `${parseFloat(val).toFixed(0)}%`;

const FormatAsInt = (val) => parseFloat(val).toFixed(0);

const FormatAsThousands = (val) => Math.round(val)
  .toString()
  .replace(/\B(?=(\d{3})+(?!\d))/g, ',');

const FormatAsTime = (time) => {
  const hh = Math.floor(time);
  const mm = Math.round((time - hh) * 12) * 5;
  const renderHours = hh === 0 ? '' : `${hh} hr`;
  const renderMinutes = mm === 0 ? '' : ` ${mm} min`;
  return renderHours + renderMinutes;
};

const FormatCarName = (car) => {
  const carTrim = car.trim ? car.trim : '';
  return car
    ? (`${car.make} ${car.model} ${carTrim}`).toString().trim()
    : null;
};

const FormatCarModelAndTrim = (car) => {
  const carTrim = car.trim ? car.trim : '';
  return car
    ? (`${car.model} ${carTrim}`).toString().trim()
    : null;
};

const FormatInThousands = (val) => `$${Math.round(val / 1000)}k`;

const FormatUSPhoneNumber = (val) => new AsYouType('US').input(val);

const FormatDate = (date, format='ymd') => {
  const year = date.getUTCFullYear();
  let month = `${date.getUTCMonth() + 1}`;
  let day = `${date.getUTCDate()}`;

  if(month.length < 2) {
    month = `0${month}`;
  }

  if(day.length < 2) {
    day =`0${day}`;
  }

  const dateFormats = {
    ymd: `${year}-${month}-${day}`,
    mdy: `${month}-${day}-${year}`
  };

  return dateFormats[format];
};

export {
  FormatAsDollars,
  FormatAsHundredsOfDollars,
  FormatAsCents,
  FormatAsTwoDecimals,
  FormatAsCentsDetailed,
  FormatAsPercent,
  FormatAsPercentRounded,
  FormatAsInt,
  FormatAsThousands,
  FormatAsTime,
  FormatCarName,
  FormatCarModelAndTrim,
  FormatInThousands,
  FormatUSPhoneNumber,
  FormatDate
};

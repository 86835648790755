import React from 'react';
import PropTypes from 'prop-types';

const CardTitle = (
  { title },
) => {

  return (
    <div className="CardTitle">{title}</div>
  );};

export default CardTitle;

CardTitle.defaultProps = {
  title: '',
};

CardTitle.propTypes = {
  title: PropTypes.string,
};

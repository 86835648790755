import processUserProfileData from './processUserProfileData';
import processUserAddressData from './processUserAddressData';
import processVehicleAndChargerData from './processVehicleAndChargerData';
import { getIncentives } from './loadToGlobalState';

const processFullDataRefresh = (
  json,
  dispatch, 
  language
) => {
  const userProfileDataFromAPI = (json && json.profile) || {};
  const equipmentDataFromAPI = (json && json.equipment) || [];

  if(json.incentive && json.incentive.length > 0) {
    getIncentives(dispatch)(json.incentive);
  }

  processUserProfileData(userProfileDataFromAPI, dispatch);
  processUserAddressData(json, dispatch, language);
  processVehicleAndChargerData(equipmentDataFromAPI, dispatch);
};

export default processFullDataRefresh;

import localStorageKeyring from '../../../constants/LOCAL_STORAGE_KEYS';

// @TODO find a way to run unit tests on this
class LocalStorage {
  static getLocalDatapoint(key) {
    try {
      // NOTE: localStorage storage is scoped to domain + protocol
      const serializedState = window.localStorage.getItem(key);
      if (serializedState === null) return undefined;
      return JSON.parse(serializedState);
    } catch (err) {
      return undefined;
    }
  }

  static setLocalDatapoint(key, value) {
    try {
      const serializedState = JSON.stringify(value);
      window.localStorage.setItem(key, serializedState);
    } catch (err) {
      // Not sure if there's anything I can do about this.
    }
  }

  static deleteLocalDatapoint(key) {
    window.localStorage.removeItem(key);
  }

  static clear() {
    window.localStorage.clear();
  }

  static clearUserData() {
    const nonUserDataKeys = [
      "homepageZipcode",
      "iaVersion",
    ];
    Object.keys(localStorageKeyring).forEach(key => {
      if (!nonUserDataKeys.includes(key)) {
        this.deleteLocalDatapoint(localStorageKeyring[key]);
      };
    });
  }
}

export default LocalStorage;

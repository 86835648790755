import React from 'react';
import PropTypes from 'prop-types';

import { renderingInTablet } from '../../../../utils/functions/device/determinePlatform';

import papers from '../../../../customizations/assets/images/papers.svg';
import { getTranslatedMessageFromID } from '../../../../utils/functions/intl/intlWrapper';

import '../css/DesktopDashboard.scss';
import TabletDashboardNav from './TabletDashboardNav';

const DesktopDashboardContent = (
  {
    language,
    setCurrentIncentiveByID
  }
) => {

  const incentivesWhere = renderingInTablet()
    ? getTranslatedMessageFromID('dashboard.incentivesWhere.tablet', language)
    : getTranslatedMessageFromID('dashboard.incentivesWhere.desktop', language);

  const readySubmitText = (
    <>
      <p>{ `${getTranslatedMessageFromID('dashboard.paragraphs.1', language)} ${incentivesWhere}.` }</p> 
    
      <p>{getTranslatedMessageFromID('dashboard.paragraphs.2.1', language)} <b>{getTranslatedMessageFromID('buttonText.incentiveCard.applicationIncomplete', language)}</b>. 
        {' '}{getTranslatedMessageFromID('dashboard.paragraphs.2.2', language)} </p> 
    
      <p>{getTranslatedMessageFromID('dashboard.paragraphs.3', language)} <b>{getTranslatedMessageFromID('buttonText.incentiveCard.reviewAndApply', language)}</b>.</p>
    </>
  );

  const seeOtherIncentives = (      
    <p style={{ textAlign: 'center' }}>
      <a href="https://homecharging.electricforall.org" rel="noreferrer" target="_blank">
        <b>{getTranslatedMessageFromID('buttonText.incentiveCard.seeOtherIncentives', language)}</b>
      </a>{getTranslatedMessageFromID('dashboard.paragraphs.4', language)}.
    </p>
  );
  
  return (
    <div className="DesktopDashboard">
      <div className="papers">
        <img
          src={papers}
          alt=""
        />
      </div>
      <h1>Almost There!</h1>
      <div className="ready-submit-text">{readySubmitText}</div>
      <TabletDashboardNav 
        language={language}
        setCurrentIncentiveByID={setCurrentIncentiveByID}        
      />
      {seeOtherIncentives}
    </div>

  );};

export default DesktopDashboardContent;

DesktopDashboardContent.propTypes = {
  language: PropTypes.string.isRequired,
  setCurrentIncentiveByID: PropTypes.func.isRequired
};

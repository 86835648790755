/* eslint-disable jsx-a11y/label-has-associated-control */

import React from 'react';
import PropTypes from 'prop-types';

import Uuid from '../../../../utils/functions/misc/Uuid';
import { stringToBool } from '../../../../utils/functions/misc/stringToBool';

import './css/radio-button-style.scss';

const RadioButtons = (
  {
    propsCollection,
    updatePropsCollection,
    fieldName,
    defaultValue,
    legend,
    choices,
    className,
  },
) => {
  const additionalClass = className
    ? ` ${className}`
    : '';


  const checkShouldBeChecked = (val) => {
    const compareVal = propsCollection[fieldName] || defaultValue;

    const shouldCheck = stringToBool(compareVal) === stringToBool(val);

    return shouldCheck;
  };

  const buttonID = (choiceName) => `${fieldName}_${choiceName}`;

  return (
    <div className={`IAAInput IAARadio${additionalClass}`}>
      <div className="inputLegend">
        {legend}
      </div>
      <div className="row double-field-row">
        {
          choices.map((choice) => (
            <div
              className="col-4 double-field-left"
              key={Uuid()}
            >
              <input
                type="radio"
                name={fieldName}
                value={choice.value}
                checked={checkShouldBeChecked(choice.value)}
                id={buttonID(choice.value)}
                onChange={(event) => {
                  updatePropsCollection(event.target.value, fieldName);
                }}
              />
              <label
                htmlFor={buttonID(choice.value)}
              >
                {choice.name}
              </label>
            </div>
          ))
        }
      </div>
    </div>
  );
};

export default RadioButtons;

RadioButtons.defaultProps = {
  className: '',
  legend: '',

};

RadioButtons.propTypes = {
  choices: PropTypes.array.isRequired, // eslint-disable-line react/forbid-prop-types
  className: PropTypes.string,
  fieldName: PropTypes.string.isRequired,
  defaultValue: PropTypes.string.isRequired,
  legend: PropTypes.string,
  propsCollection: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  updatePropsCollection: PropTypes.func.isRequired,
};

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { updateRootState, checkForValidation, checkFieldNotEmpty } from '../functions/inputHelper';

const IAACheckbox = (
  {
    fieldName,
    propsCollection,
    updatePropsCollection,
    validate,
    legend,
    className,
    inputComponentsMustValidate,
    hasInvalidInput,
  },
) => {
  const [warning, setWarning] = useState('');

  const validateFunc = validate || checkFieldNotEmpty;

  const checkboxID = `checkbox_${fieldName}`;

  useEffect(() => {
    const validateOnCheck = () => {
      if (inputComponentsMustValidate) {
        checkForValidation(
          validateFunc,
          propsCollection[fieldName],
          null,
          setWarning,
          fieldName,
          hasInvalidInput,
        );
      }
    };

    validateOnCheck();
  });

  // @TODO should at least be able to get warning div out of these components... perhaps if
  // it becomes more complex.
  return (
    <div className={`IAAInput ${className}`}>
      <div className="row">
        <div className="checkboxContainer">
          <input
            id={checkboxID}
            className="IAACheckbox"
            type="checkbox"
            checked={propsCollection[fieldName]}
            onChange={() => {
              updateRootState({
                target: {
                  value: !propsCollection[fieldName],
                },
              }, fieldName, propsCollection, updatePropsCollection);
            }}
          />
        </div>
        <div className="legendContainer">
          <label
            htmlFor={checkboxID}
            className="IaaCheckboxLabel inputLegend"
          >
            {legend}
          </label>
        </div>
        <div className="warning">{warning}</div>
      </div>
    </div>
  );
};

export default IAACheckbox;

IAACheckbox.defaultProps = {
  className: '',
  legend: '',
  validate: null,
};

IAACheckbox.propTypes = {
  className: PropTypes.string,
  fieldName: PropTypes.string.isRequired,
  legend: PropTypes.string,
  validate: PropTypes.func,
  inputComponentsMustValidate: PropTypes.bool.isRequired,
  hasInvalidInput: PropTypes.func.isRequired,
  propsCollection: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  updatePropsCollection: PropTypes.func.isRequired,
};

const getElementDims = (element) => {
  return [
    element.clientWidth,
    element.clientHeight
  ];    
};

const getViewportDims = () => {
  return getElementDims(document);
};

const checkThresholdSize = (platform) => {
  const platformSizes = {
    'smartphone': 768,
    'tablet': 992
  };

  const vw = getViewportDims()[0];

  return vw < platformSizes[platform];
};

export {
  getElementDims,
  getViewportDims, 
  checkThresholdSize 
};

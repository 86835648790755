import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import IAAProgressBar from '../../Atoms/ProgressBars/IAAProgressBar';
import ApplicationProgressMonitor from '../../../utils/helpers/ApplicationProgressMonitor/ApplicationProgressMonitor';

// @TODO break out into subcomponents.
// @TODO is there a way to get color out of css and into js for use with the progress bar?
// @TODO Spanish translations
// @TODO if multiple chargers ChargerName is a dropdown (for next iteration?)

const DashboardChargerHead = (
  {
    equipment,
    user,
    currentAddress,
    vehicle
  },
) => {

  const fractionCompleteFunction = ApplicationProgressMonitor
    .getCurrentProgress
    .bind(ApplicationProgressMonitor);

  const [fractionComplete, setFractionComplete] = useState(fractionCompleteFunction());
  //const roundedFractionComplete = roundToNearest(fractionComplete, 2);

  useEffect(() => {
    ApplicationProgressMonitor.updateCurrentProgress(
      {
        user,
        equipment,
        vehicle,
        currentAddress
      }
    );

    setFractionComplete(fractionCompleteFunction());
  },
  [
    setFractionComplete,
    fractionCompleteFunction,
    user,
    equipment,
    vehicle,
    currentAddress
  ]);
  
  return(
    <div
      className="DashboardChargerHead"
    >
      <h2
        className="ChargerName"
      >
        {`${equipment.make} ${equipment.model || ""}  `}
      </h2>
      <div className="ChargerOwnerLocation">
        <div
          className="ChargerOwner row"
        >
          <div className="col-4">Owner</div>
          <div className="col-8 text-right">
            {`${user.first_name} ${user.last_name}`}
          </div>
        </div>
        <div
          className="ChargerAddress row"
        >
          <div className="col-4">Location</div>
          <div className="col-8 text-right">
            {currentAddress.street_address}
          </div>
        </div>
      </div>
      <div
        className="ChargerApplicationProgressBar"
      >
        <IAAProgressBar
          fractionComplete={fractionComplete}
          barColor="#ff8364"
          barBGColor="#472f2a"
          height="4px"
          width="100%"
        />
        <div className="ProgressBarLegend">{`${Math.trunc(fractionComplete * 100)}% of Application Completed`}</div>
      </div>
    </div>
  );};

export default DashboardChargerHead;

DashboardChargerHead.defaultProps = {
  vehicle: {}
};

DashboardChargerHead.propTypes = {
  currentAddress: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  equipment: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  user: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  vehicle: PropTypes.object
};

// @TODO this wasn't intended to be just for pages. Abstract this out.

import React from 'react';

import ProgressSubDrawer from '../submodules/ProgressSubdrawer';
import TitleNode from '../submodules/TitleNode';
import EditableDetails from '../../../Atoms/Inputs/EditableDetails/EditableDetails';
import TabletModalProfileItem from '../../../Pages/Dashboard/submodules/TabletModalProfileItem';

import { getTranslatedMessageFromID } from '../../../../utils/functions/intl/intlWrapper';
import ApplicationProgressMonitor from '../../../../utils/helpers/ApplicationProgressMonitor/ApplicationProgressMonitor';

import greenCheck from '../../../../customizations/assets/images/greenCheck.svg';
import yellowCircle from '../../../../customizations/assets/images/yellowCircle.svg';

import {
  userProfile,
  chargerLocation,
  chargerData,
  utilityData,
  evData,
} from '../data/drawerContentData';

import PATH_TO_PAGE_NAME_MAP from '../../../../constants/PATH_TO_PAGE_NAME_MAP';

const pathToPageNameMap = PATH_TO_PAGE_NAME_MAP;

const subDrawers = (
  language,
  user,
  equipment,
  vehicle,
  currentAddress,
) => [
  {
    title: getTranslatedMessageFromID('dashboard.subdrawers.drawer1', language),
    contentData: userProfile(user),
    editTarget: '/user-profile', // where the link from the edit button goes
  },
  {
    title: getTranslatedMessageFromID('dashboard.subdrawers.drawer2', language),
    contentData: chargerLocation(currentAddress),
    editTarget: '/charger-location',
  },
  {
    title: getTranslatedMessageFromID('dashboard.subdrawers.drawer3', language),
    contentData: chargerData(equipment),
    editTarget: '/my-charger',
  },
  {
    title: getTranslatedMessageFromID('dashboard.subdrawers.drawer4', language),
    contentData: utilityData(currentAddress),
    editTarget: '/my-utility',
  },
  {
    title: getTranslatedMessageFromID('dashboard.subdrawers.drawer5', language),
    contentData: evData(vehicle),
    editTarget: '/my-ev',
  },
];

const buildSubDrawers = (language, user, equipment, vehicle, currentAddress, allDrawersOpened = false) => {
  const returnedDrawers = subDrawers(
    language,
    user,
    equipment,
    vehicle,
    currentAddress,
  ).map((subDrawer) => {

    const partialPages = ApplicationProgressMonitor.getCurrentPartialPages();
    const completePages = ApplicationProgressMonitor.getCurrentPagesComplete();

    const linkedPage = pathToPageNameMap[subDrawer.editTarget];
    const isComplete = completePages.has(linkedPage);
    const isPartiallyComplete = partialPages.has(linkedPage);
    const hasStarted = isComplete || isPartiallyComplete;

    const drawerContent = [(
      <EditableDetails
        key={subDrawer.title}
        language={language}
        hasEditMode={false}
        formFieldsList={subDrawer.contentData}
        inputComponentsMustValidate={false}
        hasInvalidInput={() => {}}
      />
    )];

    const titleNode = (
      <TitleNode
        titleText={subDrawer.title}
        statusImage={isComplete ? greenCheck : yellowCircle}
      />
    );

    const returnedDrawer = allDrawersOpened
      ? (
        <TabletModalProfileItem
          title={subDrawer.title}
          drawerContent={drawerContent}
          language={language}
          editLink={subDrawer.editTarget}
        />
      )
      : (
        <ProgressSubDrawer
          titleNode={titleNode}
          drawerContent={drawerContent}
          language={language}
          editLink={hasStarted ? subDrawer.editTarget : null}
        />
      );

    return returnedDrawer;
  });
  return returnedDrawers;
};

export default buildSubDrawers;

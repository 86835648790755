import React from 'react';
import PropTypes from 'prop-types';

import SignatureTypeYourName from '../SignatureTypeYourName';
import FieldGroup from '../../../../../Atoms/FieldGroup/FieldGroup';
import FontSelector from '../../../../../Atoms/FontSelector/FontSelector';
import SignatureLegal from '../SignatureLegal';

import { getTranslatedMessageFromID } from '../../../../../../utils/functions/intl/intlWrapper';
import { checkFieldNotEmpty } from '../../../../../Atoms/Inputs/functions/validate';
import { fontSelectorOptions } from '../../../../../../utils/functions/forms/fonts/fonts';

const SignatureDesktop = (
  {
    user,
    updateUser,
    language,
    hasInvalidInput,
    inputComponentsMustValidate,
  },
) => {
  const leftField = (
    <>
      <SignatureTypeYourName
        user={user}
        updateUser={updateUser}
        language={language}
        hasInvalidInput={hasInvalidInput}
        inputComponentsMustValidate={inputComponentsMustValidate}
      />
      <SignatureLegal
        language={language}
      />
    </>
  );

  const rightField = (
    <div className="FontSelectorField">
      <FontSelector
        validate={checkFieldNotEmpty}
        fieldName="font-family"
        textToBeFonted={user.signature}
        font={user['font-family']}
        fontSet={fontSelectorOptions()}
        propsCollection={user}
        updatePropsCollection={updateUser}
        label={getTranslatedMessageFromID('userProfile.signature.selectFont', language)}
        legend={getTranslatedMessageFromID('userProfile.signature.yourSignature', language)}
        className="FontSelectorContainer"
        inputComponentsMustValidate={inputComponentsMustValidate}
        hasInvalidInput={hasInvalidInput}
      />
    </div>
  );

  return (
    <section className="SignatureBox">
      <FieldGroup
        language={language}
        leftField={leftField}
        rightField={rightField}
      />
    </section>
  );
};

export default SignatureDesktop;

SignatureDesktop.propTypes = {
  user: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  updateUser: PropTypes.func.isRequired,
  language: PropTypes.string.isRequired,
  hasInvalidInput: PropTypes.func.isRequired,
  inputComponentsMustValidate: PropTypes.bool.isRequired,
};

import React from 'react';
import PropTypes from 'prop-types';

import TextField from '../../Inputs/TextField/TextField';

import { getTranslatedMessageFromID } from '../../../../utils/functions/intl/intlWrapper';
import { validateZip } from '../../Inputs/functions/validate';

const ApartmentAndZip = ({
  address,
  updateAddress,
  language,
  hasInvalidInput,
  inputComponentsMustValidate,
  fieldIDSuffix
}) => (
  <div className="row double-field-row">
    <div className="col-6 double-field-left">
      <TextField
        validate={() => { /* no-op */ }}
        className="TextInput"
        fieldName="apt_number"
        defaultValue={getTranslatedMessageFromID('chargerLocation.zipappt.placeholder', language)}
        legend={getTranslatedMessageFromID('chargerLocation.zipappt.legend', language)}
        fieldWidth="100%"
        inputComponentsMustValidate={inputComponentsMustValidate}
        hasInvalidInput={hasInvalidInput}
        propsCollection={address}
        updatePropsCollection={updateAddress}
        fieldIDSuffix={fieldIDSuffix}
      />
    </div>
    <div className="col-6 double-field-right">
      <TextField
        validate={validateZip}
        className="TextInput"
        fieldName="postcode"
        defaultValue={getTranslatedMessageFromID('zip', language)}
        legend={getTranslatedMessageFromID('zipCode', language)}
        fieldWidth="100%"
        inputComponentsMustValidate={inputComponentsMustValidate}
        hasInvalidInput={hasInvalidInput}
        propsCollection={address}
        updatePropsCollection={updateAddress}
        fieldIDSuffix={fieldIDSuffix}
        type="tel"
      />
    </div>
  </div>
);

export default ApartmentAndZip;

ApartmentAndZip.propTypes = {
  address: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  updateAddress: PropTypes.func.isRequired,
  language: PropTypes.string.isRequired,
  hasInvalidInput: PropTypes.func.isRequired,
  inputComponentsMustValidate: PropTypes.bool.isRequired,
  fieldIDSuffix: PropTypes.string.isRequired
};

import fieldsByUpdater from './data/fieldsByUpdater';
import bindDataToField from './functions/bindDataToField';
import filterByField from '../../utils/functions/misc/filterByField';
import loadToGlobalState from './loadToGlobalState';

const findEquipmentByType = (equipType, equipments) => {
  const extractedEquipDetails = [];

  equipments.forEach((equip) => {
    extractedEquipDetails.push(equip);
  });

  const filteredEquipment = filterByField('type', equipType, extractedEquipDetails);

  return filteredEquipment;
};

const extractEquipmentData = (equipmentData, selectedType) => {
  const fieldsGroupsForEquip = {
    charger: {
      equipDetails: 'updateCharger',
      equipImages: 'updateChargerImages',
    },
    vehicle: {
      equipDetails: 'updateVehicle',
      equipImages: 'updateVehicleImages',
    },
  };

  const selectedEquip = findEquipmentByType(selectedType, equipmentData || [{}]) || [{}];

  // @TODO should be able to specify equipment id to select a charger out of the list -
  // same approach should be used to get equipment address data in processUserAddress.

  let currentEquip = selectedEquip[0] || {};

  // FOR 4/7 --> Find the Largest Equipment ID and set to global state
  if (selectedType === 'charger' && selectedEquip.length > 0) {
    let largestEquipmentIdObject = selectedEquip[0];

    equipmentData.forEach((equipment) => {
      if (equipment.equipment_id > largestEquipmentIdObject.equipment_id && equipment.type === 'charger') {
        largestEquipmentIdObject = equipment;
      }
    });

    currentEquip = largestEquipmentIdObject;
  }

  const equipDetails = currentEquip.details || {};
  const equipImages = { ...currentEquip.images } || {};
  const equipIncentivesNode = currentEquip.incentives || [];

  const equipFieldNames = fieldsByUpdater[fieldsGroupsForEquip[selectedType].equipDetails];
  const equipNode = bindDataToField(equipFieldNames, equipDetails);

  const equipImagesFieldNames = fieldsByUpdater[fieldsGroupsForEquip[selectedType].equipImages];
  const equipImagesNode = bindDataToField(equipImagesFieldNames, equipImages);

  equipNode.equipment_id = currentEquip.equipment_id ? currentEquip.equipment_id.toString() : 0;

  equipNode.address_ids = currentEquip.address_ids || [];

  equipNode.images = equipImagesNode;

  // We do not need to populate with defaults because this part is not determined by user input.
  equipNode.incentives = equipIncentivesNode;

  return equipNode;
};

const processVehicleAndChargerData = (equipmentData, dispatch) => {
  const chargerNode = extractEquipmentData(equipmentData, 'charger');
  const vehicleNode = extractEquipmentData(equipmentData, 'vehicle');

  loadToGlobalState(dispatch, {
    type: 'SET_CHARGER',
    payload: chargerNode,
  });

  loadToGlobalState(dispatch, {
    type: 'SET_VEHICLE',
    payload: vehicleNode,
  });
};

export default processVehicleAndChargerData;

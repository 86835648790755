import React from 'react';

import Uuid from '../../../../utils/functions/misc/Uuid';

const finalizeReturnedElement = ((rows) => {
  return (
    <>{
      rows.map((row) => {
        return(
          <div 
            className="row"
            key={Uuid()}
          > 
            {
              row.map((col) => {
                return col;
              })
            }
          </div>);
      })}
    </>
  );

});

const assembleTabletDashboardContentBlocks = (blocks) => {
  const contentRows = [];

  let currentRowComponents;
  let count = 0;

  blocks.forEach((block) => {
    const isNewRow = count % 2 === 0;

    const newCol = (
      <div 
        className="col-6"
        key={Uuid()}
      >{block}</div>
    );

    if(isNewRow) {
      currentRowComponents = [];
      currentRowComponents.push(newCol);
    } else {
      currentRowComponents.push(newCol);
      contentRows.push([ ...currentRowComponents]);
      currentRowComponents = [];
    }

    count += 1;
  });

  if(currentRowComponents) {
    contentRows.push(currentRowComponents);
  }

  return finalizeReturnedElement(contentRows);

};

export default assembleTabletDashboardContentBlocks;

import React from 'react';
import PropTypes from 'prop-types';

import ExampleBlurb from './ComponentWrappers/ExampleBlurb';
import ExampleCheckbox from './ComponentWrappers/ExampleCheckbox';
import ExampleChecklistUL from './ComponentWrappers/ExampleChecklistUL';
import ExampleApplicationStepsDesktop from './ComponentWrappers/ExampleApplicationStepsDesktop';
import ExampleDropdown from './ComponentWrappers/ExampleDropdown';
import ExampleIncentiveCard from './ComponentWrappers/ExampleIncentiveCard';
import ExampleIncentiveCatalog from './ComponentWrappers/ExampleIncentiveCatalog';
import ExampleNextButton from './ComponentWrappers/ExampleNextButton';
import ExampleTextField from './ComponentWrappers/ExampleTextField';
import ExampleDashboardChargerhead from './ComponentWrappers/ExampleDashboardChargerHead';

import './css/catalog.scss';

const ComponentCatalog = (
  {
    language,
    currentPage,
    inputComponentsMustValidate,
    setInputComponentsMustValidate,
    hasInvalidInput,
    user,
    updateUser,
    requiredCheckboxes,
    setRequiredCheckboxes,
    setCurrentIncentiveByID,
    currentAddress,
  },
) => (
  <section className="container">
    <div className="row catalog-row">
      <div className="col-md-4">
        <ExampleTextField
          inputComponentsMustValidate={inputComponentsMustValidate}
          hasInvalidInput={hasInvalidInput}
          user={user}
          updateUser={updateUser}
        />
      </div>
      <div className="col-md-4">
        <ExampleDropdown
          inputComponentsMustValidate={inputComponentsMustValidate}
          hasInvalidInput={hasInvalidInput}
          user={user}
          updateUser={updateUser}
        />
      </div>
      <div className="col-md-4">
        <ExampleCheckbox
          language={language}
          inputComponentsMustValidate={inputComponentsMustValidate}
          hasInvalidInput={hasInvalidInput}
          requiredCheckboxes={requiredCheckboxes}
          setRequiredCheckboxes={setRequiredCheckboxes}
        />
      </div>
    </div>
    <div className="row catalog-row">
      <div className="col-md-4">
        <ExampleIncentiveCard
          language={language}
          incentiveToUse={0}
          setCurrentIncentiveByID={setCurrentIncentiveByID}
        />
      </div>
      <div className="col-md-4">
        <ExampleIncentiveCard
          language={language}
          incentiveToUse={1}
          setCurrentIncentiveByID={setCurrentIncentiveByID}
        />
      </div>
      <div className="col-md-4">
        <ExampleIncentiveCard
          language={language}
          incentiveToUse={2}
          setCurrentIncentiveByID={setCurrentIncentiveByID}
        />
      </div>
    </div>
    <div className="row catalog-row">
      <div className="col-md-4">
        <ExampleBlurb />
      </div>
      <div className="col-md-4">
        <ExampleChecklistUL />
      </div>
      <div className="col-md-4">
        <ExampleIncentiveCatalog
          language={language}
          setCurrentIncentiveByID={setCurrentIncentiveByID}
        />
      </div>
    </div>
    <div className="row catalog-row">
      <div className="col-md-4">
        <ExampleDashboardChargerhead
          currentAddress={currentAddress}
        />
      </div>
      <div className="col-md-8">
        <ExampleApplicationStepsDesktop
          currentPage={currentPage}
          language={language}
        />
      </div>
    </div>
    <div className="row catalog-row">
      <div className="col-md-3 offset-md-9">
        <ExampleNextButton
          setInputComponentsMustValidate={setInputComponentsMustValidate}
        />
      </div>
    </div>
  </section>
);

export default ComponentCatalog;

// @TODO bring back when adding a page turn button unless we can find a more DRY way to do this.
ComponentCatalog.propTypes = {
  currentPage: PropTypes.string.isRequired,
  language: PropTypes.string.isRequired,
  inputComponentsMustValidate: PropTypes.bool.isRequired,
  setInputComponentsMustValidate: PropTypes.func.isRequired,
  hasInvalidInput: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  updateUser: PropTypes.func.isRequired,
  requiredCheckboxes: PropTypes.objectOf(PropTypes.bool).isRequired,
  setRequiredCheckboxes: PropTypes.func.isRequired,
  setCurrentIncentiveByID: PropTypes.func.isRequired,
  currentAddress: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
};

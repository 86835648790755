const noOp = () => {};

const emptyPromise = (shouldResolve = true) => {
  return new Promise((resolve, reject) => {
    if(shouldResolve) {
      resolve();
    } else {
      reject();
    }
  });
};

export {
  noOp,
  emptyPromise
};

import userProfilePromise from './functions/promises/userProfilePromise';
import chargerLocationPromise from './functions/promises/chargerLocationPromise';
import myEquipmentPromise from './functions/promises/myEquipmentPromise';
import myUtilityPromise from './functions/promises/myUtilityPromise';
import ownerDetailsPromise from './functions/promises/ownerDetailsPromise';
import completeApplicationPromise from './functions/promises/completeApplicationPromise';

const noOpPromise = () =>
  new Promise((resolve) => {
    resolve();
  });

// Reaches out to API and makes new upates as needed
// will probably require the full set of setters
const pageSubmitHandler = (
  currentPage,
  { fetchUserData, setDisplayPending, iaaUser, dispatch, triggerAllInputComponentsValidPromise, alertAPIError, language }
) => {
  const postPutPromises = {
    '/user-profile': () =>
      userProfilePromise({
        user: iaaUser.UserObject.profile,
        setDisplayPending,
        triggerAllInputComponentsValidPromise,
        alertAPIError,
      }),
    '/': () => noOpPromise(),
    '/welcome': () => noOpPromise(),
    '/update-account': () => noOpPromise(),
    '/charger-location': () => {
      return chargerLocationPromise({
        user: iaaUser.UserObject.profile,
        currentAddress: iaaUser.UserObject.chargerAddress,
        charger: iaaUser.UserObject.charger,
        triggerAllInputComponentsValidPromise,
        setDisplayPending,
        dispatch,
        alertAPIError,
        language
      });
    },
    '/complete-application': (backClick) => {
      return completeApplicationPromise({
        incentive: iaaUser.UserObject.currentIncentive,
        setDisplayPending,
        triggerAllInputComponentsValidPromise,
        dispatch,
        charger: iaaUser.UserObject.charger,
        alertAPIError,
        backClick,
      });
    },
    '/my-charger': () =>
      myEquipmentPromise({
        fetchUserData,
        equipment: iaaUser.UserObject.charger,
        setDisplayPending,
        triggerAllInputComponentsValidPromise,
        dispatch,
        alertAPIError,
      }),
    '/my-utility': () => {
      return myUtilityPromise({
        currentAddress: iaaUser.UserObject.chargerAddress,
        setDisplayPending,
        triggerAllInputComponentsValidPromise,
        dispatch,
        alertAPIError,
        language
      });
    },
    '/my-ev': () => {
      return myEquipmentPromise({
        fetchUserData,
        equipment: iaaUser.UserObject.vehicle,
        currentAddress: iaaUser.UserObject.chargerAddress,
        setDisplayPending,
        triggerAllInputComponentsValidPromise,
        dispatch,
        alertAPIError,
      });
    },
    '/owner-details': () => {
      return ownerDetailsPromise({
        user: iaaUser.UserObject.profile,
        currentAddress: iaaUser.UserObject.chargerAddress,
        setDisplayPending,
        triggerAllInputComponentsValidPromise,
        dispatch,
        alertAPIError,
      });
    },
    '/my-dashboard': () => noOpPromise(),
    '/contact-us': () => noOpPromise(),
    '/component-zoo': () => noOpPromise(),
    '/login': () => noOpPromise(),
    '/signout': () => noOpPromise(),
    '/no-incentives-available': () => noOpPromise(),
  };

  return postPutPromises[currentPage];
};

export default pageSubmitHandler;

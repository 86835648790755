import React from 'react';
import PropTypes from 'prop-types';

import IAAButton from '../../Atoms/Buttons/IAAButton';

// @TODO add next page function- will probably pass down from root.
const NextButton = (
  {
    children,
    setInputComponentsMustValidate,
  },
) => (
  <IAAButton
    label="nextPage"
    onClick={() => {
      // Root.js will give buttons a chance to validate their input, then
      // - setInputComponentsMustValidate(false)
      // - if no issues root will turn the page
      setInputComponentsMustValidate(true);
    }}
  >
    {children}
  </IAAButton>
);

export default NextButton;

NextButton.propTypes = {
  children: PropTypes.node.isRequired,
  setInputComponentsMustValidate: PropTypes.func.isRequired,
};

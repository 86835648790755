import React from 'react';
import PropTypes from 'prop-types';

import IncentiveDrawer from '../../DashboardApplicationProgress/IncentiveDrawer/IncentiveDrawer';

import equipmentData from '../data/equipmentData';

const ExampleIncentiveCatalog = (
  {
    language,
    setCurrentIncentiveByID,
  },
) => (
  <IncentiveDrawer
    title="Available Incentives"
    incentives={equipmentData.equipment.incentives}
    language={language}
    setCurrentIncentiveByID={setCurrentIncentiveByID}
  />
);

export default ExampleIncentiveCatalog;

ExampleIncentiveCatalog.propTypes = {
  language: PropTypes.string.isRequired,
  setCurrentIncentiveByID: PropTypes.func.isRequired,
};

import React from 'react';
import PropTypes from 'prop-types';

import RequiredFieldIndicator from '../../RequiredFieldIndicator/RequiredFieldIndicator';

const PhotoProcessorInstrux = (
  {
    bottomMessage,
    topMessage,
    id
  },
) => (
  <div
    className="PhotoProcessorInstrux pl-2"
  >
    <div
      className="what-to-do"
    >
      {topMessage}
    </div>
    <div
      className="what-in-picture"
    >
      {bottomMessage}
      <RequiredFieldIndicator
        fieldName={id}
      />
    </div>
  </div>
);

export default PhotoProcessorInstrux;

PhotoProcessorInstrux.propTypes = {
  id: PropTypes.string.isRequired,
  bottomMessage: PropTypes.string.isRequired,
  topMessage: PropTypes.string.isRequired,
};

const filterByField = (propName, propValue, json, toLowerCase = false) => json.filter(
  (node) => { 
    if(toLowerCase) {
      return node[propName].toLowerCase() === propValue.toLowerCase();    
    }
    return node[propName] === propValue; 
  }
);

const filterByFieldBigNumber = (propName, propValue, json) => json.filter(
  (node) => {
    if(node[propName] < propValue || node[propName] > propValue) {
      return false;
    }
    return true;
  }
);

export default filterByField;
export { filterByFieldBigNumber };

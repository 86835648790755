import React from 'react';
import PropTypes from 'prop-types';

import './Main.scss';


const Main = ({ 
  children,
  haveCheckedRedirect 
}) => {
  const mainClass = haveCheckedRedirect
    ? 'Main'
    : 'hidden';

  return(<main className={mainClass}>{children}</main>);
};

export default Main;

Main.propTypes = {
  children: PropTypes.node.isRequired,
  haveCheckedRedirect: PropTypes.bool.isRequired
};

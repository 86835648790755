import React from 'react';
import PropTypes from 'prop-types';

import IAACheckbox from "../../../Atoms/Inputs/Checkbox/IAACheckbox";

import { getTranslatedMessageFromID } from "../../../../utils/functions/intl/intlWrapper";

import '../css/MobileNoIncentivesAvailable.scss';

const MobileNoIncentivesAvailableContent = ({
  user,
  notifyIfNoIncentives, 
  validateFunc,
  language,
  hasInvalidInput,
  inputComponentsMustValidate
}) => (
  <div className="MobileNoIncentivesAvailable">
    <div className="NoIncentivesAvailableHead">
      <h1>Currently you don&apos;t qualify for the available incentives.</h1>
    </div>
    <div className="NoIncentivesAvailableContent">
      <div className="notify">Please notify me when new incentives become available</div>
      <IAACheckbox
        fieldName="agreeToTerms"
        propsCollection={notifyIfNoIncentives}
        updatePropsCollection={() => {}}
        validate={validateFunc}
        legend={getTranslatedMessageFromID('noIncentivesAvaiable.email', language, {
          email: user.email
        })}
        inputComponentsMustValidate={inputComponentsMustValidate}
        hasInvalidInput={hasInvalidInput}
      />
      <IAACheckbox
        fieldName="agreeToTerms"
        propsCollection={notifyIfNoIncentives}
        updatePropsCollection={() => {}}
        validate={validateFunc}
        legend={getTranslatedMessageFromID('noIncentivesAvaiable.text', language, {
          phone: user.mobile_phone
        })}
        inputComponentsMustValidate={inputComponentsMustValidate}
        hasInvalidInput={hasInvalidInput}
      />
    </div>
  </div>
);

export default MobileNoIncentivesAvailableContent;

MobileNoIncentivesAvailableContent.propTypes = {
  user: PropTypes.object.isRequired,
  notifyIfNoIncentives: PropTypes.object.isRequired,
  validateFunc: PropTypes.func.isRequired,
  language: PropTypes.string.isRequired,
  hasInvalidInput: PropTypes.func.isRequired,
  inputComponentsMustValidate: PropTypes.bool.isRequired,
};

import React from 'react';
import PropTypes from 'prop-types';

import { renderingInAnyMobile } from '../../../utils/functions/device/determinePlatform';

const ApplicationStep = (
  {
    targetPage,
    currentPage,
    titleText, // optional because not present in mobile version
  },
) => {
  const activeClassStatus = (targetPage === currentPage)
    ? ' activeStep'
    : ' inactiveStep';

  const mobileClassStatus = renderingInAnyMobile()
    ? ' mobileStep'
    : ' desktopStep';

  return (
    <div
      className="ApplicationStepNavLinkWrapper"
    >
      <div
        className={`ApplicationStepNavLink text-left${activeClassStatus}${mobileClassStatus}`}
      >
        <div 
          className={`ApplicationStepNavOverscore${activeClassStatus}${mobileClassStatus}`}
        />
        <span className="titleText">
          {titleText}
        </span>
      </div>
    </div>
  );
};

export default ApplicationStep;

ApplicationStep.defaultProps = {
  titleText: ' ',
};

ApplicationStep.propTypes = {
  targetPage: PropTypes.string.isRequired,
  currentPage: PropTypes.string.isRequired,
  titleText: PropTypes.string,
};

import React from 'react';

import spinner from '../../../customizations/assets/images/spinner.gif';

import './css/loadingAnimation.scss';

const LoadingAnimation = () => {
  return (
    <div className="loading-spinner">
      <img 
        src={spinner}
        alt="loading animation"    
      />
    </div>
  );
};

export default LoadingAnimation;

import React from 'react';
import PropTypes from 'prop-types';

import Dropdown from '../../../../Atoms/Inputs/Dropdown/Dropdown';
import FieldGroup from '../../../../Atoms/FieldGroup/FieldGroup';

import { getTranslatedMessageFromID } from '../../../../../utils/functions/intl/intlWrapper';
import { checkFieldNotEmpty } from '../../../../Atoms/Inputs/functions/validate';
import { getHouseholdSizeValues, getHouseholdIncomeValues } from '../data/optionsData';

const HouseholdSizeAndIncome = (
  {
    inputComponentsMustValidate,
    hasInvalidInput,
    user,
    updateUser,
    language,
  },
) => {
  const householdSizeLegend = getTranslatedMessageFromID('userProfile.householdSize', language);
  const householdIncomeLegend = getTranslatedMessageFromID('householdIncome', language);
  const householdIncomePlaceholder = getTranslatedMessageFromID('userProfile.selectIncomeRange', language);
  const HouseholdMembersTooltip = getTranslatedMessageFromID('userProfile.householdMembers.tooltip', language);
  const HouseholdIncomeTooltip = getTranslatedMessageFromID('userProfile.householdIncome.tooltip', language);

  const leftField = (
    <Dropdown
      fieldName="number_household_members"
      validate={checkFieldNotEmpty}
      propsCollection={user}
      updatePropsCollection={updateUser}
      options={getHouseholdSizeValues}
      placeholder={householdSizeLegend}
      legend={householdSizeLegend}
      inputComponentsMustValidate={inputComponentsMustValidate}
      hasInvalidInput={hasInvalidInput}
      search={false}
      tooltip={HouseholdMembersTooltip}
    />
  );

  const rightField = (
    <Dropdown
      fieldName="household_income"
      validate={checkFieldNotEmpty}
      propsCollection={user}
      updatePropsCollection={updateUser}
      options={getHouseholdIncomeValues}
      placeholder={householdIncomePlaceholder}
      legend={householdIncomeLegend}
      inputComponentsMustValidate={inputComponentsMustValidate}
      hasInvalidInput={hasInvalidInput}
      search={false}
      tooltip={HouseholdIncomeTooltip}
    />
  );

  return (
    <FieldGroup
      language={language}
      leftField={leftField}
      rightField={rightField}
    />
  );
};

export default HouseholdSizeAndIncome;

HouseholdSizeAndIncome.propTypes = {
  inputComponentsMustValidate: PropTypes.bool.isRequired,
  hasInvalidInput: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  updateUser: PropTypes.func.isRequired,
  language: PropTypes.string.isRequired,
};

import React from 'react';

import IncentiveCard from '../../Atoms/Cards/IncentiveCard/IncentiveCard';

import placeholderIncentives from '../../../constants/PLACEHOLDER_INCENTIVES';

/**
 * @param {Array}   incentives
 * @param {String}  language
 *
 * @returns {Array}
 */
const incentiveCatalogBuilder = (incentives, language, setCurrentIncentiveByID = () => {}) => {
  const incentiveCatalog = [];

  const workingLanguage = language || '';


  /* 

  We build the incentive catalog across 3 cases:
  * If we don't have the postcode yet, note just that
  * If we have the postcode:
  *   list the incentives (even if it is a placeholder 'incentive' explaining that
  *   there are no incentives available in this postcode)
  */

  if(incentives === null) {
    incentives = [
      placeholderIncentives.availableIncentivesUnknown
    ];
  }

  incentives.forEach((incentive) => {
    incentiveCatalog.push(
      <IncentiveCard
        language={workingLanguage}
        incentiveData={incentive}
        setCurrentIncentiveByID={setCurrentIncentiveByID}
      />,
    );
  });

  return incentiveCatalog;
};

export default incentiveCatalogBuilder;

import { optionNameAndValSame } from '../../../../utils/functions/forms/dropdown';
///make api call here if dropdowns are empty
const mapVehicleYears = (vehicle, storedList) => {
  if (!storedList.length > 0) {
    return [];
  }
  const vehicleModelYears = new Set();
  const model = vehicle.model;
  const availableVehicles = storedList.filter((listedVehicle) => {
    return listedVehicle.model === model;
  });
  availableVehicles.forEach((availableVehicle) => {
    vehicleModelYears.add(availableVehicle.model_year.toString());
  });

  return optionNameAndValSame(Array.from(vehicleModelYears).sort((a, b) => b - a));
};

export default mapVehicleYears;

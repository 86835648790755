import loadToGlobalState from './loadToGlobalState';
import LocalStorage from '../../utils/functions/localStorage/LocalStorage';
import localStorageKeyring from '../../constants/LOCAL_STORAGE_KEYS';
import checkApt from './functions/checkApt';

const createAddressMap = (allAddresses) => {
  let map = {};
  // UPDATE IF WE NEED MORE THAN 2 ADDRESSES
  allAddresses.forEach(address => {
    map[address.address_id.toString()] = address;
  });
  return map;
}; 

const processUserAddressData = (userData, dispatch, language) => {

  const userProfile = (userData && userData.profile)
    ? userData.profile
    : {};

  const allAddresses = (userData && userData.addresses)
    ? userData.addresses
    : [];

  const equipment = (userData && userData.equipment)
    ? userData.equipment
    : [];

  const primaryAddressID = userProfile.primary_address_id === undefined
    ? 0
    : userProfile.primary_address_id;

  // @TODO will need a state variable containing current equipment id
  const equipmentAddressIDs = equipment.length > 0
    ? equipment[0].address_ids
    : [];

  const chargerAddressID = equipmentAddressIDs.length > 0
    ? equipmentAddressIDs[0]
    : 0;

  const addressMap = createAddressMap(allAddresses);

  const rawChargerAddress = addressMap[chargerAddressID.toString()] || { address_id: chargerAddressID };
  const rawPrimaryAddress = addressMap[primaryAddressID.toString()] || { address_id: primaryAddressID };

  const chargerAddress = checkApt(rawChargerAddress, language);
  const primaryAddress = checkApt(rawPrimaryAddress, language);

  // @TODO do we still need this?
  if(chargerAddress && chargerAddress.postcode) {
    LocalStorage.setLocalDatapoint(localStorageKeyring.lastZIP, chargerAddress.postcode);
  }
  
  loadToGlobalState(
    dispatch,
    {
      type: 'SET_PRIMARY_ADDRESS',
      payload: primaryAddress
    }
  );

  loadToGlobalState(
    dispatch,
    {
      type: 'SET_CHARGER_ADDRESS',
      payload: chargerAddress
    }
  );
};

export default processUserAddressData;

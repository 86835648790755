import React from 'react';
import { FormattedMessage } from 'react-intl';

import './ContactUs.scss';

const ContactUs = () => {
  return (
    <section className="container" id="contact-us">
      <div className="row">
        <div className="col-md-8 offset-md-2">
          <h1 className="page-title">
            <FormattedMessage id="contactUs.title" />
          </h1>
          <div className="row">
            <div className="col-md-12">
              <div className="white-container">
                <h3>
                  <FormattedMessage id="contactUs.heading" />
                </h3>
                <p>
                  <FormattedMessage
                    id="contactUs.bodyText"
                    values={{ br: <br /> }}
                  />
                </p>
                <p>
                  <a
                    href="mailto:veloz-iaa-support@zappy.freshdesk.com"
                    className="strong"
                  >
                    veloz-iaa-support@zappy.freshdesk.com
                  </a>
                </p>
              </div>
            </div>
            <div className="col-md-12">
              <div className="text-container">
                <p>
                  <FormattedMessage id="contactUs.whoWeAre" />
                </p>
                <p className="legal-text">
                  <FormattedMessage
                    id="contactUs.disclaimer"
                    values={{
                      link: function PrivacyPolicyLink(chunks) {
                        return (
                          <a
                            href="https://www.electricforall.org/privacy-policy/"
                            target="_blank"
                            rel="noreferrer noopener"
                          >
                            {chunks}
                          </a>
                        );
                      },
                    }}
                  />
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ContactUs;

import React from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';

import IAAButton from '../../../../Atoms/Buttons/IAAButton';
import { getTranslatedMessageFromID } from '../../../../../utils/functions/intl/intlWrapper';

const ContinueButton = (
  {
    language,
  },
) => {
  const history = useHistory();

  return (
    <section className="container">
      <div className="row">
        <div className="col-12 text-center">
          <IAAButton
            label="continue"
            onClick={() => {
              history.push('/user-profile');
            }}
          >
            { getTranslatedMessageFromID('continue', language) }
          </IAAButton>
        </div>
      </div>
    </section>
  );
};

export default ContinueButton;

ContinueButton.propTypes = {
  language: PropTypes.string.isRequired,
};

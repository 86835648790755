import React from 'react';
import PropTypes from 'prop-types';

import PhoneNumberTextField from '../../../../Atoms/Inputs/TextField/PhoneNumberTextField';
import FieldGroup from '../../../../Atoms/FieldGroup/FieldGroup';

import { getTranslatedMessageFromID } from '../../../../../utils/functions/intl/intlWrapper';

const PhoneNumbers = (
  {
    user,
    updateUser,
    language,
    hasInvalidInput,
    inputComponentsMustValidate,
  },
) => {
  const leftField = (
    <PhoneNumberTextField
      className="TextInput"
      fieldName="mobile_phone"
      defaultValue={getTranslatedMessageFromID('userProfile.mobilePhone', language)}
      legend={getTranslatedMessageFromID('mobilePhone', language)}
      fieldWidth="100%"
      inputComponentsMustValidate={inputComponentsMustValidate}
      hasInvalidInput={hasInvalidInput}
      propsCollection={user}
      updatePropsCollection={updateUser}
    />
  );

  const rightField = (
    <PhoneNumberTextField
      className="TextInput"
      fieldName="home_phone"
      defaultValue={getTranslatedMessageFromID('userProfile.homePhone', language)}
      legend={getTranslatedMessageFromID('homePhone', language)}
      fieldWidth="100%"
      inputComponentsMustValidate={inputComponentsMustValidate}
      hasInvalidInput={hasInvalidInput}
      propsCollection={user}
      updatePropsCollection={updateUser}
      validate={()=>{}}
    />
  );

  return (
    <FieldGroup
      language={language}
      leftField={leftField}
      rightField={rightField}
    />
  );
};

export default PhoneNumbers;

PhoneNumbers.propTypes = {
  user: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  updateUser: PropTypes.func.isRequired,
  language: PropTypes.string.isRequired,
  hasInvalidInput: PropTypes.func.isRequired,
  inputComponentsMustValidate: PropTypes.bool.isRequired,
};

// @TODO consolidate the various forms of this where fields are listed by prop collection
// Put in top level data folder
const fieldsByUpdater = {
  updateUser: [
    {
      name: 'email',
      default: '',
    },
    {
      name: 'first_name',
      default: '',
    },
    {
      name: 'last_name',
      default: '',
    },
    {
      name: 'home_phone',
      default: '',
    },
    {
      name: 'mobile_phone',
      default: '',
    },
    {
      name: 'number_household_members',
      default: 0,
    },
    {
      name: 'household_income',
      default: 0,
    },
    {
      name: 'signature',
      default: '',
    },
    {
      name: 'font-family',
      default: 'Sacramento',
    },
    {
      name: 'primary_address_id',

      // @TODO when processing addresses
      // need to do a check on whether this addess id is in addresses
      default: 0,
    },
  ],
  updateAddress: [
    {
      name: 'address_id',
      default: 0,
    },
    {
      name: 'street_address',
      default: '',
    },
    {
      name: 'postcode',
      default: '',
    },
    {
      name: 'owner',
      default: 'true',
    },
    {
      name: 'charger-at-this-property',
      default: 'true',
    },
    {
      name: 'power_supplier',
      default: {
        name: '',
        account_number: '',
      },
    },
  ],
  updateCharger: [
    {
      name: 'make',
      default: '',
    },
    {
      name: 'model',
      default: '',
    },
    {
      name: 'purchase_price',
      default: 0,
    },
    {
      name: 'purchase_date',
      default: '',
    },
    {
      name: 'serial_number',
      default: '',
    },
    {
      name: 'type',
      default: 'charger',
    },
    {
      name: 'address_ids',
      default: [],
    },
  ],
  updateVehicle: [
    {
      name: 'make',
      default: '',
    },
    {
      name: 'model',
      default: '',
    },
    {
      name: 'purchase_price',
      default: 0,
    },
    {
      name: 'purchase_date',
      default: '',
    },
    {
      name: 'vin',
      default: '',
    },
    {
      name: 'model_year',
      default: '',
    },
    {
      name: 'type',
      default: 'vehicle',
    },
    {
      name: 'address_ids',
      default: [],
    },
    {
      name: 'fuel',
      default: '',
    },
  ],
  updateChargerImages: [
    {
      name: 'CHARGER_INSTALLATION',
      default: {
        size: '',
        url: '',
      },
    },
    {
      name: 'CHARGER_INSTALLATION_INVOICE',
      default: {
        size: '',
        url: '',
      },
    },
    {
      name: 'CHARGER_PURCHASE_RECEIPT',
      default: {
        size: '',
        url: '',
      },
    },
    {
      name: 'BUILDING_PERMIT',
      default: {
        size: '',
        url: '',
      },
    },
  ],
  updateVehicleImages: [
    {
      name: 'vehicle_registration',
      default: {
        size: '',
        url: '',
      },
    },
    {
      name: 'VEHICLE_LICENSE_PLATE',
      default: {
        size: '',
        url: '',
      },
    },
  ],
};

fieldsByUpdater.updateCurrentAddress = fieldsByUpdater.updateAddress;
fieldsByUpdater.updatePrimaryAddress = fieldsByUpdater.updateAddress;

export default fieldsByUpdater;

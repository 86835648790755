import contactAmplifyEndpoint from "../../contactEndpoint/contactAmplifyEndpoint";

/**
 *
 * @param {string}    equipmentid   ID of equipment according to equipment endpoint
 * @param {string}    asset         Field mapping of asset e.g. installation, charger_receipt
 *
 * @returns {Promise}
 */
const getAssetUploadURL = (props) => new Promise((resolve, reject) => {
  contactAmplifyEndpoint(
    '/user/newasset',
    'get',
    {
      queryStringParameters: props
    }
  )
    .then((data) => {
      resolve(data);
    })
    .catch((err) => {
      reject(err);
    });
});

export default getAssetUploadURL;

import { 
  mapUserProfile,
  mapAddress 
} from '../mapStateDataToRequestBody';
import contactAmplifyEndpoint from '../tempScaffolding/contactAmplifyEndpoint';
import { triggerAppWaitingMode, triggerGetUserData } from '../pageCycles/pageCycleManager';

const ownerDetailsPromise = (
  {
    user, 
    currentAddress,
    setDisplayPending
  }
) => {
  const mappedUser = mapUserProfile(user);
  const mappedAddress = mapAddress(currentAddress);
  
  return new Promise((resolve, reject) => {
    triggerAppWaitingMode(
      {
        setDisplayPending
      }
    );

    contactAmplifyEndpoint(
      '/user',
      'post',
      {
        body: mappedUser
      }
    )
      .then(() => {
        contactAmplifyEndpoint(
          '/user/addresses',
          'post',
          {
            body: mappedAddress
          }
        );
      })
      .catch((err) => {
        reject(err);
      })
      .finally(() => {
        triggerGetUserData({
          setDisplayPending,
        });   
      });
    ;
  });
};

export default ownerDetailsPromise;

import { mapUserProfile } from '../mapStateDataToRequestBody';
import contactAmplifyEndpoint from '../../../../contactEndpoint/contactAmplifyEndpoint';

import { triggerAppWaitingMode, triggerGetUserData } from '../pageCycles/pageCycleManager';
import logger from '../../../../../utils/functions/logging/logger';

const userProfilePromise = ({
  user,
  setDisplayPending,
  triggerAllInputComponentsValidPromise,
  alertAPIError
}) => {

  // @TODO why am I switching to camel-case? Most likely an issue raised by airbnb linter...
  const userProfile = mapUserProfile({
    email: user.email,
    firstName: user.first_name,
    fontFamily: user['font-family'],
    homePhone: user.home_phone,
    householdIncome: user.household_income,
    lastName: user.last_name,
    mobilePhone: user.mobile_phone,
    numberHouseholdMembers: user.number_household_members,
    primaryAddressID: user.primary_address_id,
    signature: user.signature,
  });

  return new Promise((resolve, reject) => {
    triggerAllInputComponentsValidPromise()
      .then(() => {
        triggerAppWaitingMode(
          {
            setDisplayPending
          }
        );

        contactAmplifyEndpoint(
          '/user/profile',
          'put',
          {
            body: userProfile,
          },
          alertAPIError
        );
      })
      .then((response) => {
        // @TODO response is undefined and we're not doing anything with it.
        resolve(response);
        triggerGetUserData({
          setDisplayPending,
        });
      })
      .catch((err) => {
        //reject(err);
        logger(err, 'userProfilePromise');
      });
  });
};

export default userProfilePromise;

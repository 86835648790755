import { renderingInAnyMobile } from './determinePlatform';


const chooseComponentForDeviceType = (mobileComponent, desktopOrTabletComponent) => (
  renderingInAnyMobile()
    ? mobileComponent
    : desktopOrTabletComponent
);

export default chooseComponentForDeviceType;

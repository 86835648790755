import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';

import ItemCounter from './ItemCounter/ItemCounter';

import selectString from '../../../utils/functions/misc/selectString';
import { getTranslatedMessageFromID } from '../../../utils/functions/intl/intlWrapper';

const DashboardNavDrawer = (
  {
    items,
    title,
    className = '',
    showCount,
    editLink,
    language,
    incentiveDrawerOpened
  },
) => {
  const [dropdownOpen, setDropdownOpen] = useState(incentiveDrawerOpened);

  const drawerStyle = {
    display: selectString(dropdownOpen, 'block', 'none'),
  };

  return (
    <div
      className={`DashboardNavDrawer dropdown-${dropdownOpen} ${className}`}
    >
      <button
        className="DrawerToggle"
        type="button"
        onClick={() => setDropdownOpen(current => !current)}
      >
        <div className="row">
          <div className="col-10 ToggleTitle"><h3>{title}</h3></div>
          <div className="col-2">
            <ItemCounter
              isExpanded={dropdownOpen}
              numberOfItems={showCount
                ? items.length
                : -1}
            />
          </div>
        </div>

      </button>
      <div
        className="DrawerContents"
        style={drawerStyle}
      >
        {editLink && (
          <div className="edit-link">
            <NavLink
              to={editLink}
            >
              {getTranslatedMessageFromID('edit', language)}
            </NavLink>
          </div>
        )}
        {
          items.map((item, idx) => {
            return (
              <div
                key={idx}
                className="NavDrawerItem"
              >
                {item}
              </div>
            );
          })
        }
      </div>
    </div>
  );
};

export default DashboardNavDrawer;

DashboardNavDrawer.defaultProps = {
  showCount: true,
  className: '',
  editLink: '',
  language: 'EN',
};

DashboardNavDrawer.defaultProps = {
  incentiveDrawerOpened: false
};

DashboardNavDrawer.propTypes = {
  title: PropTypes.any.isRequired, // eslint-disable-line react/forbid-prop-types
  className: PropTypes.string,
  items: PropTypes.arrayOf(PropTypes.node).isRequired,
  showCount: PropTypes.bool,
  editLink: PropTypes.string,
  language: PropTypes.string,
  incentiveDrawerOpened: PropTypes.bool
};

import React from 'react';

import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';

import IAAButton from '../Buttons/IAAButton';
import logger from '../../../utils/functions/logging/logger';

const BackNextButtons = ({
  previousPage,
  nextPage,
  previousPageButtonText,
  nextPageButtonText,
  handlePageForwardClick,
  sendToAPIOnBackButtonClick
}) => {
  const history = useHistory();

  const handleClick = async (targetPage=nextPage) => {

    // Know to handle differently if we are going somewhere other than nextPage e.g. previous page.
    handlePageForwardClick(targetPage !== nextPage)
      .then(() => {
        history.push(targetPage);
      })
      .catch((err) => {
        logger(`promise went bad ${err}`, BackNextButtons);
      });
  };

  return (
    <section className="container backNextButtons">
      <div className="IAABackNext d-flex justify-content-between">
        <IAAButton
          color="light"
          label="backButton"
          onClick={() => {
            if(sendToAPIOnBackButtonClick) {
              handleClick(previousPage);
            } else {
              history.push(previousPage);
            }
          }}
        >
          {previousPageButtonText}
        </IAAButton>
        <IAAButton
          label="nextButton"
          onClick={() => handleClick()}
        >
          {nextPageButtonText}
        </IAAButton>
      </div>
    </section>
  );
};

export default BackNextButtons;

BackNextButtons.defaultProps = {
  previousPage: null,
  nextPage: null,
  sendToAPIOnBackButtonClick: false
};

// @TODO will probably also need a state from Root.js to indicate that
// it is time to send values to API.
BackNextButtons.propTypes = {
  previousPage: PropTypes.string,
  nextPage: PropTypes.string,
  previousPageButtonText: PropTypes.string.isRequired,
  nextPageButtonText: PropTypes.string.isRequired,
  handlePageForwardClick: PropTypes.func.isRequired,
  sendToAPIOnBackButtonClick: PropTypes.bool
};

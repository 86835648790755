import React from 'react';
import PropTypes from 'prop-types';

import FieldGroupMobile from './subcomponents/FieldGroupMobile';
import FieldGroupDesktop from './subcomponents/FieldGroupDesktop';
import SectionWrapper from '../SectionWrapper/SectionWrapper';

const FieldGroup = (
  {
    leftField,
    rightField,
    language,
  },
) => {
  const mobileFieldGroup = (
    <div className="FieldGroup-Mobile">
      <FieldGroupMobile
        leftField={leftField}
        rightField={rightField}
      />
    </div>
  );

  const desktopFieldGroup = (
    <div className="FieldGroup-Desktop">
      <FieldGroupDesktop
        leftField={leftField}
        rightField={rightField}
      />
    </div>
  );

  return (
    <SectionWrapper
      sectionClassName="IAAFieldGroup"
      language={language}
      mobileSection={mobileFieldGroup}
      desktopSection={desktopFieldGroup}
    />
  );
};

export default FieldGroup;

FieldGroup.defaultProps = {
  leftField: <></>,
  rightField: <></>,
};

FieldGroup.propTypes = {
  leftField: PropTypes.node,
  rightField: PropTypes.node,
  language: PropTypes.string.isRequired,
};

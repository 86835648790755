import React from 'react';

import AddressForm from '../../../Atoms/AddressForm/AddressForm';

import { getTranslatedMessageFromID } from '../../../../utils/functions/intl/intlWrapper';

const makeAddressForm = (
  addressToUpdate,
  isTopFormPosition,
  {
    address,
    updateAddress,
    currentAddress,
    updateCurrentAddress,
    language,
    hasInvalidInput,
    inputComponentsMustValidate,
  },
) => {
  let addressToUpdateParam = addressToUpdate;

  const addressTextType = addressToUpdateParam === 'equipment'
    ? 'chargerStreetAddress'
    : 'homeStreetAddress';

  return (
    <AddressForm
      address={address}
      updateAddress={updateAddress}
      language={language}
      hasInvalidInput={hasInvalidInput}
      fieldIDSuffix={
        isTopFormPosition
          ? ''
          : 'charger'
      }
      inputComponentsMustValidate={inputComponentsMustValidate}
      streetAddressLegend={getTranslatedMessageFromID(`chargerLocation.${addressTextType}.legend`, language)}
      streetAddressPlaceholder={getTranslatedMessageFromID(`chargerLocation.${addressTextType}.placeholder`, language)}
    />
  );
};

export default makeAddressForm;

const pathToPageNameMap = {
  '/my-charger': 'myCharger',
  '/my-ev': 'myVehicle',
  '/user-profile': 'userProfile',
  '/charger-location': 'chargerLocation',
  '/my-utility': 'myUtility',
  '/dashboard': 'dashboard',
  '/complete-application': 'completeApplication',
  '/submit-application': 'submitApplication'
};

export default pathToPageNameMap;

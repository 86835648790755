// @TODO dead code?

import React from 'react';

const UpdateAccount = () => {

  return (
    <section className="container">
      <div>
        Update Account. User can change email, password, etc here.
        The link to this will be the head and shoulders button at the far right.
      </div>
    </section>
  );
};

export default UpdateAccount;

import React from 'react';
import PropTypes from 'prop-types';

import { setClassName, seeMoreOrSeeLess } from './functions/descriptionHandler';

// @TODO may need to actually make this a button. I'm expecting trouble if we have more than one.
const SeeMoreSeeLess = (
  {
    onClick,
    descriptionExpanded,
    language,
  },
) => (
  <span
    className={`SeeMoreSeeLess ${setClassName(descriptionExpanded)}`}
    onClick={onClick}
    onKeyDown={onClick}
    role="button"
    label="seeMoreSeeLess"
    tabIndex={0}
  >
    {seeMoreOrSeeLess(descriptionExpanded, language)}
  </span>
);
export default SeeMoreSeeLess;

SeeMoreSeeLess.propTypes = {
  onClick: PropTypes.func.isRequired,
  descriptionExpanded: PropTypes.bool.isRequired,
  language: PropTypes.string.isRequired,
};

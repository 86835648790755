import { API, Auth } from 'aws-amplify';

const doAPIRequest = (method, apiName, path, initObj) => new Promise((resolve, reject) => {
  
  Auth.currentSession()
    .then(() => {
      method(apiName, path, initObj)
        .then((response) => {
          resolve(response);
        })
        .catch((err) => {
          reject(err);
        });
    })
    .catch((err) => {
      reject({
        message: "noCurrentSession",
        messageOnly: "true"
      });
    });
});

const contactAmplifyEndpoint = (
  path,
  method,
  {
    queryStringParameters,
    body,
    headers,
  },
  alertAPIError = null
) => {

  // @TODO make dynamic
  const env = 'staging';

  const envNames = {
    staging: 'MyAPIGatewayAPI',
  };

  const inits = {
    get: {
      headers,
      queryStringParameters
    },
    post: {
      body,
      headers
    },
    put: {
      body,
      headers
    },
  };

  const APIMethods = {
    get: API.get.bind(API),
    post: API.post.bind(API),
    put: API.put.bind(API),
  };

  return new Promise((resolve, reject) => { 
    doAPIRequest(APIMethods[method], envNames[env], path, inits[method])
      .then((response) => {
        resolve(response);
      })
      .catch((err) => { 
        const rejector = (helpfulError) => {
          reject(helpfulError);
        };

        const errorHandler = alertAPIError || rejector;
        const message = err.message || err;
        const moreHelpfulError = new Error(`Amplify ${path} responded with error: ${message}`);

        errorHandler(err.messageOnly 
          ? new Error(message)
          : moreHelpfulError  
        );

      });
  });
};

export default contactAmplifyEndpoint;

import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';

import ApplicationAssistantLayout from '../../ApplicationAssistantLayout/ApplicationAssistantLayout';
import UserProfileMainContent from './MainContent/UserProfileMainContent';

import './css/UserProfile.scss';

const UserProfile = (
  {
    language,
    currentPage,
    setCurrentIncentiveByID,
    hasInvalidInput,
    inputComponentsMustValidate,
    setInputComponentsMustValidate,
    handlePageForwardClick,
  },
) => {
  const intl = useIntl();
  return (
    <ApplicationAssistantLayout
      language={language}
      setCurrentIncentiveByID={setCurrentIncentiveByID}
      currentPage={currentPage}
      previousPage="/welcome"
      nextPage="/charger-location"
      previousPageButtonText={intl.formatMessage({ id: 'back' })}
      nextPageButtonText={intl.formatMessage({ id: 'saveAndNext' })}
      setInputComponentsMustValidate={setInputComponentsMustValidate}
      handlePageForwardClick={handlePageForwardClick}
    >
      <UserProfileMainContent
        language={language}
        hasInvalidInput={hasInvalidInput}
        inputComponentsMustValidate={inputComponentsMustValidate}
      />
    </ApplicationAssistantLayout>

  );
};

export default UserProfile;

UserProfile.propTypes = {
  language: PropTypes.string.isRequired,
  currentPage: PropTypes.string.isRequired,
  setCurrentIncentiveByID: PropTypes.func.isRequired,
  hasInvalidInput: PropTypes.func.isRequired,
  inputComponentsMustValidate: PropTypes.bool.isRequired,
  setInputComponentsMustValidate: PropTypes.func.isRequired,
  handlePageForwardClick: PropTypes.func.isRequired,
};

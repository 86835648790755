import React from 'react';
import PropTypes from 'prop-types';

import TextField from '../../../Atoms/Inputs/TextField/TextField';

import { getTranslatedMessageFromID } from '../../../../utils/functions/intl/intlWrapper';
import filterByField from '../../../../utils/functions/misc/filterByField';
import { checkFieldNotEmpty } from '../../../Atoms/Inputs/functions/inputHelper';
import RadioButtons from '../../../Atoms/Inputs/RadioButtons/RadioButtons';

// @TODO this appears to only be for incentive. Rename to IncentiveInputFields.
const InputFields = ({
  language,
  incentive,
  fields,
  updateIncentive,
  hasInvalidInput,
  inputComponentsMustValidate,
}) => {
  const inputFieldsAsObj = {};
  const inputFields = fields;

  // @TODO we've done this in a smarter way someplace else
  inputFields.forEach((field) => {
    const fieldName = field.name;
    const fieldValue = field.user_input || field.default_value;

    inputFieldsAsObj[fieldName] = fieldValue;
  });

  const updatePropsCollection = (fieldValue, fieldName) => {
    const targetField = filterByField('name', fieldName, inputFields)[0];

    targetField.user_input = fieldValue;

    const updatedIncentive = { ...incentive };

    updatedIncentive.input_fields = inputFields;

    updateIncentive(updatedIncentive);
  };

  // @TODO need to work out way to do Spanish translations...
  const determineField = (inputField) => {
    if(!inputField.type) {
      return null;
    }

    const typeMap = {
      'boolean': (
        <RadioButtons
          propsCollection={inputFieldsAsObj}
          updatePropsCollection={updatePropsCollection}
          fieldName={inputField.name}
          defaultValue={`${inputField.default_value}`}
          legend={inputField.description}
          key={`key_${inputField.name}`}
          choices={[
            {
              name: getTranslatedMessageFromID('yes', language),
              value: 'true',
            },
            {
              name: getTranslatedMessageFromID('no', language),
              value: 'false',
            },
          ]}
        />
      ), 
      'string': (
        <TextField
          validate={checkFieldNotEmpty}
          key={inputField.name}
          className="TextInput"
          fieldName={inputField.name}
          defaultValue={inputField.default_value}
          legend={inputField.description}
          fieldWidth="100%"
          inputComponentsMustValidate={inputComponentsMustValidate}
          hasInvalidInput={hasInvalidInput}
          propsCollection={inputFieldsAsObj}
          updatePropsCollection={updatePropsCollection}
        />
      ),
      'float': (
        <TextField
          validate={checkFieldNotEmpty}
          key={inputField.name}
          className="TextInput"
          fieldName={inputField.name}
          defaultValue={inputField.default_value.toString()}
          legend={inputField.description}
          fieldWidth="100%"
          inputComponentsMustValidate={inputComponentsMustValidate}
          hasInvalidInput={hasInvalidInput}
          propsCollection={inputFieldsAsObj}
          updatePropsCollection={updatePropsCollection}
        />
  
      )
    };

    return typeMap[inputField.type.toLowerCase()] || null;
  };

  return (
    <div>
      {
        inputFields.map((field) => determineField(field))
      }
    </div>
  );
};

export default InputFields;

InputFields.defaultProps = {
  fields: []
};

InputFields.propTypes = {
  language: PropTypes.string.isRequired,
  incentive: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  updateIncentive: PropTypes.func.isRequired,
  hasInvalidInput: PropTypes.func.isRequired,
  inputComponentsMustValidate: PropTypes.bool.isRequired,
  fields: PropTypes.array
};

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import TextField from '../../../../Atoms/Inputs/TextField/TextField';
import Dropdown from '../../../../Atoms/Inputs/Dropdown/Dropdown';
import FieldGroup from '../../../../Atoms/FieldGroup/FieldGroup';

import { getTranslatedMessageFromID } from '../../../../../utils/functions/intl/intlWrapper';
import { checkFieldNotEmpty } from '../../../../Atoms/Inputs/functions/validate';
import contactEndpoint from '../../../../../apiInterface/contactEndpoint/contactUnifiedEndpoint';
import getAllUtilitiesForZip from '../../functions/getAllUtilitiesForZip';

const UtilityInfo = (
  {
    currentAddress,
    utility,
    updateUtility,
    language,
    hasInvalidInput,
    inputComponentsMustValidate,
    alertAPIError
  },
) => {
  const [utilities, setUtilities] = useState([]);
  
  useEffect(() => {
    let ignore = false;

    async function fetchUtilities() {
      try {
        const utilities = await contactEndpoint(
          'unified',
          '/powersuppliers',
          'GET',
          {
            postcode: currentAddress.postcode,
          },
          null,
          alertAPIError
        );
        if (!ignore) setUtilities(utilities.suppliers);
      } catch (e) {
      }
    }

    fetchUtilities();

  },[currentAddress.postcode]);

  const leftField = (
    <TextField
      validate={checkFieldNotEmpty}
      className="TextInput"
      fieldName="account_number"
      defaultValue=""
      legend={getTranslatedMessageFromID('myUtility.accountID', language)}
      fieldWidth="100%"
      inputComponentsMustValidate={inputComponentsMustValidate}
      hasInvalidInput={hasInvalidInput}
      propsCollection={utility}
      updatePropsCollection={updateUtility}
    />
  );

  const rightField = (
    <Dropdown
      fieldName="name"
      validate={checkFieldNotEmpty}
      propsCollection={utility}
      updatePropsCollection={updateUtility}
      options={getAllUtilitiesForZip(utilities)}
      legend={getTranslatedMessageFromID('myUtility.providerName', language)}
      placeholder={getTranslatedMessageFromID('myUtility.providerName', language)}
      inputComponentsMustValidate={inputComponentsMustValidate}
      hasInvalidInput={hasInvalidInput}
      tooltip={getTranslatedMessageFromID('myUtility.electricUtilityName.tooltip',language)}
    />
  );

  return (
    <FieldGroup
      hi
      language={language}
      leftField={leftField}
      rightField={rightField}
    />
  );
};

export default UtilityInfo;

UtilityInfo.propTypes = {
  displayPending: PropTypes.bool.isRequired,
  setDisplayPending: PropTypes.func.isRequired,
  utility: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  updateUtility: PropTypes.func.isRequired,
  currentAddress: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  language: PropTypes.string.isRequired,
  hasInvalidInput: PropTypes.func.isRequired,
  inputComponentsMustValidate: PropTypes.bool.isRequired,
  alertAPIError: PropTypes.func.isRequired
};

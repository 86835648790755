import React from 'react';
import PropTypes from 'prop-types';

import { getTranslatedMessageFromID } from '../../../../../utils/functions/intl/intlWrapper';

const WelcomeHeader = (
  {
    user,
    language,
  },
) => {
  
  const translatedText = user.first_name
    ? getTranslatedMessageFromID(
      'register.partiallyCompleted.welcome',
      language,
      {
        firstName: user.first_name,
      },
    )
    : getTranslatedMessageFromID(
      'register.registerSuccess.welcome',
      language,
      {
        firstName: user.first_name,
        lineBreak: <br className="d-block d-sm-none" />
      },
    );

  return (
    <section className="container">
      <div className="row">
        <div className="col-12 text-center">
          <h1>
            {translatedText}
          </h1>
        </div>
      </div>
    </section>
  );
};

export default WelcomeHeader;

WelcomeHeader.propTypes = {
  user: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  language: PropTypes.string.isRequired,
};

// @TODO couple of issues with switching from default value to a blank.
// Also when a textfield is not valid, it is reverting to default value

const handleClick = (event, defaultValue) => {
  const targ = event.target;
  if (targ.value === defaultValue) {
    targ.value = '';
  }
};

const updateRootState = (event, fieldName, currentState, setNewState) => {
  const newState = { ...currentState };
  newState[fieldName] = event.target.value;

  setNewState(newState);
};

const checkForValidation = (
  validate, 
  value, 
  defaultVal, 
  setWarning, 
  fieldName, 
  hasInvalidInput
) => {
  try {
    validate(value, defaultVal);
    setWarning('');
    hasInvalidInput(fieldName, false);
  } catch (err) {
    setWarning(err.message);
    hasInvalidInput(fieldName, true);
  }
};

const checkFieldNotEmpty = (fieldValue, defaultValue = '') => {
  if (!fieldValue || (fieldValue === defaultValue)) {
    throw new Error('Field is required');
  }
};

const checkDateFormat = (fieldValue) => {
  // Dates from the datepicker are sent as YYYY-MM-DD strings
  const isYYYYMMDDString = /([12]\d{3}-(0[1-9]|1[0-2])-(0[1-9]|[12]\d|3[01]))/.test(fieldValue);
  // Dates are returned from the API as timestamps
  const isThirteenDigitInteger = /^$|^\d{13}$/.test(fieldValue);
  const isValidInput = isYYYYMMDDString || isThirteenDigitInteger;
  const isEmptyString = fieldValue === '';

  if (!isValidInput || isEmptyString) {
    throw new Error('Field is required');
  }
};

export {
  handleClick,
  updateRootState,
  checkForValidation,
  checkFieldNotEmpty,
  checkDateFormat,
};

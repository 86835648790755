// @TODO remove where no longer in use.
const localStorageKeyring = {
  expandedComponents: 'lsExpandedComponents',
  toggledComponents: 'lsToggledComponents',
  storedVehicles: 'lsStoredVehicles',
  userReducedObject: 'lsReducedUserObject',
  lastFocusedField: 'lsLastFocusedField',
  chargerAtPrimaryAddress: 'lsChargerPrimary',
  utilityList: 'lsUtilities',
  availableIncentives: 'lsIncentives',
  partialPages: 'lsPartialPages',
  completedPages: 'lsCompletedPages',
  fractionFieldsFinished: 'lsFractionsFieldsFinished',
  unifiedAPIChargers: 'lsUnifiedAPIChargers',
  iaVersion: 'iaVersion',
  homepageZipcode: 'lsHomepageZipcode',
  hasUploadedVehicleRegistration: 'lsHasUploadedVehicleRegistration',
  lastZIP: 'lsLastZip'
};

export default localStorageKeyring;

const pagesBothPlatforms = [
  {
    targetPage: '/charger-location',
    titleText: 'chargerLocation',
  },
  {
    targetPage: '/my-charger',
    titleText: 'myCharger',
  },
  {
    targetPage: '/my-utility',
    titleText: 'myUtility',
  },
  {
    targetPage: '/my-ev',
    titleText: 'myElectricVehicle',
  },
];

const pagesDesktopOnly = [
  {
    targetPage: '/user-profile',
    titleText: 'myProfile',
  },
];

export {
  pagesBothPlatforms,
  pagesDesktopOnly,
};

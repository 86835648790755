import React from 'react';
import PropTypes from 'prop-types';

const UploadIcon = ({ color = "#000000" }) => {
  return (
    <svg
      width="19"
      height="22"
      viewBox="0 0 19 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.9142 0H2.5C1.39543 0 0.5 0.89543 0.5 2V20C0.5 21.1046 1.39543 22 2.5 22H16.5C17.6046 22 18.5 21.1046 18.5 20V5.58579L12.9142 0ZM2.5 2H10.5V6C10.5 7.10457 11.3954 8 12.5 8H16.5V20H2.5V2ZM12.5 6V2.41421L16.0858 6H12.5ZM10.5 17V12.4142L11.7929 13.7071L13.2071 12.2929L9.5 8.58579L5.79289 12.2929L7.20711 13.7071L8.5 12.4142V17H10.5Z"
        fill={color}
      />
    </svg>
  );
};

UploadIcon.propTypes = {
  color: PropTypes.string,
};

export default UploadIcon;

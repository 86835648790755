import React from "react";
import PropTypes from "prop-types";

import PhotoProcessorInstrux from "../submodules/PhotoProcessorInstrux";
import UploadPhoto from "./submodules/UploadPhoto/UploadPhoto";

import maybeRenderTooltip from "../../../../../utils/functions/componentRenderLogic/maybeRenderTooltip";
import UploadIcon from '../../../../../customizations/assets/images/icons/UploadIcon';

const PhotoProcessorDesktop = ({
  id,
  topMessage,
  bottomMessage,
  tooltip,
  onChange,
  photoUploaded,
  showWarning,
}) => {
  return (
    <>
      <div className="camera-case position-relative">
        <div className="thumbnail d-flex align-items-center justify-content-center">
          <UploadIcon />
        </div>
        <div className="row main-content align-items-center no-gutters">
          <div className="col-lg-6">
            <div className="p-2">
              <PhotoProcessorInstrux
                topMessage={topMessage}
                bottomMessage={bottomMessage}
                id={id}
              />
            </div>
          </div>
          <div className="col-lg-6 d-flex align-items-center">
            {tooltip && (
              <div className="tooltip-wrapper ml-auto p-2">
                {maybeRenderTooltip(tooltip, id)}
              </div>
            )}
            <div className={`p-2 ${tooltip ? '' : 'ml-auto'}`}>
              <UploadPhoto
                id={id}
                onChange={onChange}
                isUploaded={photoUploaded}
              />
            </div>
          </div>
        </div>
      </div>
      {(showWarning && !photoUploaded) && <div className="warning">Field is required</div> }
    </>
  );
};

export default PhotoProcessorDesktop;

PhotoProcessorDesktop.defaultProps = {
  tooltip: "",
};

PhotoProcessorDesktop.propTypes = {
  id: PropTypes.string.isRequired,
  bottomMessage: PropTypes.string.isRequired,
  topMessage: PropTypes.string.isRequired,
  tooltip: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  photoUploaded: PropTypes.bool.isRequired,
  showWarning: PropTypes.bool,
};

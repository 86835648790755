const { protocol, hostname, pathname } = window.location;

const pathfinder = {
  rootURL: `${protocol}//${hostname}`,
  path: pathname,
};

const findHostNameFromConfig = () => {
  const env = process.env.REACT_APP_ENVIRONMENT;

  if(!env) {
    return 'http://localhost:3000';
  } 

  // @TODO use process.env
  return process.env.REACT_APP_SITE_PUBLIC_URL;
};

export {
  findHostNameFromConfig,
  pathfinder
};

import React from 'react';
import PropTypes from 'prop-types';

import TextField from '../../../../Atoms/Inputs/TextField/TextField';
import FieldGroup from '../../../../Atoms/FieldGroup/FieldGroup';

import { getTranslatedMessageFromID } from '../../../../../utils/functions/intl/intlWrapper';
import { validateEmail } from '../../../../Atoms/Inputs/functions/validate';

const Email = (
  {
    user,
    updateUser,
    language,
    hasInvalidInput,
    inputComponentsMustValidate,
  },
) => {
  const leftField = (
    <TextField
      validate={validateEmail}
      className="TextInput"
      fieldName="email"
      defaultValue={getTranslatedMessageFromID('userProfile.emailAddress', language)}
      legend={getTranslatedMessageFromID('preferredEmailAddress', language)}
      fieldWidth="100%"
      inputComponentsMustValidate={inputComponentsMustValidate}
      hasInvalidInput={hasInvalidInput}
      propsCollection={user}
      updatePropsCollection={updateUser}
    />
  );

  return (
    <FieldGroup
      language={language}
      leftField={leftField}
    />
  );
};

export default Email;

Email.propTypes = {
  user: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  updateUser: PropTypes.func.isRequired,
  language: PropTypes.string.isRequired,
  hasInvalidInput: PropTypes.func.isRequired,
  inputComponentsMustValidate: PropTypes.bool.isRequired,
};

import React from 'react';
import PropTypes from 'prop-types';

import pipe from '../../../../customizations/assets/images/pipe.svg';


const TitleNode = (
  { 
    titleText,
    statusImage
  }
) => {

  return (
    <div className={"row checkmark"}>
      <div className="status-symbol">
        <div className="status-image">
          <img
            src={statusImage}
            alt="statusImage"
            width="20"
            height="20"
          />
        </div>
        <div className="status-pipe">
          <img
            src={pipe}
            alt=""
          />
        </div>
      </div>
      <span className="title-text">
        {titleText}
      </span>
    </div>
  );
};

export default TitleNode;

TitleNode.propTypes = {
  titleText: PropTypes.string.isRequired,
  statusImage: PropTypes.any.isRequired
};

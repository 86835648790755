// @TODO can this also be used for vehicle? - yep, rename to myEquipmentPromise

import { mapEquipment } from '../mapStateDataToRequestBody';
import contactAmplifyEndpoint from '../../../../contactEndpoint/contactAmplifyEndpoint';
import { triggerAppWaitingMode, triggerGetUserData } from '../pageCycles/pageCycleManager';
import processVehicleAndChargerData from '../../../../responseHandlers/processVehicleAndChargerData';
import filterUnfilledImages from '../../filterUnfilledImages';
import filterByField from '../../../../../utils/functions/misc/filterByField';
import logger from '../../../../../utils/functions/logging/logger';

const myEquipmentPromise = ({
  canSkipValidation,
  fetchUserData,
  equipment,
  currentAddress,
  setDisplayPending,
  triggerAllInputComponentsValidPromise,
  dispatch,
  alertAPIError,
}) => {
  const mappedEquipment = mapEquipment({ ...equipment });

  mappedEquipment[0].images = filterUnfilledImages(mappedEquipment[0].images);
  mappedEquipment[0].incentives = [];

  // @TODO for now... this is sure to be revisited (which address to use?)

  if (mappedEquipment[0].type === 'vehicle') {
    mappedEquipment[0].address_ids = [currentAddress.address_id];
    mappedEquipment[0].details.fuel = 'BEV';
  }

  const method = equipment.equipment_id.toString() === '0' ? 'post' : 'put';

  const corePromise = (newUserObject) => {
    return new Promise((resolve, reject) => {
      const sortEquipment = (a, b) => {
        if (a > b) {
          return -1;
        }

        if (a < b) {
          return 1;
        }

        return 0;
      };

      let targetEquipment = [
        {
          images: {},
        },
      ];

      if (method === 'put') {
        if (mappedEquipment[0].equipment_id) {
          targetEquipment = filterByField('equipment_id', mappedEquipment[0].equipment_id, newUserObject.equipment);
        } else {
          targetEquipment = newUserObject.equipment.sort(sortEquipment);
        }
      }

      mappedEquipment[0].images = targetEquipment[0].images;

      contactAmplifyEndpoint(
        '/user/equipment',
        method,
        {
          body: mappedEquipment,
        },
        alertAPIError
      )
        .then((response) => {
          processVehicleAndChargerData(response, dispatch);
          // @TODO update equipment id based on response
          resolve();
        })
        .catch((err) => {
          reject(err);
        })
        .finally(() => {
          // @TODO could be I need to call this before I resolve or reject?
          triggerGetUserData({
            setDisplayPending,
          });
        });
    });
  };

  return new Promise((resolve, reject) => {
    triggerAllInputComponentsValidPromise(canSkipValidation).then(() => {
      triggerAppWaitingMode({
        setDisplayPending,
      });

      fetchUserData()
        .then((newUserObject) => {
          corePromise(newUserObject)
            .then(() => {
              resolve();
            })
            .catch((err) => {
              logger(err, 'myEquipmentPromise');
            });
        })
        .catch((err) => {
          logger(err, 'myEquipmentPromise');
          reject(err);
        });
    });
  });
};

export default myEquipmentPromise;

import { checkFieldCompleted } from "../../../../utils/fields/checkFieldCompleted";
import parsePhoneNumber, { isValidPhoneNumber } from 'libphonenumber-js';

const checkFieldNotEmpty = (fieldValue) => {
  if(!checkFieldCompleted(fieldValue)) {
    throw new Error('Field is required');
  }
};

const validateEmail = (mightBeEmail) => {
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  
  if(!re.test(String(mightBeEmail).toLowerCase())) {
    throw new Error('Not a valid email address');
  }
};

const validatePhoneNumber = (mightBePhoneNumber) => {
  const formattedPhoneNumber = parsePhoneNumber(mightBePhoneNumber, 'US');
  const warning = 'Not a valid phone number';

  if (!formattedPhoneNumber) {
    throw new Error(warning);
  }

  if(!isValidPhoneNumber(formattedPhoneNumber.number)) {
    throw new Error(warning);
  }
};

const validateVin = (mightBeVIN) => {
  var vinReg = new RegExp('[A-Z0-9]{17}');
  if (!vinReg.test(mightBeVIN)) {
    throw new Error('Invalid VIN');
  }
};

const validateZip = (mightBeZipcode) => {
  const zipRegExp = new RegExp('^[0-9]{5}$');

  if(!zipRegExp.test(mightBeZipcode)) {
    throw new Error('Invalid ZIP');
  }
};


export {
  checkFieldNotEmpty,
  validateEmail,
  validateVin,
  validatePhoneNumber,
  validateZip
};

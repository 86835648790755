const userProfile = {
  email: '',
  first_name: '',
  last_name: '',
  mobile_phone: '',
  home_phone: '',
  primary_address_id: -1,
  signature: '',
  household_income: 0,
  number_household_members: 0,
  number_household_members_17_older: 0,
  'font-family': '',
};

const address = {
  address_id: -1,
  street_address: '',
  municipality: '',
  region: '',
  country: '',
  postcode: '',
  active: true,
  owner: true,
  power_supplier: {
    name: '',
    account_number: '',
  },
};

const image = {
  assetId: '',
  url: '',
};

const inputField = {
  name: '',
  type: '',
  default_value: '',
  user_input: '',
};

const incentive = {
  name: '',
  id: 0,
  incentive_id: {},
  status: '',
  apply_date: '',
  apply_completion_date: '',
  disable_alert: true,
  grantor: '',
  grantor_type: '',
  description: '',
  type: '',
  support_for: '',
  typical_amount: '',
  limitations: '',
  details_url: '',

  // Additional input fields specific to the incentive
  input_fields: [],
  completed_application_url: '',
  INCENTIVE_VERSION: '',
  images: {},
};

const charger = {
  equipment_id: '0',
  address_ids: [],
  details: {
    make: '',
    model: '',
    trim: '',
    serial_number: '',
    purchase_price: 0,
    purchase_date: '',
    fuel: '',
    model_year: 0,
  },
  type: 'charger',
  incentives: [],
  images: {},
};

const vehicle = {
  equipment_id: '0',
  address_ids: [],
  details: {
    make: '',
    model: '',
    trim: '',
    vin: '',
    purchase_price: 0,
    purchase_date: '',
    fuel: '',
    model_year: '',
  },
  type: 'vehicle',
  incentives: [],
  images: {},
};

export {
  userProfile,
  address,
  charger,
  vehicle,
  incentive,
  inputField,
  image,
};

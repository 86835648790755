import React, { createContext, useReducer } from 'react';
import cloneDeep from 'lodash/cloneDeep';

import INITIAL_EQUIPMENT_STATE from '../../constants/INITIAL_EQUIPMENT_STATE';

const EquipmentState = (state, action) => {
  const newGlobalState = {
    ...state,
  };

  switch (action.type) {
    case 'SET_VEHICLES':
      const Vehicles = action.payload;
      newGlobalState.Vehicles = Vehicles;

      return newGlobalState;
    case 'SET_CHARGERS':
      const Chargers = action.payload;
      newGlobalState.Chargers = Chargers;

      return newGlobalState;

    case 'RESET':
      return cloneDeep(INITIAL_EQUIPMENT_STATE);

    default:
      return state;
  }
};

// eslint-disable-next-line react/prop-types
const Equipment = ({ children }) => {
  const [state, dispatch] = useReducer(EquipmentState, cloneDeep(INITIAL_EQUIPMENT_STATE));

  return <EquipmentContext.Provider value={[state, dispatch]}>{children}</EquipmentContext.Provider>;
};

export const EquipmentContext = createContext(cloneDeep(INITIAL_EQUIPMENT_STATE));
export { Equipment, EquipmentState };

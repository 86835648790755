import React from 'react';
import PropTypes from 'prop-types';

import SectionWrapper from '../../../Atoms/SectionWrapper/SectionWrapper';
import MobileNoIncentivesAvailableContent from '../submodules/MobileNoIncentivesAvailableContent';
import DesktopNoIncentivesAvailableContent from '../submodules/DesktopNoIncentivesAvailableContent';

const NoIncentivesAvailableContent = ({
  language,
  user,
  hasInvalidInput,
  inputComponentsMustValidate,
}) => {

  // To be replaced when backend is more clear
  const notifyIfNoIncentives = {
    notifyEmail: false,
    notifyText: false
  };

  const validateFunc = (isChecked) => {
    if (!isChecked) {
      // Error message should be a prop we can pass to an input form element.
      throw new Error('We need agreement on accepting terms and conditions before we can proceed');
    }
  };

  const mobileSection = (
    <MobileNoIncentivesAvailableContent
      notifyIfNoIncentives={notifyIfNoIncentives} 
      validateFunc={validateFunc} 
      language={language}
      user={user}
      inputComponentsMustValidate={inputComponentsMustValidate}
      hasInvalidInput={hasInvalidInput}
    />
  );

  const desktopSection = (
    <DesktopNoIncentivesAvailableContent 
      notifyIfNoIncentives={notifyIfNoIncentives} 
      validateFunc={validateFunc} 
      language={language}
      user={user}
      inputComponentsMustValidate={inputComponentsMustValidate}
      hasInvalidInput={hasInvalidInput}
    />
  );

  return (
    <SectionWrapper
      mobileSection={mobileSection}
      desktopSection={desktopSection}
      language={language}
      sectionClassName="NoIncentivesAvailableContent"
      isContainer={false}
    />

  );
};

export default NoIncentivesAvailableContent;

NoIncentivesAvailableContent.propTypes = {
  language: PropTypes.string.isRequired,
  user: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  equipment: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  vehicle: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  currentAddress: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  setCurrentIncentiveByID: PropTypes.func.isRequired,
  hasInvalidInput: PropTypes.func.isRequired,
  inputComponentsMustValidate: PropTypes.bool.isRequired,
};

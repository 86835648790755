import React from 'react';
import PropTypes from 'prop-types';
import buildSubDrawers from '../../../DashboardApplicationProgress/ProgressReviewDrawer/functions/buildSubDrawers';

const TabletProfileModalBody = ({
  equipment,
  user,
  vehicle,
  currentAddress,
  language,
}) => {
  return (
    <div className="TabletModalBody">
      {buildSubDrawers(
        language,
        user,
        equipment,
        vehicle,
        currentAddress,
        true
      )}
    </div>
  );
};

export default TabletProfileModalBody;

TabletProfileModalBody.propTypes = {
  language: PropTypes.string.isRequired,
  user: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  equipment: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  vehicle: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  currentAddress: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
};

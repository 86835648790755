import React, { useContext } from 'react';
import SaveReturn from './SaveReturn';
import loadToGlobalState from '../../../apiInterface/responseHandlers/loadToGlobalState';
import { UserContext } from '../../IaaUserEntity/IaaUserEntity';

const SubmitReturn = () => {
  const [, dispatch] = useContext(UserContext);

  setTimeout(() => {
    loadToGlobalState(dispatch, {
      type: 'SET_INCENTIVE_COMPLETE_MESSAGE',
      payload: true
    });  
  }, 1000);


  return (
    <SaveReturn />
  );
};

export default SubmitReturn;

import React from 'react';
import PropTypes from 'prop-types';

// @TODO include top progress bar conditionally.
const ContentContainingChildNodeMobile = (
  {
    children,
    // user,
    // equipment,
    // language,
  },
) => (
  <>
    {children}
  </>
);

export default ContentContainingChildNodeMobile;

ContentContainingChildNodeMobile.propTypes = {
  children: PropTypes.node.isRequired,
  // user: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  // equipment: PropTypes.array.isRequired, // eslint-disable-line react/forbid-prop-types
  // language: PropTypes.string.isRequired,
};

const queryParamsFromURL = () => window.location.search;

const queryParamsToObject = () => {
  const query = queryParamsFromURL();
  if (!query) {
    return {};
  }

  const truncatedQuery = query.substring(1);

  const keyVals = truncatedQuery.split('&');
  const queryObj = {};
  keyVals.forEach((keyVal) => {
    const splitKeyVal = keyVal.split('=');
    const key = splitKeyVal[0];
    const val = splitKeyVal[1] || 'true'; // For those cases where the param doesn't = anything.

    queryObj[key] = val;
  });

  return queryObj;
};

const extractQueryParam = (para) => queryParamsToObject()[para];

export {
  extractQueryParam,
  queryParamsToObject,
  queryParamsFromURL,
};

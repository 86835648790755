import React from 'react';
import PropTypes from 'prop-types';

import TextField from '../../../../Atoms/Inputs/TextField/TextField';

import { getTranslatedMessageFromID } from '../../../../../utils/functions/intl/intlWrapper';
import { checkFieldNotEmpty } from '../../../../Atoms/Inputs/functions/validate';

const SignatureTypeYourName = (
  {
    user,
    updateUser,
    language,
    hasInvalidInput,
    inputComponentsMustValidate,
  },
) => (
  <>
    <TextField
      validate={checkFieldNotEmpty}
      className="TextInput"
      fieldName="signature"
      defaultValue={getTranslatedMessageFromID('userProfile.signature', language)}
      legend={getTranslatedMessageFromID('signature', language)}
      fieldWidth="100%"
      inputComponentsMustValidate={inputComponentsMustValidate}
      hasInvalidInput={hasInvalidInput}
      propsCollection={user}
      updatePropsCollection={updateUser}
    />
  </>
);

export default SignatureTypeYourName;

SignatureTypeYourName.propTypes = {
  user: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  updateUser: PropTypes.func.isRequired,
  language: PropTypes.string.isRequired,
  hasInvalidInput: PropTypes.func.isRequired,
  inputComponentsMustValidate: PropTypes.bool.isRequired,
};

import React from 'react';
import PropTypes from 'prop-types';

import chevronUp from '../../../../customizations/assets/images/chevronUp.svg';
import chevronDown from '../../../../customizations/assets/images/chevronDown.svg';

import selectString from '../../../../utils/functions/misc/selectString';

const Chevron = (
  {
    isExpanded,
  },
) => (
  <img
    src={selectString(isExpanded, chevronUp, chevronDown)}
    alt={selectString(isExpanded, 'chevron up', 'chevron down')}
  />
);

export default Chevron;

Chevron.propTypes = {
  isExpanded: PropTypes.bool.isRequired,
};

import React from 'react';
import PropTypes from 'prop-types';

import ApplicationAssistantLayout from '../../ApplicationAssistantLayout/ApplicationAssistantLayout';
import SubmitApplicationMainContent from './MainContent/SubmitApplicationMainContent';

const SubmitApplication = (
  {
    setCurrentIncentiveByID,
    language,
    currentPage,
    handlePageForwardClick,
  },
) => (
  <ApplicationAssistantLayout
    language={language}
    currentPage={currentPage}
    setCurrentIncentiveByID={setCurrentIncentiveByID}
    handlePageForwardClick={handlePageForwardClick}
  >
    <SubmitApplicationMainContent
      language={language}
    />
  </ApplicationAssistantLayout>
);

export default SubmitApplication;

SubmitApplication.defaultProps = {
  handlePageForwardClick: () => {},
};

SubmitApplication.propTypes = {
  setCurrentIncentiveByID: PropTypes.func.isRequired,
  language: PropTypes.string.isRequired,
  currentPage: PropTypes.string.isRequired,
  handlePageForwardClick: PropTypes.func,
};

import React from 'react';
import PropTypes from 'prop-types';

const FieldGroupMobile = ({
  leftField = <></>,
  rightField = <></>,
}) => (
  <div className="fieldGoup">
    {leftField}
    {rightField}
  </div>
);

export default FieldGroupMobile;

FieldGroupMobile.defaultProps = {
  leftField: <></>,
  rightField: <></>,
};

FieldGroupMobile.propTypes = {
  leftField: PropTypes.node,
  rightField: PropTypes.node,
};

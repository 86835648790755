import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import DatePicker from 'react-datepicker';

import RequiredFieldIndicator from '../RequiredFieldIndicator/RequiredFieldIndicator';

import "react-datepicker/dist/react-datepicker.css";
import "./css/DateInput.scss";

import { checkForValidation } from '../functions/inputHelper';

const convertToDate = value => {
  if (!value) return value;

  const asDate = new Date(value);
  if (asDate instanceof Date && !isNaN(asDate)) {
    const timezoneAdjustedDate = new Date(
      asDate.getTime() + (asDate.getTimezoneOffset() * 60000)
    );
    return timezoneAdjustedDate;
  } else {
    throw new Error(`DateInput received a bad date value: ${value}`);
  }
};

const DateInput = (
  {
    validate,
    fieldName,
    propsCollection,
    updatePropsCollection,
    defaultValue,
    legend,
    className,
    inputComponentsMustValidate,
    hasInvalidInput,
  },
) => {
  const [warning, setWarning] = useState('');

  let value = propsCollection[fieldName];
  const date = convertToDate(value);

  useEffect(() => {
    const validateOnCheck = () => {
      if (inputComponentsMustValidate) {
        checkForValidation(
          validate,
          value,
          defaultValue,
          setWarning,
          fieldName,
          hasInvalidInput
        );
      }
    };

    validateOnCheck();
  });

  return (
    <div className={`${className} IAAInput`}>
      <div className="inputLegend">
        {legend}
        <RequiredFieldIndicator
          fieldName={fieldName}
        />
      </div>
      <DatePicker
        id={`input_${fieldName}`}
        className="inputField inputDate"
        dateFormat="MM/dd/yyyy"
        selected={date}
        onChange={(newDate) => {
          const newValue = newDate ? newDate.toISOString().substring(0,10) : newDate;
          updatePropsCollection(newValue, fieldName);
        }}
        customInput={<input inputMode="none" />}
      />
      <div className="warning">{warning}</div>
    </div>
  );
};

export default DateInput;

DateInput.defaultProps = {
  validate: () => {},
  className: '',
  defaultValue: '',
  legend: '',
};

DateInput.propTypes = {
  validate: PropTypes.func,
  className: PropTypes.string,
  fieldName: PropTypes.string.isRequired,
  defaultValue: PropTypes.string,
  legend: PropTypes.string,
  inputComponentsMustValidate: PropTypes.bool.isRequired,
  hasInvalidInput: PropTypes.func.isRequired,
  propsCollection: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  updatePropsCollection: PropTypes.func.isRequired,
};

import { extractQueryParam } from "../locationBar/queryParams";

const logger = (msg, caller) => {

  const debugMode = process.env.NODE_ENV === 'development' 
  || extractQueryParam('debugmode') === 'true';

  if(!debugMode) {
    return;
  }

  console.table(caller, msg, new Date().getTime());
};

export default logger;

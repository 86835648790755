import React from 'react';
import { Button } from 'reactstrap';
import PropTypes from 'prop-types';

import logger from '../../../utils/functions/logging/logger';

// @TODO should we actually call these atoms?
// And what we're calling atoms within pages may be better known as LowLevelComponents

const IAAButton = ({
  className,
  color = "primary",
  onClick,
  label,
  children,
  size = "lg",
  ...rest
}) => (
  <Button
    color={color}
    type="button"
    className={`IAAButton ${className}`}
    label={label}
    onClick={onClick}
    size={size}
    {...rest}
  >
    {children}
  </Button>
);

export default IAAButton;

IAAButton.defaultProps = {
  onClick: () => { logger('click', 'IAAButton'); }, 
};

IAAButton.propTypes = {
  color: PropTypes.string,
  className: PropTypes.string,
  label: PropTypes.string,
  onClick: PropTypes.func,
  children: PropTypes.node.isRequired,
  size: PropTypes.string,
};

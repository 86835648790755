import React from 'react';
import PropTypes from 'prop-types';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import { useHistory } from 'react-router-dom';

import IAAButton from '../Atoms/Buttons/IAAButton';

import { getTranslatedMessageFromID } from '../../utils/functions/intl/intlWrapper';

const SessionTimeoutErrorNotifier = (
  {
    setSessionTimeoutError,
    sessionTimeoutError,
    language
  }
) => {
  const history = useHistory(); 

  const toggle = () => {
    setSessionTimeoutError(false);
  };

  const handleClick = () => {
    toggle();
    history.push('/welcome');
  };

  return (
    <Modal
      isOpen={sessionTimeoutError}
    >
      <ModalHeader>{getTranslatedMessageFromID('sessionTimeoutModal.header', language)}</ModalHeader>
      <ModalBody>
        <p>{getTranslatedMessageFromID('sessionTimeoutModal.para1', language)}</p>
        <p>{getTranslatedMessageFromID('sessionTimeoutModal.para2', language)}</p>
        <div className="row">
          <div className="col-md-5 col-7 offset-md-7 offset-5">
            <IAAButton
              onClick={handleClick}
            >{getTranslatedMessageFromID('sessionTimeoutModal.buttonText', language)}</IAAButton>
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default SessionTimeoutErrorNotifier;

SessionTimeoutErrorNotifier.propTypes = {
  setSessionTimeoutError: PropTypes.func.isRequired,
  sessionTimeoutError: PropTypes.bool.isRequired,
  language: PropTypes.string.isRequired
};

import React from 'react';
import PropTypes from 'prop-types';

import PhotoProcessorInstrux from '../submodules/PhotoProcessorInstrux';
import TakeAPhoto from './submodules/TakeAPhoto/TakeAPhoto';

import maybeRenderTooltip from '../../../../../utils/functions/componentRenderLogic/maybeRenderTooltip';

const PhotoProcessorMobile = (
  {
    id,
    topMessage,
    bottomMessage,
    tooltip,
    onChange,
    photoUploaded,
    showWarning,
  },
) => {
  return (
    <>
      <div className="camera-case text-center">
        <PhotoProcessorInstrux
          id={id}
          topMessage={topMessage}
          bottomMessage={bottomMessage}
        />
        <div className="button-wrapper">
          <TakeAPhoto
            id={id}
            onChange={onChange}
            isUploaded={photoUploaded}
          />
        </div>
        <div className="tooltip-wrapper">
          {maybeRenderTooltip(tooltip, id)}
        </div>
      </div>
      {(showWarning && !photoUploaded) && <div className="warning">Field is required</div>}
    </>
  );
};

export default PhotoProcessorMobile;

PhotoProcessorMobile.defaultProps = {
  tooltip: '',
};

PhotoProcessorMobile.propTypes = {
  id: PropTypes.string.isRequired,
  bottomMessage: PropTypes.string.isRequired,
  topMessage: PropTypes.string.isRequired,
  tooltip: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  photoUploaded: PropTypes.bool.isRequired,
  showWarning: PropTypes.bool,
};

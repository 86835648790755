import React from 'react';
import PropTypes from 'prop-types';

import IAACheckbox from '../../Atoms/Inputs/Checkbox/IAACheckbox';

import { getTranslatedMessageFromID } from '../../../utils/functions/intl/intlWrapper';

import '../../Atoms/Inputs/css/input-1.scss';
import '../../Atoms/Inputs/Checkbox/css/iaa-checkbox.scss';

const ExampleCheckbox = (
  {
    language,
    inputComponentsMustValidate,
    hasInvalidInput,
    requiredCheckboxes,
    setRequiredCheckboxes,
  },
) => {
  // @TODO move to external js, also be able to set the warning text displayed.
  const validateFunc = (isChecked) => {
    if (!isChecked) {
      // Error message should be a prop we can pass to an input form element.
      throw new Error('We need agreement on accepting terms and conditions before we can proceed');
    }
  };

  // @TODO need to have a util for providing links within translations.
  return (
    <IAACheckbox
      fieldName="agreeToTerms"
      propsCollection={requiredCheckboxes}
      updatePropsCollection={setRequiredCheckboxes}
      validate={validateFunc}
      legend={getTranslatedMessageFromID('register.agreeToTerms', language)}
      inputComponentsMustValidate={inputComponentsMustValidate}
      hasInvalidInput={hasInvalidInput}
    />
  );
};

export default ExampleCheckbox;

ExampleCheckbox.propTypes = {
  language: PropTypes.string.isRequired,
  inputComponentsMustValidate: PropTypes.bool.isRequired,
  hasInvalidInput: PropTypes.func.isRequired,
  requiredCheckboxes: PropTypes.objectOf(PropTypes.bool).isRequired,
  setRequiredCheckboxes: PropTypes.func.isRequired,
};

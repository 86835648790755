/* eslint jsx-a11y/label-has-associated-control: 0 */

import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import UploadIcon from '../../../../../../../customizations/assets/images/icons/UploadIcon';

// import IAAButton from '../../../../../Buttons/IAAButton';

// @TODO add function to be called on click. In this case a camera interface.
const TakeAPhoto = (
  {
    id,
    isUploaded = false,
    onChange,
  },
) => {
  return (
    <>
      <label htmlFor={id} className="mb-0">
        <div className={`btn btn-lg btn-${isUploaded ? 'success' : 'light'} IAAButton`}>
          <div className="d-inline-block mr-2">
            <UploadIcon color={isUploaded ? "#ffffff" : "#000000"} />
          </div>
          {isUploaded && (
            <FormattedMessage id="photoReceived" />
          )}
          {!isUploaded && (
            <>
              <span className="d-none d-sm-inline-block">
                <FormattedMessage id="uploadPhoto" />
              </span>
              <span className="d-inline-block d-sm-none">
                <FormattedMessage id="takeAPhoto" />
              </span>
            </>
          )}
        </div>
      </label>
      <input
        type="file"
        accept="image/*,.pdf"
        capture="environment"
        className="photo-input d-none"
        id={id}
        onChange={onChange}
      />
    </>
  );
};

export default TakeAPhoto;

TakeAPhoto.propTypes = {
  id: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  isUploaded: PropTypes.bool,
};

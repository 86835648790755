import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';

import { UserContext } from '../IaaUserEntity/IaaUserEntity';
import loadToGlobalState from '../../apiInterface/responseHandlers/loadToGlobalState';

import checkCircle from '../../customizations/assets/images/checkCircle.svg';
import { getTranslatedMessageFromID } from '../../utils/functions/intl/intlWrapper';

import './css/congrats.scss';

const Congrats = ({
  language
}) => {

  const [iaaUser, dispatch] = useContext(UserContext);
  let isOpen = iaaUser.incentiveCompleteMessage;

  const toggle = () => {
    loadToGlobalState(dispatch, {
      type: 'SET_INCENTIVE_COMPLETE_MESSAGE',
      payload: false
    });    
  };

  return (
    <Modal isOpen={isOpen} toggle={toggle} className="congratsModal">
      <ModalHeader toggle={toggle} />
      <ModalBody>
        <div className="text-center">
          <img src={checkCircle} alt="check" width="44px" height="44px" />
        </div>
        <div className="text-center">
          <h2>
            {getTranslatedMessageFromID("congratsModal.congrats", language)}
          </h2>
        </div>
        <div className="text-center text-bucket">
          {getTranslatedMessageFromID("congratsModal.message", language)}{" "}
          <strong>
            <a
              href="mailto:veloz-iaa-support@zappy.freshdesk.com"
              className="strong"
            >
              veloz-iaa-support@zappy.freshdesk.com
            </a>
          </strong>
          .
        </div>
      </ModalBody>
    </Modal>
  );

};

export default Congrats;

Congrats.propTypes = {
  language: PropTypes.string.isRequired
};

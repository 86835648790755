const equipmentData = {
  code: 0,
  message: 'string',
  equipment:
    {
      equipment_id: 0,
      address_ids: [
        0,
      ],
      details: {
        make: 'string',
        model: 'string',
        trim: 'string',
        vin: 'string',
        purchase_price: 0,
        purchase_date: '2021-02-01',
        fuel: 'BEV',
        model_year: 0,
        images: {
          vehicle_registration: {
            size: 'string',
            url: 'string',
          },
          VEHICLE_LICENSE_PLATE: {
            size: 'string',
            url: 'string',
          },
        },
      },
      type: 'charger',
      incentives: [
        {
          name: 'Incentive Title',
          id: 0,
          incentive_id: {},
          status: 'available',
          apply_date: 'string',
          apply_completion_date: 'string',
          disable_alert: true,
          grantor: 'string',
          grantor_type: 'Air Quality District',
          description: 'Here is a lengthy description of the incentive. Sometimes a lot needs to be said. And we daren\'t shorten it because we could find ourselves on the wrong side of the law if we leave anything out. So we may have an incentive description that goes on for hundreds of pixels. What shall we do??? Well I suppose we could add a show and hide toggle.',
          type: 'Annual fee',
          support_for: 'string',
          typical_amount: '500',
          limitations: 'string',
          details_url: 'string',
          custom_input_fields: [
            {
              name: 'string',
              type: 'string',
              default_value: 'string',
              user_input: 'string',
            },
          ],
          completed_application_url: 'string',
        },
        {
          name: 'Incentive Title 2',
          id: 1,
          incentive_id: {},
          status: 'applicationIncomplete',
          apply_date: 'string',
          apply_completion_date: 'string',
          disable_alert: true,
          grantor: 'string',
          grantor_type: 'Air Quality District',
          description: 'Here is a lengthy description of the incentive. Sometimes a lot needs to be said. And we daren\'t shorten it because we could find ourselves on the wrong side of the law if we leave anything out. So we may have an incentive description that goes on for hundreds of pixels. What shall we do??? Well I suppose we could add a show and hide toggle.',
          type: 'Annual fee',
          support_for: 'string',
          typical_amount: '500',
          limitations: 'string',
          details_url: 'string',
          custom_input_fields: [
            {
              name: 'string',
              type: 'string',
              default_value: 'string',
              user_input: 'string',
            },
          ],
          completed_application_url: 'string',
        },
        {
          name: 'Incentive Title 3',
          id: 2,
          incentive_id: {},
          status: 'applied',
          apply_date: 'string',
          apply_completion_date: 'string',
          disable_alert: true,
          grantor: 'string',
          grantor_type: 'Air Quality District',
          description: 'Here is a lengthy description of the incentive. Sometimes a lot needs to be said. And we daren\'t shorten it because we could find ourselves on the wrong side of the law if we leave anything out. So we may have an incentive description that goes on for hundreds of pixels. What shall we do??? Well I suppose we could add a show and hide toggle.',
          type: 'Annual fee',
          support_for: 'string',
          typical_amount: '500',
          limitations: 'string',
          details_url: 'string',
          custom_input_fields: [
            {
              name: 'string',
              type: 'string',
              default_value: 'string',
              user_input: 'string',
            },
          ],
          completed_application_url: 'string',
        },
      ],
    },
};

export default equipmentData;

import React from 'react';
import PropTypes from 'prop-types';

import ApplicationStepsNavDesktop from '../../Nav/ApplicationStepsNav/ApplicationStepsNavDesktop';

import '../../Nav/ApplicationStepsNav/css/ApplicationStepsNav.scss';
import '../../Atoms/ApplicationStep/css/ApplicationStepDesktop.scss';

const ExampleApplicationStepsDesktop = (
  {
    language,
  },
) => (
  <ApplicationStepsNavDesktop
    currentPage="/user-profile"
    language={language}
  />
);

export default ExampleApplicationStepsDesktop;

ExampleApplicationStepsDesktop.propTypes = {
  language: PropTypes.string.isRequired,
};

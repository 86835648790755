import React from 'react';

import HomePageNotLoggedInBlurb from '../../Pages/Homepage/SubComponents/HomePageNotLoggedIn/Atoms/HomePageNotLoggedInBlurb';

import '../../Pages/Homepage/SubComponents/HomePageNotLoggedIn/css/customizations/homePageNotLoggedIn.scss';

const ExampleBlurb = () => (
  <div className="exampleBlurb">
    <HomePageNotLoggedInBlurb
      headlineTranslateId="homepage.createASecureAccount"
      bodyTranslateId="homepage.encryptionWeUse"
    />
  </div>
);

export default ExampleBlurb;

import { getTranslatedMessageFromID } from '../../../../../utils/functions/intl/intlWrapper';

const setClassName = (isExpanded) => (
  isExpanded
    ? 'expanded'
    : 'collapsed'
);

const seeMoreOrSeeLess = (isExpanded, language) => {
  const messageId = isExpanded
    ? 'card.seeless'
    : 'card.seemore';

  return getTranslatedMessageFromID(messageId, language);
};

export {
  setClassName,
  seeMoreOrSeeLess,
};

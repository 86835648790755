import React from 'react';
import PropTypes from 'prop-types';

import IAAButton from '../../../Buttons/IAAButton';

import { getTranslatedMessageFromID } from '../../../../../utils/functions/intl/intlWrapper';

import buttonText from './data/buttonText';

// @TODO move to IncentiveCard

const IncentiveCardButton = (
  {
    language,
    cardBottomType,
    incentiveID,
    onClick,
  },
) => { 

  return(
    <div
      className={`IncentiveCardButton_${cardBottomType}`}
    >
      <IAAButton
        onClick={onClick}
        label={`incentiveCardButton_${incentiveID}`}
      >
        {getTranslatedMessageFromID(buttonText[cardBottomType], language)}
      </IAAButton>
    </div>
  );
};

export default IncentiveCardButton;

IncentiveCardButton.defaultProps = {
  cardBottomType: null
};

IncentiveCardButton.propTypes = {
  language: PropTypes.string.isRequired,
  cardBottomType: PropTypes.string,
  incentiveID: PropTypes.number.isRequired,
  onClick: PropTypes.func.isRequired,
};

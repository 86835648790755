import React from 'react';
import PropTypes from 'prop-types';
import { getTranslatedMessageFromID } from '../../../../../utils/functions/intl/intlWrapper';

const IncentiveDetailsLink = ({
  incentiveDetailsURL,
  language,
  cardBottomType
}) => {

  const learnMore = (<a 
    href={incentiveDetailsURL}
    target="_blank"
    rel="noreferrer"
  >{getTranslatedMessageFromID('card.learnmore', language)}</a>);

  const bottomLinks = cardBottomType === 'applied'
    ? (
      <div className="ApplicationStatusNote row">
        <div className="col-6 text-left">{learnMore}</div>
        <div className="col-6 text-right">
          <a href="contact-us">{getTranslatedMessageFromID('incentiveCard.contactGrantor', language)}</a>
        </div>
      </div>
    )
    : learnMore;

  return bottomLinks;

};

export default IncentiveDetailsLink;

IncentiveDetailsLink.propTypes = {
  incentiveDetailsURL: PropTypes.string.isRequired,
  language: PropTypes.string.isRequired,
  cardBottomType: PropTypes.string
};

import React from 'react';
import PropTypes from 'prop-types';

import ApplicationAssistantLayout from '../../ApplicationAssistantLayout/ApplicationAssistantLayout';
import MyElectricVehicleMainContent from './MainContent/MyElectricVehicleMainContent';

import '../../Atoms/Inputs/EditableDetails/css/EditableDetails.scss';

const MyElectricVehicle = ({
  language,
  currentPage,
  hasInvalidInput,
  inputComponentsMustValidate,
  triggerAllInputComponentsValidPromise,
  setInputComponentsMustValidate,
  setCurrentIncentiveByID,
  setDisplayPending,
  handlePageForwardClick,
  fetchUserData,
  alertAPIError
}) => (
  <ApplicationAssistantLayout
    language={language}
    currentPage={currentPage}
    setCurrentIncentiveByID={setCurrentIncentiveByID}
    previousPage="/my-utility"
    nextPage="/dashboard"
    previousPageButtonText="Back"
    nextPageButtonText="Submit"
    handlePageForwardClick={handlePageForwardClick}
  >
    <MyElectricVehicleMainContent
      language={language}
      hasInvalidInput={hasInvalidInput}
      inputComponentsMustValidate={inputComponentsMustValidate}
      triggerAllInputComponentsValidPromise={triggerAllInputComponentsValidPromise}
      setInputComponentsMustValidate={setInputComponentsMustValidate}
      setDisplayPending={setDisplayPending}
      fetchUserData={fetchUserData}
      alertAPIError={alertAPIError}
    />
  </ApplicationAssistantLayout>
);

export default MyElectricVehicle;

MyElectricVehicle.propTypes = {
  language: PropTypes.string.isRequired,
  currentPage: PropTypes.string.isRequired,
  hasInvalidInput: PropTypes.func.isRequired,
  inputComponentsMustValidate: PropTypes.bool.isRequired,
  setInputComponentsMustValidate: PropTypes.func.isRequired,
  setCurrentIncentiveByID: PropTypes.func.isRequired,
  setDisplayPending: PropTypes.func.isRequired,
  triggerAllInputComponentsValidPromise: PropTypes.func.isRequired,
  handlePageForwardClick: PropTypes.func.isRequired,
  fetchUserData: PropTypes.func.isRequired,
  alertAPIError: PropTypes.func
};

import PATH_TO_PAGE_NAME_MAP from '../../../constants/PATH_TO_PAGE_NAME_MAP';

// @TODO work in general toward simplifying with having ONE SINGLE PAGE DIRECTORY and
// no need to keep inventing isomorphisms between them.
// Most of the lines of this function are because there are TWO WAYS of representing the id of 
// a page.
const DetermineAllowedPages = (requestedPage, pagesKnownComplete) => {
  let targetPage = requestedPage;
  
  const completedPages = Array.from(pagesKnownComplete);
  
  const pageOrderMap = {
    'userProfile': 0,
    'chargerLocation': 1,
    'myCharger': 2,
    'myUtility': 3,
    'myVehicle': 4,
    'dashboard': 5,
    'completeApplication': 6,
    'submitApplication': 7
  };

  if((completedPages.length === Object.keys(pageOrderMap).length - 3) 
  && (requestedPage === '/welcome')) {
    return '/dashboard';
  }

  if(!pageOrderMap.hasOwnProperty(PATH_TO_PAGE_NAME_MAP[requestedPage])) {
    return targetPage;
  }

  if(!completedPages || completedPages.length === 0) {
    return '/user-profile';
  }

  const sortedCompletedPages = completedPages.sort((a, b) => {
    const pageAPos = pageOrderMap[a] || 0;
    const pageBPos = pageOrderMap[b] || 0;

    return pageAPos - pageBPos;
  });

  const lastCompletedPageNumber = sortedCompletedPages.length;
  const requestedPageNumber = pageOrderMap[PATH_TO_PAGE_NAME_MAP[requestedPage]];

  const targetPageNumber = (requestedPageNumber < lastCompletedPageNumber)
    ? requestedPageNumber
    : lastCompletedPageNumber;

  const pagePaths = Object.keys(PATH_TO_PAGE_NAME_MAP);
  

  for(let i = 0; i < pagePaths.length; i++) {
    const currentPageName = PATH_TO_PAGE_NAME_MAP[pagePaths[i]];

    if(pageOrderMap[currentPageName] === targetPageNumber) {
      targetPage = pagePaths[i];
      break;
    }
  }

  return targetPage;
};

export default DetermineAllowedPages;

const INITIAL_USER_STATE_DEFAULT = {
  "UserObject": {
    "profile": {
      "email": "",
      "first_name": "",
      "last_name": "",
      "home_phone": "",
      "mobile_phone": "",
      "number_household_members": 0,
      "household_income": 0,
      "signature": "",
      "font-family": "",
      "primary_address_id": 0
    },
    "charger": {
      "make": "",
      "model": "",
      "purchase_price": 0,
      "purchase_date": "",
      "serial_number": "",
      "type": "charger",
      "address_ids": [],
      "equipment_id": 0,
      "images": {
        "CHARGER_INSTALLATION": {
          "size": "",
          "url": ""
        },
        "CHARGER_INSTALLATION_INVOICE": {
          "size": "",
          "url": ""
        },
        "CHARGER_PURCHASE_RECEIPT": {
          "size": "",
          "url": ""
        },
        "BUILDING_PERMIT": {
          "size": "",
          "url": ""
        }
      },
      "incentives": null
    },
    "vehicle": {
      "make": "",
      "model": "",
      "purchase_price": 0,
      "purchase_date": "",
      "vin": "",
      "model_year": 0,
      "type": "vehicle",
      "address_ids": [],
      "fuel": "",
      "equipment_id": 0,
      "images": {
        "vehicle_registration": {
          "size": "",
          "url": ""
        },
        "VEHICLE_LICENSE_PLATE": {
          "size": "",
          "url": ""
        }
      },
      "incentives": []
    },
    "chargerAddress": {
      "address_id": 0,
      "street_address": "",
      "postcode": "",
      "owner": "true",
      "charger-at-this-property": "true",
      "power_supplier": {
        "name": "",
        "account_number": ""
      }
    },
    "primaryAddress": {
      "address_id": 0,
      "street_address": "",
      "postcode": "",
      "owner": "true",
      "charger-at-this-property": "true",
      "power_supplier": {
        "name": "",
        "account_number": ""
      }
    },
  },
  "errorState": null,
  "currentPage": null,
  "incentiveCompleteMessage": false
};

export default INITIAL_USER_STATE_DEFAULT;

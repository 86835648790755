// @TODO need to have a button type for when user < 100% complete
const setCardBottomType = (currentStatus, customFields, incentiveID) => {
  let cardBottomType = customFields.length > 0
    ? 'incomplete'
    : incentiveID < 0
      ? null
      : 'available';

  const typeMap = {
    submitted: 'applied',
    saved: 'incomplete'
  };

  cardBottomType = typeMap[currentStatus] || cardBottomType;

  return cardBottomType;
};

export default setCardBottomType;

import React from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';

// @TODO this should open a modal allowing user to drag photo
const UploadPhoto = (
  {
    id,
    onChange,
    isUploaded = false,
  },
) => {
  return (
    <>
      <label htmlFor={id} className="mb-0">
        <div
          className={`btn btn-lg btn-${isUploaded ? 'success' : 'light'} IAAButton`}
        >
          {isUploaded && (
            <FormattedMessage id="photoReceived" />
          )}
          {!isUploaded && (
            <>
              <span className="d-none d-sm-inline">
                <FormattedMessage id="uploadPhoto" />
              </span>
              <span className="d-inline d-sm-none">
                <FormattedMessage id="takeAPhoto" />
              </span>
            </>
          )}
        </div>
      </label>
      <input
        type="file"
        accept="image/*,.pdf"
        id={id}
        className="photo-input d-none"
        onChange={onChange}
      />
    </>

  );
};

export default UploadPhoto;

UploadPhoto.propTypes = {
  id: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  isUploaded: PropTypes.bool,
};

const findCurrentStepNumber = (pages, currentPage) => {
  let returnedIndex = -2;

  for (let i = 0; i < pages.length; i += 1) {
    if (pages[i].targetPage === currentPage) {
      returnedIndex = i;
      break;
    }
  }

  return returnedIndex + 1;
};

export default findCurrentStepNumber;

import React from 'react';
import flappyZappyWave from '../../../../../customizations/assets/images/flappyZappyWave.png';

const ZappyWave = () => (
  <section className="container">
    <div className="row">
      <div className="col-12 text-center">
        <img
          src={flappyZappyWave}
          alt="Flappy Zappy Wave"
        />
      </div>
    </div>
  </section>
);

export default ZappyWave;

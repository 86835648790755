const determineClickHandler = (
  status,
  incentiveID,
  setCurrentIncentiveByID,
  history,
) => {
  const clickHandlers = {
    available: () => {
      setCurrentIncentiveByID(incentiveID);
      history.push('submit-application');
    },
    incomplete: () => {
      setCurrentIncentiveByID(incentiveID);
      history.push('complete-application');
    },
  };

  const noOp = () => {};

  return clickHandlers[status] || noOp;
};

export default determineClickHandler;

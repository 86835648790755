import React, { useContext } from 'react';

import PropTypes from 'prop-types';
import AppStepHeader from '../../../Atoms/AppStepHeader/AppStepHeader';
import FirstAndLastName from './subcomponents/FirstAndLastName';
import PhoneNumbers from './subcomponents/PhoneNumbers';
import HouseholdSizeAndIncome from './subcomponents/HouseholdSizeAndIncome';
import Email from './subcomponents/Email';
import Signature from './subcomponents/Signature';

import { UserContext } from '../../../IaaUserEntity/IaaUserEntity';
import { updateUserFunction } from '../../../../apiInterface/responseHandlers/loadToGlobalState';

import logger from '../../../../utils/functions/logging/logger';

const UserProfileMainContent = (
  {
    language,
    hasInvalidInput,
    inputComponentsMustValidate,
  },
) => {

  const contextArr = useContext(UserContext);
  const [iaaUser, dispatch] = contextArr;

  const user = iaaUser.UserObject.profile;

  const updateUser = (newValue, fieldName) => {
    const newUser = { ...user };
    newUser[fieldName] = newValue;
    updateUserFunction(dispatch)(newUser);
    logger(iaaUser, 'UserProfileMainContent');
  };

  return (
    <div className="UserProfile">
      <AppStepHeader
        language={language}
        headlineID="myProfile"
        blurbID="userProfile.headline.blurb"
      />
      <FirstAndLastName
        user={user}
        updateUser={updateUser}
        language={language}
        hasInvalidInput={hasInvalidInput}
        inputComponentsMustValidate={inputComponentsMustValidate}
      />
      <PhoneNumbers
        user={user}
        updateUser={updateUser}
        language={language}
        hasInvalidInput={hasInvalidInput}
        inputComponentsMustValidate={inputComponentsMustValidate}
      />
      <HouseholdSizeAndIncome
        user={user}
        updateUser={updateUser}
        language={language}
        hasInvalidInput={hasInvalidInput}
        inputComponentsMustValidate={inputComponentsMustValidate}
      />
      <Email
        user={user}
        updateUser={updateUser}
        language={language}
        hasInvalidInput={hasInvalidInput}
        inputComponentsMustValidate={inputComponentsMustValidate}
      />
      <Signature
        user={user}
        updateUser={updateUser}
        language={language}
        hasInvalidInput={hasInvalidInput}
        inputComponentsMustValidate={inputComponentsMustValidate}
      />
    </div>
  );
};

export default UserProfileMainContent;

UserProfileMainContent.propTypes = {
  language: PropTypes.string.isRequired,
  hasInvalidInput: PropTypes.func.isRequired,
  inputComponentsMustValidate: PropTypes.bool.isRequired,
};

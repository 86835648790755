import React from 'react';
import PropTypes from 'prop-types';
import Dropdown from '../Inputs/Dropdown/Dropdown';

const FontSelector = (
  {
    validate,
    fieldName,
    textToBeFonted,
    font,
    fontSet,
    propsCollection,
    updatePropsCollection,
    label,
    legend,
    className,
    inputComponentsMustValidate,
    hasInvalidInput,
  },
) => {
  const sigDivStyle = {
    fontFamily: font,
  };

  return (
    <>
      <div
        className={`${className} IAAInput`}
      >
        <div className="inputLegend">
          {legend}
        </div>
        <div className="FontSelector">
          <div
            style={sigDivStyle}
            className="renderedSignature text-center"
          >
            {textToBeFonted}
          </div>
          <Dropdown
            fieldName={fieldName}
            validate={validate}
            propsCollection={propsCollection}
            updatePropsCollection={updatePropsCollection}
            options={fontSet}
            inputComponentsMustValidate={inputComponentsMustValidate}
            hasInvalidInput={hasInvalidInput}
            placeholder=' '
          />
          <div className="dropdownLabel">
            {label}
          </div>
        </div>
      </div>
    </>
  );
};

export default FontSelector;

FontSelector.defaultProps = {
  validate: () => {},
  className: '',
  placeHolder: '',
  legend: '',
  font: '',
  textToBeFonted: ' ',
};

FontSelector.propTypes = {
  validate: PropTypes.func,
  className: PropTypes.string,
  fieldName: PropTypes.string.isRequired,
  label: PropTypes.string,
  legend: PropTypes.string,
  inputComponentsMustValidate: PropTypes.bool.isRequired,
  hasInvalidInput: PropTypes.func.isRequired,
  font: PropTypes.string,
  textToBeFonted: PropTypes.string,
  fontSet: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.string)).isRequired,
  propsCollection: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  updatePropsCollection: PropTypes.func.isRequired,
};

import React from 'react';
import PropTypes from 'prop-types';

import ApplicationAssistantLayout from '../../ApplicationAssistantLayout/ApplicationAssistantLayout';
import RegistrationSuccessfulMainContent from './MainContent/RegistrationSuccessfulMainContent';

const RegistrationSuccessful = (
  {
    language,
    setCurrentIncentiveByID,
    handlePageForwardClick,
    currentPage
  },
) => (
  <ApplicationAssistantLayout
    language={language}
    setCurrentIncentiveByID={setCurrentIncentiveByID}
    handlePageForwardClick={handlePageForwardClick}
    currentPage={currentPage}
  >
    <RegistrationSuccessfulMainContent
      language={language}
    />
  </ApplicationAssistantLayout>
);

export default RegistrationSuccessful;

RegistrationSuccessful.propTypes = {
  language: PropTypes.string.isRequired,
  setCurrentIncentiveByID: PropTypes.func.isRequired,
  handlePageForwardClick: PropTypes.func.isRequired,
  currentPage: PropTypes.string.isRequired
};

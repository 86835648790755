import React from 'react';
import PropTypes from 'prop-types';

import SignatureDesktop from './desktop/SignatureDesktop';
import SignatureMobile from './mobile/SignatureMobile';
import SectionWrapper from '../../../../Atoms/SectionWrapper/SectionWrapper';

const Signature = (
  {
    user,
    updateUser,
    language,
    hasInvalidInput,
    inputComponentsMustValidate,
  },
) => {
  const desktopSignature = (
    <SignatureDesktop
      user={user}
      updateUser={updateUser}
      language={language}
      hasInvalidInput={hasInvalidInput}
      inputComponentsMustValidate={inputComponentsMustValidate}
    />
  );

  const mobileSignature = (
    <SignatureMobile
      user={user}
      updateUser={updateUser}
      language={language}
      hasInvalidInput={hasInvalidInput}
      inputComponentsMustValidate={inputComponentsMustValidate}
    />

  );

  return (
    <SectionWrapper
      mobileSection={mobileSignature}
      desktopSection={desktopSignature}
      language={language}
      sectionClassName="SignatureSection"
    />
  );
};

export default Signature;

Signature.propTypes = {
  user: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  updateUser: PropTypes.func.isRequired,
  language: PropTypes.string.isRequired,
  hasInvalidInput: PropTypes.func.isRequired,
  inputComponentsMustValidate: PropTypes.bool.isRequired,
};

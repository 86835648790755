  
//import contactEndpoint from '../../contactEndpoint/contactEndpoint';
// import fakeAPIResponse from '../../contactEndpoint/fakeAPIResponse';
import contactAmplifyEndpoint from "../../contactEndpoint/contactAmplifyEndpoint";


// const contactEndpoint = fakeAPIResponse;

const fullDataRefresh = () => new Promise((resolve, reject) => {
  contactAmplifyEndpoint(
    '/user',
    'get',
    {
      queryStringParameters: {},
      body: {},
    },
  ).then((data) => {
    resolve(data);
  }).catch((err) => {
    reject(err);
  });
});

export default fullDataRefresh;

import React, { useEffect, useContext, useState, useRef } from 'react';
import PropTypes from 'prop-types';

import SectionWrapper from '../../SectionWrapper/SectionWrapper';
import PhotoProcessorMobile from './mobile/PhotoProcessorMobile';
import PhotoProcessorDesktop from './desktop/PhotoProcessorDesktop';

import photoHandler from '../../../../apiInterface/requestBuilders/post/photoHandler';

import { UserContext } from '../../../IaaUserEntity/IaaUserEntity';
import { updateChargerFunction, updateVehicleFunction } from '../../../../apiInterface/responseHandlers/loadToGlobalState';
import LocalStorage from '../../../../utils/functions/localStorage/LocalStorage';
import localStorageKeyring from '../../../../constants/LOCAL_STORAGE_KEYS';
import filterByField from '../../../../utils/functions/misc/filterByField';

const PhotoProcessor = (
  {
    id,
    equipmentID,
    setDisplayPending,
    hasInvalidInput,
    language,
    topMessage,
    bottomMessage,
    tooltip,
    inputComponentsMustValidate,
    assetType, 
    beforeAddingPhoto,
    incentive, 
    updateIncentive,
    incentivePhotoReceived,
    fetchUserData
  },
) => {

  const [iaaUser, dispatch] = useContext(UserContext);
  const [showWarning, setShowWarning] = useState(false);

  const photoUploadNoted = useRef(false);

  const assetTypeMap = {
    'CHARGER_PHOTOGRAPH': {
      imageSlot: iaaUser.UserObject.charger.images,
      updateFunction: updateChargerFunction,
      existingProperty: iaaUser.UserObject.charger,
    },
    'VEHICLE_PHOTOGRAPH' : {
      imageSlot: iaaUser.UserObject.vehicle.images,
      updateFunction: updateVehicleFunction,
      existingProperty: iaaUser.UserObject.vehicle,
    },
    'INCENTIVE_PHOTOGRAPH' : {
      imageSlot: incentive ? incentive.images : {},
      updateFunction: () => {
        return (inc) => {
          const namedPhotoInputField = filterByField('name', id.toLowerCase(), inc.input_fields)[0];
          namedPhotoInputField.user_input = 'done';

          updateIncentive(inc);
        };
      },
      existingProperty: incentive
    }
  };

  const imageSet = assetTypeMap[assetType].imageSlot;
  const targetImage = imageSet[id];

  // Triggered when photoprocessor unmounts
  useEffect(() => {
    return () => {
      if(photoUploadNoted.current && fetchUserData) {
        fetchUserData();
      }
    };
  }, []);

  const getImageLoaded = (img) => {
    if(incentivePhotoReceived) {
      return true;
    }

    if(assetType === 'VEHICLE_PHOTOGRAPH') {
      if(LocalStorage.getLocalDatapoint(localStorageKeyring.hasUploadedVehicleRegistration)) {
        return true;
      }
    }

    if(photoUploadNoted.current) {
      return true;
    }
    const isLoaded = img.asset_id || img.url
      ? true
      : false;
    return isLoaded;
  };

  useEffect(() => {
    const validateOnCheck = () => {
      if (inputComponentsMustValidate) {
        const invalid = !getImageLoaded(targetImage);
        setShowWarning(invalid);
        hasInvalidInput(id, invalid);
        
      }
    };

    validateOnCheck();
  });

  const photoAddedCallback = () => {
    photoUploadNoted.current = true;

    if(assetType === 'VEHICLE_PHOTOGRAPH') {
      LocalStorage.setLocalDatapoint(localStorageKeyring.hasUploadedVehicleRegistration, true);
    }

    const imageToUpdate = { ...targetImage };
    imageToUpdate.url = 'done';

    const newCollection = { ...assetTypeMap[assetType].existingProperty };

    newCollection.images[id] = imageToUpdate;
    
    assetTypeMap[assetType].updateFunction(dispatch)(newCollection);    
  };

  const photoAdded = (event) => {
    setShowWarning(false);
    beforeAddingPhoto()
      .then((equipID) => {
        const equipIDToSend = equipID || equipmentID;
        const photoHandlerArgs = {
          event,
          equipmentID: equipIDToSend,
          assetType, 
          callback: photoAddedCallback,  
          setDisplayPending,
        };

        if(assetType === 'INCENTIVE_PHOTOGRAPH') {
          if(!incentive) {

            // honey badger error wrapper will catch  
            // @TODO verify that the app is also catching this.
            throw new Error('asset type is INCENTIVE_PHOTOGRAPH but no incentive available');
          }
          photoHandlerArgs.incentive_id = incentive.id;
        } 

        photoHandler(photoHandlerArgs);  
      });
  };

  if(assetType === 'INCENTIVE_PHOTOGRAPH' && !incentive) {
    return null;
  }
  
  if(!targetImage) {
    return null;
  }

  const mobileSection = (
    <>
      <PhotoProcessorMobile
        id={id}
        topMessage={topMessage}
        bottomMessage={bottomMessage}
        tooltip={tooltip}
        onChange={photoAdded}
        showWarning={showWarning}
        photoUploaded={
          getImageLoaded(targetImage)
        }
      />
    </>
  );

  const desktopSection = (
    <PhotoProcessorDesktop
      id={id}
      topMessage={topMessage}
      bottomMessage={bottomMessage}
      tooltip={tooltip}
      onChange={photoAdded}
      showWarning={showWarning}
      photoUploaded={
        getImageLoaded(targetImage)
      }
    />
  );

  return (
    <SectionWrapper
      language={language}
      mobileSection={mobileSection}
      desktopSection={desktopSection}
      sectionClassName="PhotoProcessor"
    />
  );
};

export default PhotoProcessor;

PhotoProcessor.defaultProps = {
  tooltip: '',
  beforeAddingPhoto: () => {
    return new Promise((resolve) => {
      resolve();
    });
  },
  incentive: null,
  updateIncentive: () => {}
};

PhotoProcessor.propTypes = {
  equipmentID: PropTypes.string.isRequired,
  setDisplayPending: PropTypes.func.isRequired,
  hasInvalidInput: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
  language: PropTypes.string.isRequired,
  bottomMessage: PropTypes.string.isRequired,
  topMessage: PropTypes.string.isRequired,
  tooltip: PropTypes.string,
  inputComponentsMustValidate: PropTypes.bool,
  assetType: PropTypes.string,
  beforeAddingPhoto: PropTypes.func,
  incentive: PropTypes.object,
  updateIncentive: PropTypes.func,
  incentivePhotoReceived: PropTypes.bool,
  fetchUserData: PropTypes.func
};

import React from 'react';
import PropTypes from 'prop-types';

import TextField from '../../Atoms/Inputs/TextField/TextField';

import '../../Atoms/Inputs/css/input-1.scss';

// @TODO will want to work out interval at which we put updates
// to the back end and/or triggering events.
// @TODO validate and other functions more complex than state setters should be in separate modules.

const ExampleTextField = (
  {
    inputComponentsMustValidate,
    hasInvalidInput,
    user,
    updateUser,
  },
) => {
  // In real implementation, consider putting this in separate functions file.
  // Also should use regexp for zipcode specifically
  const validateFunc = (zipcodeValue) => {
    if (zipcodeValue.length !== 5) {
      throw new Error('We need a zipcode with five numbers');
    }
  };

  return (
    <TextField
      fieldName="zipcode"
      validate={validateFunc}
      propsCollection={user}
      updatePropsCollection={updateUser}
      defaultValue="ZIP"
      legend="Zip Code"
      fieldWidth="10rem"
      inputComponentsMustValidate={inputComponentsMustValidate}
      hasInvalidInput={hasInvalidInput}
    />

  );
};

export default ExampleTextField;

ExampleTextField.propTypes = {
  inputComponentsMustValidate: PropTypes.bool.isRequired,
  hasInvalidInput: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  updateUser: PropTypes.func.isRequired,
};

import React from 'react';
import PropTypes from 'prop-types';

import SectionWrapper from '../../../Atoms/SectionWrapper/SectionWrapper';
import MobileDashboardContent from '../submodules/MobileDashboardContent';
import DesktopDashboardContent from '../submodules/DesktopDashboardContent';

const DashboardContent = ({
  language,
  setCurrentIncentiveByID,
}) => {
  
  const mobileSection = (
    <>
      <MobileDashboardContent
        language={language}
        setCurrentIncentiveByID={setCurrentIncentiveByID}
      />
    </>
  );

  const desktopSection = (
    <DesktopDashboardContent 
      language={language}
      setCurrentIncentiveByID={setCurrentIncentiveByID}
    />
  );

  return (
    <SectionWrapper
      mobileSection={mobileSection}
      desktopSection={desktopSection}
      language={language}
      sectionClassName="DashboardContent"
      isContainer={false}
    />

  );
};

export default DashboardContent;

DashboardContent.propTypes = {
  language: PropTypes.string.isRequired,
  setCurrentIncentiveByID: PropTypes.func.isRequired,
};

import React from 'react';
import PropTypes from 'prop-types';

import FieldGroup from '../../../../Atoms/FieldGroup/FieldGroup';
import TextField from '../../../../Atoms/Inputs/TextField/TextField';
import PurchaseDatePrice from './PurchaseDatePrice';

import { checkFieldNotEmpty } from '../../../../Atoms/Inputs/functions/inputHelper';
import { getTranslatedMessageFromID } from '../../../../../utils/functions/intl/intlWrapper';

const SerialNumberPriceDatePurchase = (
  {
    equipment,
    updateEquipment,
    language,
    inputComponentsMustValidate,
    hasInvalidInput,
  },
) => {
  const leftField = (
    <TextField
      validate={checkFieldNotEmpty}
      className="TextInput"
      fieldName="serial_number"
      defaultValue=""
      legend={getTranslatedMessageFromID('serialNumber', language)}
      fieldWidth="100%"
      inputComponentsMustValidate={inputComponentsMustValidate}
      hasInvalidInput={hasInvalidInput}
      propsCollection={equipment}
      updatePropsCollection={updateEquipment}
    />
  );

  const rightField = (
    <PurchaseDatePrice
      equipment={equipment}
      updateEquipment={updateEquipment}
      language={language}
      hasInvalidInput={hasInvalidInput}
      inputComponentsMustValidate={inputComponentsMustValidate}
    />
  );

  return (
    <FieldGroup
      language={language}
      leftField={leftField}
      rightField={rightField}
    />
  );
};

export default SerialNumberPriceDatePurchase;

SerialNumberPriceDatePurchase.propTypes = {
  equipment: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  updateEquipment: PropTypes.func.isRequired,
  language: PropTypes.string.isRequired,
  hasInvalidInput: PropTypes.func.isRequired,
  inputComponentsMustValidate: PropTypes.bool.isRequired,
};

import React from 'react';
import PropTypes from 'prop-types';

import { getTranslatedMessageFromID } from '../../../../../utils/functions/intl/intlWrapper';

const SignatureLegal = ({
  language,
}) => (
  <p className="legalDisclaimer">{getTranslatedMessageFromID('userProfile.signature.legalese', language)}</p>
);

export default SignatureLegal;

SignatureLegal.propTypes = {
  language: PropTypes.string.isRequired,
};

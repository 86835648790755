import React from 'react';
import PropTypes from 'prop-types';

import { getTranslatedMessageFromID } from '../../../../utils/functions/intl/intlWrapper';

const AppStepHeaderDesktop = (
  {
    headlineID,
    blurbID,
    language,
  },
) => {
  const blurb = blurbID
    ? (
      <div className="AppStepBlurb">
        {getTranslatedMessageFromID(blurbID, language)}
      </div>
    )
    : null;

  return (
    <div>
      <div className="AppStepHeader">
        {getTranslatedMessageFromID(headlineID, language)}
      </div>
      {blurb}
    </div>
  );
};

export default AppStepHeaderDesktop;

AppStepHeaderDesktop.defaultProps = {
  blurbID: null,
};

AppStepHeaderDesktop.propTypes = {
  headlineID: PropTypes.string.isRequired,
  blurbID: PropTypes.string,
  language: PropTypes.string.isRequired,
};

// @TODO can this be tested? Do we need to if this is being run by Root.js?

import React, { useEffect, useState, useContext, useRef } from "react";
import { Switch, Route, useHistory } from "react-router-dom";
import PropTypes from "prop-types";
import Amplify, { Auth, Hub } from "aws-amplify";
import {
  AmplifySignIn,
  AmplifyAuthenticator,
  AmplifySignUp,
  AmplifyConfirmSignUp,
} from "@aws-amplify/ui-react";
import { useIntl } from "react-intl";

import NAV_PAGE_DESCRIPTORS from "../../constants/NAV_PAGE_DESCRIPTORS";

import PageLayout from "../PageLayout/PageLayout";

import Homepage from "../Pages/Homepage/Homepage";
import Login from "../Pages/Login/Login";
import RegistrationSuccessful from "../Pages/RegistrationSuccessful/RegistrationSuccessful";
import UserProfile from "../Pages/UserProfile/UserProfile";
import UpdateAccount from "../Pages/UpdateAccount/UpdateAccount";
import ChargerLocation from "../Pages/ChargerLocation/ChargerLocation";
import MyCharger from "../Pages/MyCharger/MyCharger";
import MyUtility from "../Pages/MyUtility/MyUtility";
import MyElectricVehicle from "../Pages/MyElectricVehicle/MyElectricVehicle";
import CompleteApplication from "../Pages/CompleteApplication/CompleteApplication";
import SubmitApplication from "../Pages/SubmitApplication/SubmitApplication";
import NoIncentivesAvailable from "../Pages/NoIncentivesAvailable/NoIncentivesAvailable";
import ErrorPage from "../Pages/ErrorPage/ErrorPage";
import Dashboard from "../Pages/Dashboard/Dashboard";
import ContactUs from "../Pages/ContactUs/ContactUs";
import ComponentCatalog from "../TemporaryScaffolding/ComponentCatalog";
import SaveReturn from "../Pages/AppSaveSubmit/SaveReturn";
import SubmitReturn from "../Pages/AppSaveSubmit/SubmitReturn";

import pageSubmitHandler from "../../apiInterface/requestBuilders/post/pageSubmitHandler";
import { findHostNameFromConfig } from "../../utils/functions/locationBar/pathfinder";
import LocalStorage from "../../utils/functions/localStorage/LocalStorage";

import fullDataRefresh from "../../apiInterface/requestBuilders/get/fullDataRefresh";
import processFullDataRefresh from "../../apiInterface/responseHandlers/processFullDataRefresh";
import {
  requiredFieldsByPage,
  pageNameMappings,
} from "../../utils/helpers/ApplicationProgressMonitor/data/constants";

import "bootstrap/dist/css/bootstrap.min.css";

import { UserContext } from "../IaaUserEntity/IaaUserEntity";
import { maybeCheckIncentives } from "../../utils/helpers/unifiedCalls/checkIncentives";
import placeholderIncentives from "../../constants/PLACEHOLDER_INCENTIVES";
import {
  getIncentives,
  updateErrorState,
} from "../../apiInterface/responseHandlers/loadToGlobalState";

import "./css/amplify.scss";
import processIncentiveDataFromUnsafe from "../../apiInterface/responseHandlers/processIncentiveDataFromUnsafe";
import filterByField from "../../utils/functions/misc/filterByField";
import logger from "../../utils/functions/logging/logger";

import LoadingAnimation from "../Atoms/LoadingAnimation/LoadingAnimation";
import DetermineAllowedPages from "../../utils/helpers/ApplicationProgressMonitor/DetermineAllowedPages";
import ApplicationProgressMonitor from "../../utils/helpers/ApplicationProgressMonitor/ApplicationProgressMonitor";
import watchTimeout from "../../utils/helpers/cognito/timeoutWatcher";
import SessionTimeoutErrorNotifier from "../ErrorHandlers/SessionTimeoutErrorNotifier";
import completeApplicationPromise from "../../apiInterface/requestBuilders/post/functions/promises/completeApplicationPromise";
import { noOp } from "../../utils/functions/misc/noOp";
import Congrats from "../Modals/Congrats";

const redirectSignInBasePath = findHostNameFromConfig();
const apiHost =
  process.env.REACT_APP_EV_INFO_API_HOST ||
  "https://api.veloz.ia.d.zappyride.com";
const userPoolId =
  process.env.REACT_APP_AWS_USER_POOL_ID || "us-west-2_e00Jd4XNp";
const userPoolWebClientId =
  process.env.REACT_APP_USER_POOL_CLIENT_ID || "603dtstpo7ita9qlpvjgkltj5t";
const oauthDomain =
  process.env.REACT_APP_AWS_OAUTH_DOMAIN ||
  "dev-iaa-veloz.auth.us-west-2.amazoncognito.com";

// @TODO need to move this out to .env
const awsconfig = {
  // OPTIONAL - if your API requires authentication
  Auth: {
    // REQUIRED - Amazon Cognito Identity Pool ID
    identityPoolId: "us-west-2:32b4b31d-ecd4-4cf5-b33a-6226e31befab", // 'us-west-2:32b4b31d-ecd4-4cf5-b33a-6226e31befab',
    // REQUIRED - Amazon Cognito Region
    region: "us-west-2",
    // OPTIONAL - Amazon Cognito User Pool ID
    userPoolId,
    // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
    userPoolWebClientId,
    // cookieStorage: { domain: 'localhost', secure: true },
    oauth: {
      domain: oauthDomain,
      scope: ["phone", "email", "profile", "openid"],
      redirectSignIn: `${redirectSignInBasePath}/welcome`,
      redirectSignOut: `${redirectSignInBasePath}`,
      responseType: "token",
      label: "Sign in with social media",
    },
  },

  API: {
    endpoints: [
      {
        name: "MyAPIGatewayAPI",
        endpoint: apiHost,
        custom_header: async () => ({
          Authorization: `Bearer ${(await Auth.currentSession())
            .getIdToken()
            .getJwtToken()}`,
          withCredentials: true,
        }),
      },
    ],
  },
};

Amplify.configure(awsconfig);

// @TODO instead of passing in every state and its setter,
// pass a function to get the state and another function to get the setter
const Routing = ({
  findPath,
  language,
  changeLanguage,
  displayPending,
  setDisplayPending,
  requiredCheckboxes,
  setRequiredCheckboxes,
  inputComponentsMustValidate,
  setInputComponentsMustValidate,
  hasInvalidInput,
  setHasInvalidInput,
  honeybadger,
}) => {
  const [iaaUser, dispatch] = useContext(UserContext);
  const [authState, setAuthState] = useState();
  const [userResponseProcessed, setUserResponseProcessed] = useState(false);
  const [sessionTimeoutError, setSessionTimeoutError] = useState(false);
  const errorState = iaaUser.errorState;
  const [currentIncentive, updateCurrentIncentive] = useState({});
  const intl = useIntl();
  const alertAPIError = (err) => {
    setDisplayPending(false);

    const defaultResponse = () => {
      updateErrorState(dispatch)(err);
    };

    const specificResponders = {
      noCurrentSession: () => {
        setSessionTimeoutError(true);
      },
    };

    const handler = specificResponders[err.message] || defaultResponse;

    handler();
  };
  const history = useHistory();
  const haveCheckedRedirect = useRef(false);

  // sets equipment, address, and incentive by id
  const setCurrentIncentiveByID = (id) => {
    const matchingIncentive = filterByField(
      "incentive_id",
      id,
      iaaUser.UserObject.incentives
    );

    // @TODO should not be a need to set current incentive in both state and drill down.
    dispatch({
      type: "SET_CURRENT_INCENTIVE",
      payload: matchingIncentive[0],
    });

    // @TODO there is no way we should need both this and the dispatch statement above.
    updateCurrentIncentive(matchingIncentive[0]);
  };

  const fetchUserData = () => {
    return new Promise((resolve) => {
      fullDataRefresh()
        .then((response) => {
          processFullDataRefresh(response, dispatch, language);
          setUserResponseProcessed(true);
          resolve(response);
        })
        .catch((err) => {
          alertAPIError(err);
        });
    });
  };

  const resetAuth = () => {
    setAuthState(false);
    LocalStorage.clearUserData();
    dispatch({ type: "RESET" });
  };

  const checkAuth = async () => {
    try {
      const session = await Auth.currentSession(); // eslint-disable-line no-unused-vars
      watchTimeout(setSessionTimeoutError);
      setAuthState(true);
      setDisplayPending(true);
      LocalStorage.clearUserData();
      fetchUserData()
        .then(() => {
          setDisplayPending(false);
        })
        .catch((err) => {
          // resetAuth here as well?
        });
    } catch {
      resetAuth();
    }
  };

  useEffect(() => {
    let interval;
    let browser = 1;
    if (navigator.userAgent.indexOf("Chrome") !== -1) {
      browser = 0;
    } else {
      browser = 1;
    }

    if (!authState) {
      interval = setInterval(() => {
        let firstShadowRoot = document.getElementsByTagName(
          "amplify-authenticator"
        )[0]?.shadowRoot.children[browser];
        if (
          firstShadowRoot &&
          firstShadowRoot.nodeName === "AMPLIFY-TOAST" &&
          firstShadowRoot.shadowRoot.children.length
        ) {
          const toast = firstShadowRoot.shadowRoot.children[browser];
          let pageFormatting = getComputedStyle(
            document.getElementsByTagName("amplify-sign-in")[0]
          ).display;
          if (
            toast.children[1].textContent.includes("password") &&
            pageFormatting === "inline"
          ) {
            toast.children[1].textContent =
              "Password did not conform with policy: Password not long enough";
          }
          toast.style.left = "50%";
          toast.style.transform = "translate(-50%, -50%)";
          toast.style.top = "50vh";
          toast.style.maxWidth = "500px";
        }
      }, 100);
    }

    return () => {
      clearInterval(interval);
    };
  });

  useEffect(() => {
    // @TODO this has to move to where we have the zipcode.
    // we could even put that trigger within IaaUserEntity
    // loadAllVehiclesFromUniversalAPI({
    //   currentAddress: {
    //     postcode: 10023
    //   }
    // });
    checkAuth();

    Hub.listen("auth", ({ payload: { event, data } }) => {
      logger(event, "Hub.listen");

      // eslint-disable-next-line default-case
      switch (event) {
        case "signIn":
        case "cognitoHostedUI":
          checkAuth();
          break;
        case "oAuthSignOut":
        case "signOut":
          resetAuth();
          break;
        case "signIn_failure":
        case "cognitoHostedUI_failure":
          logger(`Sign in failure ${data}`, "Routing"); // eslint-disable-line no-console
      }
    });
    // This line needed to cover case where user refreshes page - no events to trigger storeCognitoUser.
    // getUser().then((userData) => storeCognitoUser((userData && userData.payload) || userData));
  }, []);

  // Could use this to be responsive to updating any field.... is that a good idea though?
  useEffect(() => {
    // @TODO - will need to check incentives if other points of userobject are updated.
    // @TODO - recheck if zipcode has changed.

    const userObj = iaaUser.UserObject;
    const charger = userObj.charger;
    const hasCompletedAddress =
      userObj.chargerAddress && userObj.chargerAddress.address_id;
    const hasChargerID = parseInt(charger.equipment_id) > 0;

    const hasIncentives = userObj.incentives && userObj.incentives.length > 0;

    if (hasCompletedAddress && hasChargerID && !hasIncentives) {
      const tempIncentives = [placeholderIncentives.nowCheckingIncentives];

      getIncentives(dispatch)(tempIncentives);

      maybeCheckIncentives(iaaUser, alertAPIError).then((incentives) => {
        if (incentives.length === 0) {
          return;
        }

        // @TODO this will break if we don't have a charger object on IAAUser
        processIncentiveDataFromUnsafe(
          dispatch,
          charger.incentives,
          incentives
        );

        incentives.forEach((incentive) => {
          // @TODO is this indeed necessary?
          setCurrentIncentiveByID(incentive.incentive_id);

          // @TODO try to get this down to one backend call.
          completeApplicationPromise({
            incentive,
            charger,
            setDisplayPending: noOp,
            alertAPIError,
          })
            .then((response) => {
              const userObjIncentives = [...userObj.incentives];
              const newIncentives = [];

              userObjIncentives.forEach((uoi) => {
                if (uoi.incentive_id === response[0].incentive_id) {
                  newIncentives.push(response[0]);
                } else {
                  newIncentives.push(uoi);
                }
              });

              getIncentives(dispatch)(newIncentives);
            })
            .catch((err) => {
              noOp(err);
            });
        });
      });
    }
  }, [iaaUser]);

  useEffect(() => {
    const checkedForRedirect = haveCheckedRedirect.current;
    const currentPageKnown = iaaUser.currentPage;
    const pagesCompleteKnown =
      ApplicationProgressMonitor.getCurrentPagesComplete();

    if (!checkedForRedirect && currentPageKnown && userResponseProcessed) {
      const redirectPage = DetermineAllowedPages(
        currentPageKnown,
        pagesCompleteKnown
      );

      logger(redirectPage, "Router");

      history.push(redirectPage);

      haveCheckedRedirect.current = true;
    }
  }, [iaaUser]);

  const routeElements = NAV_PAGE_DESCRIPTORS.map((pageDescriptor) => {
    const pages = {
      Homepage,
      Login,
      RegistrationSuccessful,
      UserProfile,
      UpdateAccount,
      ChargerLocation,
      MyCharger,
      MyUtility,
      MyElectricVehicle,
      Dashboard,
      ContactUs,
      ComponentCatalog,
      CompleteApplication,
      SubmitApplication,
      NoIncentivesAvailable,
      SaveReturn,
      SubmitReturn,
    };

    const TagName = pages[pageDescriptor.page];

    const triggerAllInputComponentsValidPromise = (
      canSkipValidation = false
    ) => {
      return new Promise((resolve, reject) => {
        // @TODO fix validation for vehicle
        if (canSkipValidation) {
          resolve();
          return;
        }

        setInputComponentsMustValidate(true);
        const targetPage = pageNameMappings[pageDescriptor.path];
        const requiredFieldsSet = new Set(requiredFieldsByPage[targetPage]);

        let hasInvalidInput = false;

        setHasInvalidInput(() => {
          return (fieldName, isNotValid) => {
            if (isNotValid) {
              hasInvalidInput = true;
            }

            // @TODO should revisit the naming of things.
            if (targetPage === "myVehicle") {
              if (fieldName === "make") fieldName = "vehicle_make";
              if (fieldName === "model") fieldName = "vehicle_model";
            }

            requiredFieldsSet.delete(fieldName);

            if (requiredFieldsSet.size === 0) {
              setInputComponentsMustValidate(false);
              if (hasInvalidInput) {
                reject("has invalid fields");
              } else {
                resolve();
              }
            }
          };
        });
      });
    };

    const handlePageForwardClick = pageSubmitHandler(pageDescriptor.path, {
      fetchUserData,
      setDisplayPending,
      dispatch,
      iaaUser,
      triggerAllInputComponentsValidPromise,
      alertAPIError: alertAPIError,
      language,
    });

    const renderSignIn = (
      <AmplifyAuthenticator
        usernameAlias="username"
        headerText="Sign in or create an account"
      >
        <AmplifySignUp
          headerText="Create account"
          slot="sign-up"
          formFields={[
            { type: "username" },
            {
              type: "password",
              hint: 'At least 12 characters, one lowercase, one uppercase, one number, and one of the following special characters = + - ^ $ * . [ ] { } ( ) ? " ! @ # % & / \\ , > < : ; | _ ~ `',
            },
            {
              type: "phone_number",
              label:
                "Phone number (you will receive a text message to enter on the next screen)",
            },
            { type: "email" },
          ]}
        />
        <AmplifySignIn slot="sign-in" usernameAlias="username" />
        <AmplifyConfirmSignUp headerText="Please check your text messages for your confirmation code" />
      </AmplifyAuthenticator>
    );

    return (
      <Route
        exact
        path={pageDescriptor.path}
        key={`key_${pageDescriptor.path}`}
        render={(properties) => {
          // @TODO add support for language, ip, etc
          const path = findPath(properties);

          const renderedPageContent = displayPending ? (
            <LoadingAnimation />
          ) : (
            <>
              <TagName
                language={language}
                currentPage={path}
                requiredCheckboxes={requiredCheckboxes}
                setRequiredCheckboxes={setRequiredCheckboxes}
                displayPending={displayPending}
                setDisplayPending={setDisplayPending}
                inputComponentsMustValidate={inputComponentsMustValidate}
                setCurrentIncentiveByID={setCurrentIncentiveByID}
                setInputComponentsMustValidate={setInputComponentsMustValidate}
                hasInvalidInput={hasInvalidInput}
                setHasInvalidInput={setHasInvalidInput}
                handlePageForwardClick={handlePageForwardClick}
                incentive={currentIncentive}
                updateIncentive={updateCurrentIncentive}
                triggerAllInputComponentsValidPromise={
                  triggerAllInputComponentsValidPromise
                }
                fetchUserData={fetchUserData}
                alertAPIError={alertAPIError}
              />
              {sessionTimeoutError && (
                <SessionTimeoutErrorNotifier
                  sessionTimeoutError={sessionTimeoutError}
                  setSessionTimeoutError={setSessionTimeoutError}
                  language={language}
                />
              )}
              {iaaUser.incentiveCompleteMessage && (
                <Congrats language={language} />
              )}
            </>
          );

          const renderContent = () => {
            if (errorState) {
              return (
                <ErrorPage
                  errorState={errorState}
                  fromCaughtError={true}
                  honeybadger={honeybadger}
                />
              );
            } else if (authState || path === "/" || path === "/contact-us") {
              return renderedPageContent;
            } else {
              return renderSignIn;
            }
          };

          // @TODO move logic for handling redirects based on completion here
          // I think this will solve some of the challenges we're looking at.

          const silentPages = new Set(["/", "/contact-us"]);
          const showMain =
            !authState ||
            haveCheckedRedirect.current ||
            displayPending ||
            silentPages.has(path);

          return (
            <PageLayout
              path={path}
              page={pageDescriptor.title}
              language={language}
              changeLanguage={changeLanguage}
              title={intl.formatMessage({ id: pageDescriptor.seoTitleKey })}
              authState={authState}
              haveCheckedRedirect={showMain}
            >
              {renderContent()}
            </PageLayout>
          );
        }}
      />
    );
  });

  return <Switch>{routeElements}</Switch>;
};

export default Routing;

Routing.defaultProps = {
  findPath: () => "",
  language: "EN",
  changeLanguage: () => {},
  userSessionId: null,
  setUserSessionId: () => {},
  requiredCheckboxes: {},
  setRequiredCheckboxes: () => {},
  devMode: false,
};

Routing.propTypes = {
  findPath: PropTypes.func,
  language: PropTypes.string,
  changeLanguage: PropTypes.func,
  displayPending: PropTypes.bool.isRequired,
  setDisplayPending: PropTypes.func.isRequired,
  requiredCheckboxes: PropTypes.objectOf(PropTypes.bool),
  setRequiredCheckboxes: PropTypes.func,
  inputComponentsMustValidate: PropTypes.bool.isRequired,
  setInputComponentsMustValidate: PropTypes.func.isRequired,
  setHasInvalidInput: PropTypes.func.isRequired,
  hasInvalidInput: PropTypes.func.isRequired,
  honeybadger: PropTypes.any.isRequired,
};

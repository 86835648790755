import React, { useContext } from 'react';
import PropTypes from 'prop-types';

import AppStepHeader from '../../../Atoms/AppStepHeader/AppStepHeader';
import UtilityInfo from './subcomponents/UtilityInfo';

import { getUtility } from '../functions/utilitiesFromAddress';

import { UserContext } from '../../../IaaUserEntity/IaaUserEntity';
import { updateChargerAddressFunction } from '../../../../apiInterface/responseHandlers/loadToGlobalState';


const MyUtilityMainContent = ({
  setDisplayPending,
  displayPending,
  language,
  inputComponentsMustValidate,
  hasInvalidInput,
  alertAPIError
}) => {
  
  const contextArr = useContext(UserContext);
  const [iaaUser, dispatch] = contextArr;

  const currentAddress = iaaUser.UserObject.chargerAddress;

  const updateAddress = (newValue, fieldName) => {
    const newAddress = { ...currentAddress };
    newAddress.power_supplier[fieldName] = newValue;
    updateChargerAddressFunction(dispatch)(newAddress);
  };

  return (
    <div className="MyUtility">
      <AppStepHeader
        language={language}
        headlineID="myUtility"
        blurbID="myUtility.headline.blurb"
      />
      <UtilityInfo
        utility={getUtility(currentAddress)}
        updateUtility={updateAddress}
        displayPending={displayPending}
        setDisplayPending={setDisplayPending}
        currentAddress={currentAddress}
        language={language}
        hasInvalidInput={hasInvalidInput}
        inputComponentsMustValidate={inputComponentsMustValidate}
        alertAPIError={alertAPIError}
      />
    </div>
  );
};

export default MyUtilityMainContent;

MyUtilityMainContent.propTypes = {
  language: PropTypes.string.isRequired,
  displayPending: PropTypes.bool.isRequired,
  setDisplayPending: PropTypes.func.isRequired,
  hasInvalidInput: PropTypes.func.isRequired,
  inputComponentsMustValidate: PropTypes.bool.isRequired,
  alertAPIError: PropTypes.func.isRequired
};

const sanitizeIaaUser = ({
  UserObject: {
    profile,
    charger: {
      make: chargerMake,
      model: chargerModel,
      purchase_price: chargerPurchasePrice,
      type: chargerType,
      incentives: chargerIncentives,
      ...redactedChargerProperties
    },
    vehicle: {
      make: vehicleMake,
      model: vehicleModel,
      model_year: vehicleModelYear,
      type: vehicleType,
      fuel: vehicleFuel,
      incentives: vehicleIncentives,
      ...redactedVehicleProperties
    },
    chargerAddress: {
      owner: isChargerAddressOwner,
      "charger-at-this-property": isChargerAtChargerAddress,
      ...redactedChargerAddressProperties
    },
    primaryAddress: {
      owner: isPrimaryAddressOwner,
      "charger-at-this-property": isChargerAtPrimaryAddress,
      ...redactedPrimaryAddressProperties
    },
  },
}) => {
  const nonRedacted = {
    UserObject: {
      charger: {
        make: chargerMake,
        model: chargerModel,
        purchase_price: chargerPurchasePrice,
        type: chargerType,
        incentives: chargerIncentives,
      },
      vehicle: {
        make: vehicleMake,
        model: vehicleModel,
        model_year: vehicleModelYear,
        type: vehicleType,
        fuel: vehicleFuel,
        incentives: vehicleIncentives,
      },
      chargerAddress: {
        owner: isChargerAddressOwner,
        "charger-at-this-property": isChargerAtChargerAddress,
      },
      primaryAddress: {
        owner: isPrimaryAddressOwner,
        "charger-at-this-property": isChargerAtPrimaryAddress,
      },
    },
  };

  return nonRedacted;
};

export default sanitizeIaaUser;

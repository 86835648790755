import { Auth } from 'aws-amplify';
import logger from '../../functions/logging/logger';

const watchTimeout = (setSessionTimeoutError, t) => {

  if(t) {
    clearTimeout(t);
  }  

  Auth.currentSession()
    .then((sessionData) => {

      const timeNow = new Date().getTime();
      const expiry = sessionData.accessToken.payload.exp * 1000;

      const timeRemaining = expiry - timeNow;

      logger(new Date(expiry), 'timeoutWatcher');

      if(timeRemaining <= 0) {
        logger('time is up', 'timeoutWatcher');
        setSessionTimeoutError(true);
      } else {
        const nextTimeout = timeRemaining;

        const nt = setTimeout(() => {
          watchTimeout(setSessionTimeoutError, nt);
        }, nextTimeout);
      }
    })
    .catch(() => {
      setSessionTimeoutError(true); 
    });
};

export default watchTimeout;
